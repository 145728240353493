<template>
  <section>
    <v-card v-if="display_flg" class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
      <v-container class="fran-weather ma-0 pa-0">
        <v-row class="fran-headtitle" no-gutters>
          <v-col>
            <span>1時間天気：{{ area }}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="text-center">
              <v-col cols="3" class="lighten-1 py-3"></v-col>
              <v-col cols="3" class="py-2">天気</v-col>
              <v-col cols="3" class="py-2">気温</v-col>
              <v-col cols="3" class="py-2">降水量</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <template v-for="(hourlyData) in hourlyForecastList">    
          <v-row v-if="hourlyData.time" :key="hourlyData.datetime" class="fran-weather-wrap py-1" no-gutters>
            <v-col cols="3">
              <div
                v-text="hourlyData.time"
                class="fran-weather-date"
              ></div>
            </v-col>
            <v-col cols="3">
              <div>
                <v-img
                  :src="require(`@/assets/wicon/${hourlyData.pic}`)"
                  aspect-ratio="3"
                  contain
                ></v-img>
              </div>
            </v-col>
            <v-col cols="3">
              <div v-text="hourlyData.tem" class="text-center"></div>
            </v-col>
            <v-col cols="3">
              <div v-text="hourlyData.r" class="text-center"></div>
            </v-col>
          </v-row>
        </template>
        <v-row 
          v-if="hourlyForecastListExtend.length > 0 && showExtendForecast === false"
          justify="center" align-content="center"
        >
          <v-btn
            plain text
            @click="showExtendForecast = true"
          >もっと見る</v-btn>
        </v-row>
        <template v-else>    
          <v-row v-for="(hourlyData) in hourlyForecastListExtend" :key="hourlyData.datetime" class="fran-weather-wrap py-1" no-gutters>
            <v-col cols="3">
              <div
                v-text="hourlyData.time"
                class="fran-weather-date"
              ></div>
            </v-col>
            <v-col cols="3">
              <div>
                <v-img
                  :src="require(`@/assets/wicon/${hourlyData.pic}`)"
                  aspect-ratio="3"
                  contain
                ></v-img>
              </div>
            </v-col>
            <v-col cols="3">
              <div v-text="hourlyData.tem" class="text-center"></div>
            </v-col>
            <v-col cols="3">
              <div v-text="hourlyData.r" class="text-center"></div>
            </v-col>
          </v-row>
          <v-row justify="center" align-content="center">
            <v-btn
              plain text
              @click="showExtendForecast = false"
            >隠す</v-btn>
          </v-row>
        </template>
      </v-container>
      </div>
    </v-card>
  </section>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { getFranEveryHoursForecastV2, getFranUser } from '@/graphql/queries';
  import dayjs from 'dayjs';
  import 'dayjs/locale/ja';
  import AreaSelect from '@/mixins/area-select.js';
  var customParseFormat = require('dayjs/plugin/customParseFormat')
  dayjs.extend(customParseFormat)

  dayjs.locale('ja');

  export default {
    name: 'WeatherEveryHour',
    
    mixins: [AreaSelect],
    
    data: () => ({
      hourlyForecastList: [],
      hourlyForecastListExtend: [],
      showExtendForecast: false,
      area: '東京',
      selectedAmedasId: '44132',
      display_flg: true
    }),
    
    computed: {
      stateFranUser: function() {
        return this.$store.state.franUser;
      }
    },
    
    watch: {
      async stateFranUser (newValue) {
        if (newValue) {
          await this.loadUserPoint();
          this.showWeatherEveryHour();
        }
      }
    },
    
    mounted: async function() {
      await this.loadUserPoint();
      this.showWeatherEveryHour();
    },
    
    methods: {
      async loadUserPoint() {
        try {
          const user = await this.$store.state.franUser;
          if (user) {
            const input = { id: user.id };
            const result = await API.graphql(graphqlOperation(getFranUser, input));
            const resultData = result.data.getFranUser;
            if (resultData) {
              this.selectedAmedasId = resultData.forecast_amedas_id;
              this.area = this.findCity(this.selectedAmedasId).pointName;
            }
          } else {
            if (!this.selectedAmedasId) {
              this.selectedAmedasId = '44132';
              this.area = '東京';
            }
          }
        } catch(e) {
          console.error(e);
        }
      },
      
      async showWeatherEveryHour() {
        const forecastList = await this.getHourlyForecastListFor(this.selectedAmedasId);
        this.hourlyForecastList = [];
        this.hourlyForecastListExtend = [];
        for (let i in forecastList) {
          const data = forecastList[i];
          var forecast_time=(dayjs(data.datetime,'YYYY-MM-DD-hh:mm'))

          if (forecast_time.isAfter(dayjs())) {
            data.time = forecast_time.format('H:mm');
            data.tem = `${data.temperature}℃`;
            data.pic = `${data.weather_id}.png`;
            data.r = `${data.rain.toFixed(1)}mm`;
            if (this.hourlyForecastList.length < 6) {
              this.hourlyForecastList.push(data);
            } else {
              this.hourlyForecastListExtend.push(data);
            }
          }
        }
      },
      async getHourlyForecastListFor(amedasId) {
        if (amedasId) {
          try {
            const input = { 
              amedas_id: amedasId
            };
            const result = await API.graphql({
              query: getFranEveryHoursForecastV2,
              authMode: 'API_KEY',
              variables: input,
            });
            const dataList = result.data.getFranEveryHoursForecastV2.forecast_value;
            return dataList;
          } catch(error) {
            this.display_flg = false;
            console.error(`Failed to load every hour forecast list: ${JSON.stringify(error)}`);
          }
        } else {
          return [];
        }
      }
    }
  };
</script>
