<template>
  <section>
    <v-card class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
      <v-container class="ma-0 pa-0">
        <v-row class="fran-headtitle" no-gutters>
          <v-col>
            <span>あなたが選んだ地点</span>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-for="h in selectedPointsHistories.slice(-3).reverse()"
        :key="h.createdAt"
        class="fran-prize-list ma-0 pa-0"
      >
        <v-row>
          <v-col cols="4">{{ getDisplayPeriod(h) }}</v-col>
          <v-col cols="5">{{ getDisplayRegion(h) }}</v-col>
          <v-col cols="3">{{ getDisplayState(h) }}</v-col>
        </v-row>
        <v-divider></v-divider>
      </v-container>
      </div>
    </v-card>
  </section>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { listFranSelectedPointsV2s } from '@/graphql/queries';
  import dayjs from 'dayjs';
  import 'dayjs/locale/ja';
  import AreaSelect from '@/mixins/area-select.js';
  dayjs.locale('ja');

  export default {
    name: 'SelectedPoints',
    
    mixins: [AreaSelect],
    
    data: () => ({
      user: '',
      timestampFranForecast: '',
      timestampFranNow: '',
      selectedPointsHistories: [],
    }),
  
    computed: {
      stateFranUser: function() {
        return this.$store.state.franUser;
      }
    },
    
    mounted: async function () {
      this.user = await this.$store.state.franUser;
      await this.getPeriod();
      if (this.user) {
        try {
          const selectedPointsHistories = await this.getSelectedPoints();
          this.selectedPointsHistories = selectedPointsHistories;
        } catch(error) {
          console.error(`mounted failed: ${error}`);
        }
      }
    },
    
    methods: {
      getPeriod() {
        let dObj = new Date();
        const timestampNow = Math.floor(dObj.getTime() / 1000) + 32400; // getTimeはミリ秒なので1000で割る 日本時間での経過時間(≠unixtime)
        this.timestampFranForecast = Math.floor(timestampNow / 43200) * 43200 - 32400; // 現在時刻より前で直近の0時or12時のunixtime
        this.timestampFranNow = this.timestampFranForecast - 43200; // さらに1つ前の0時or12時のunixtime
      },
    
      async getSelectedPoints() {
        try {
          const input = { id: this.user.id };
          const result = await API.graphql(graphqlOperation(listFranSelectedPointsV2s, input));
          const selectedPointsHistories = result.data.listFranSelectedPointsV2s.items;
          const sortedSelectedPointsHistories = selectedPointsHistories.sort((a, b) => {
            return (a.forecast_date < b.forecast_date) ? -1 : (a.forecast_date > b.forecast_date) ? 1 : 0;
          });
          return sortedSelectedPointsHistories;
        } catch(error) {
          console.error(`Failed to load user all selected points histories: ${JSON.stringify(error)}`);
        }
      },
      
      getDisplayPeriod(history) {
        const forecast_date = new Date((history.forecast_date + 43200) * 1000);
        const year = forecast_date.getFullYear();
        const month = forecast_date.getMonth() + 1;
        const day = forecast_date.getDate();
        const ampm = (forecast_date.getHours() == 0) ? '午前' : '午後';
        const result = year + '/' + month + '/' + day + ' ' + ampm;
        return result;
      },
      
      getDisplayRegion(history) {
        const prefecture = this.getDisplayPrefecture(history);
        try {
          const point = this.getDisplayPoint(history);
          return prefecture + ' ' + point;
        } catch(error) {
          console.error(error);
          return '移設等の理由で表示できません';
        }
      },
      
      getDisplayPrefecture(history) {
        let result = '--';
        result = this.findArea(history.selected_amedas_id.substr(0, 2)).name;
        return result;
      },
      
      getDisplayPoint(history) {
        let result = '--';
        result = this.findCity(history.selected_amedas_id).pointName;
        return result;
      },
      
      getDisplayState(history) {
        let result = '';
        if (history.forecast_date === this.timestampFranForecast) {
          result = '選択中';
        } else if (history.forecast_date === this.timestampFranNow) {
          result = '結果待ち';
        }
        return result;
      }
    }
  };
</script>
