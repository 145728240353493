<template>
  <section>
    <v-card class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
        <v-container class="ma-0 pa-0">
          <template v-if="mode=='signin'">
            <div class="fran-headtitle">メールアドレスでログイン</div>
            <template v-if="errMessage">
              <span class="red--text subtitle-1"> {{errMessage}}</span>
            </template>
            <div>
              <v-form
                class="mail-login-form"
                ref="mailform"
                v-model="validMailForm"
                lazy-validation
                autocomplete="on"
              >
              <v-text-field
                outlined
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="メールアドレスを入力"
                autocomplete="email"
              ></v-text-field>
              <v-checkbox
                v-model="mailNewsPermission"
                class= "ma-0 pt-0 pl-2 mail-receive-label"
                label="FRANの最新情報をメールで受け取る"
                color="#289FDA"
                hide-details
              ></v-checkbox>
              </v-form>
            </div>
            <div class="mail-login-btn-group">
              <div class="mail-signup-btn">
              <v-btn
                @click="signUp"
                color="blue"
                width="90px"
                height="29px"
                depressed
                outlined
                :disabled="!enablelogin"
                :loading="processingsignup"
              >新規登録</v-btn>
              </div>
              <div class="mail-signin-btn">
              <v-btn
                @click="signIn"
                color="blue"
                width="90px"
                height="29px"
                depressed
                :disabled="!enablelogin"
                :loading="processingsignin"
              >ログイン</v-btn>
              </div>
            </div>
          </template>
          <template v-if="mode=='entercode'">
            <div v-if="signupFlag" class="subtitle-1">ユーザーの新規登録を行います。<br>{{email}}に認証メールを送信しました。<br>メールに記載された認証コードを入力してください。</div>
            <div v-else class="subtitle-1">{{email}}に認証メールを送信しました。<br>メールに記載された認証コードを入力してください。</div>
            <template v-if="errMessage">
              <span class="red--text subtitle-1"> {{errMessage}}</span>
            </template>
            <v-otp-input
              class = "mail-otp-field"
              length="4"
              plain
              type="number"
              :disabled="processingcode"
              v-model="logincode"
            ></v-otp-input>
            <div class="mail-login-btn-group">
              <div class="mail-continue-btn">
              <v-btn
                @click="answerCustomChallenge"
                color="blue"
                width="100px"
                height="29px"
                depressed
                tile
                :disabled = "!enableverify"
                :loading="processingcode"
              >続行</v-btn>
              </div>
            </div>
          </template>
          <template v-if="mode=='autosignin'">
            <div>ログイン中・・・</div>
          </template>
          <template v-if="mode=='completed'">
            <div>ログインしました</div>
          </template>
        </v-container>
      </div>
    </v-card>
  </section>
</template>
<script>
  import { Auth, API, graphqlOperation } from "aws-amplify";
  import awsconfig from '@/aws-exports';
  import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
  import router from '../../router/index.js';
  import { getLimitMailSignIn, getFranMailNews, getFranUser } from '@/graphql/queries';
  import { createFranMailNews, deleteFranMailNews } from '@/graphql/mutations';
  //import crypto from "crypto";
  //import Vue from 'vue';
  export default {
    name:'MailSignIn',
    computed: {
      enablelogin: function() {
        return this.validMailForm === true && this.email && this.processingsignin === false && this.processingsignup === false;
      },
      enableverify: function() {
        return this.logincode.length === 4 && this.processingcode === false;
      }
    },
    data () {
      return {
        mode: 'signin',
        signupFlag: false,
        validMailForm: false,
        cognitoUser:'',
        email: '',
        logincode:'',
        processingsignup: false,
        processingsignin: false,
        processingcode: false,
        errMessage: '',
        rules: {
          required: value => !!value || '入力欄が空です',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || '無効なメールアドレスです';
          },
        },
        mailNewsPermission: true
      };
    },
    mounted: async function () {
      this.signupFlag = false;
      let loginstate = await this.isAuthenticated();
      if(loginstate){
        this.mode = 'completed';
      }else{
        //this.mode = this.$route.params.pageid;
        //console.log(this.mode);
        if(this.$route.params.pageid == 'entercode'){
          this.mode = 'autosignin';
          this.autoAnswerCustomChallenge(this.$route.params.code);
          }
      }
      await this.loadNewsPermissionFromStorage();
    },
    methods:{
        async signIn() {
          this.errMessage = '';
          this.processingsignin = true;
          try{
            const sendmail = await this.loadMailLimit();
            if(sendmail){
            this.cognitoUser = await Auth.signIn("Email_" + this.email);
            }else{
              throw 'TooManyMailSentError';
            }
          }catch(e){
            if(e == 'TooManyMailSentError'){
              this.errMessage = 'メール送信数の上限に達しています。15分後に再度お試しください';
            }else if (e.code == 'UserNotFoundException') {
              this.errMessage = 'メールアドレスが間違っているか未登録です';
            }else{
              console.error(e);
            }
            this.processingsignin = false;
            return;
          }
          localStorage.setItem(
            'loginUserInfo',
            JSON.stringify({
              username: "Email_" + this.email,
              session: this.cognitoUser.Session,
              selectingEventId: sessionStorage.eventId,
              selectingCenter: sessionStorage.center,
              mailNewsPermission: this.mailNewsPermission
            })
          );
          this.processingsignin = false;
          this.mode = "entercode";
          this.errMessage = '';
        },

        async signUp() {
          this.errMessage = '';
          this.processingsignup = true;
          this.signupFlag = true;
          const params = {
          username: "Email_" + this.email,
          password: this.getRandomString(30),
          attributes: {
              name: this.email,
              email: this.email,
          }
          };
          try {
            const sendmail = await this.loadMailLimit();
            if(sendmail){
              await Auth.signUp(params);
              this.cognitoUser = await Auth.signIn("Email_" + this.email);
            }else{
              throw 'TooManyMailSentError';
            }
          } catch(e) {
            if(e == 'TooManyMailSentError'){
              this.errMessage = 'メール送信数の上限に達しています。15分後に再度お試しください';
            }else if (e.code == 'UsernameExistsException') {
              this.errMessage = 'すでに登録されているメールアドレスです';
            }else{
              console.error(e);
            }
            this.processingsignup = false;
            return;
          }
          localStorage.setItem(
            'loginUserInfo',
            JSON.stringify({
              username: "Email_" + this.email,
              session: this.cognitoUser.Session,
              selectingEventId: sessionStorage.eventId,
              selectingCenter: sessionStorage.center,
              mailNewsPermission: this.mailNewsPermission
            })
          );
          this.processingsignup = false;
          this.mode = "entercode";
          this.errMessage = '';
        },

        getRandomString(bytes) {
            const randomValues = new Uint8Array(bytes);
            window.crypto.getRandomValues(randomValues);
            return Array.from(randomValues).map(this.intToHex).join('');
        },

        intToHex(nr) {
            return nr.toString(16).padStart(2, '0');
        },

        async loadMailLimit() {
          try {
            const input = { mail: this.email };
            const result = await API.graphql({
              query: getLimitMailSignIn,
              authMode: 'API_KEY',
              variables: input
            });
            const resultData = result.data.getLimitMailSignIn;
            if (resultData) {
              let date = new Date();
              let now_unixtime = Math.floor(date.getTime() / 1000);
              if (resultData.deletedAt >= now_unixtime && resultData.count >= 3) {
                return false; //送信制限テーブルに存在し、x分以内にy回以上送信している＝メール送信不可
              }else{
                return true; //送信制限テーブルに存在し、x分以内にy回未満＝メール送信可
              }
            }else{
              return true; //送信制限テーブルに存在しない＝メール送信可
            }
          } catch(e) {
            console.error(e);
            return true; //エラーの場合送信制限をかけない
          }
        },

        async answerCustomChallenge() {
            this.errMessage = '';
            this.processingcode = true;
            const user = JSON.parse(localStorage.getItem('loginUserInfo'));
            //if(user.selectedEventId){sessionStorage.setItem('eventId', user.selectedEventId);}
            //if(user.selectedCenter){sessionStorage.setItem('center', user.selectedCenter);}
            try{
              this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, this.logincode);
            }catch(e){
              if (e.code == 'NotAuthorizedException') {
                this.errMessage = '認証に失敗しました。もう一度ログインしてください';
                this.signupFlag = false;
                this.mode = "signin";
              }else{
                console.error(e);
              }
              this.processingcode = false;
              return;
            }
            const loginSucceeded = await this.isAuthenticated();
            if (loginSucceeded) {
              await this.saveNewsPermission(user);
              this.errMessage= '';
              localStorage.removeItem('loginUserInfo');
              //this.mode = "completed";
              await this.getUser();
              if(user.selectingEventId){//イベントダイアログからログインした場合トップに戻ったときダイアログを表示しなおす
                router.push({path: '/', query: { eventId: user.selectingEventId, lat: user.selectingCenter.split(',')[0], lon: user.selectingCenter.split(',')[1] }},() => {});
              } else {
                router.push({path: '/'},() => {});
              }
            } else {
              this.errMessage= '認証コードが間違っています';
            }
            this.processingcode = false;
            },

        async autoAnswerCustomChallenge(answer) {//自動ログイン
          this.$store.commit('progressCircleOn');
          this.errMessage = '';
          //const answer=this.logincode;
          try{
            const user = JSON.parse(localStorage.getItem('loginUserInfo'));
            //if(user.selectingEventId){sessionStorage.setItem('eventId', user.selectingEventId);}
            //if(user.selectingCenter){sessionStorage.setItem('center', user.selectingCenter);}
            const authenticationData = {
              Username: user.username,
            };
            new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
            const poolData = {
              UserPoolId: awsconfig.aws_user_pools_id,
              ClientId: awsconfig.aws_user_pools_web_client_id,
            };
            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const userData = {
              Username: user.username,
              Pool: userPool,
            };
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            cognitoUser.Session = user.session;
            cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
            const me = this;
            this.cognitoUser = await cognitoUser.sendCustomChallengeAnswer(answer, {
              async onSuccess() {
                const loginSucceeded = await me.isAuthenticated();
                if (loginSucceeded) {
                  await me.saveNewsPermission(user);
                  me.$store.commit('progressCircleOff');
                  me.errMessage= '';
                  localStorage.removeItem('loginUserInfo');
                  //this.mode = "completed";
                  await me.getUser();
                  if(user.selectingEventId){//イベントダイアログからログインした場合トップに戻ったときダイアログを表示しなおす
                    router.push({path: '/', query: { eventId: user.selectingEventId, lat: user.selectingCenter.split(',')[0], lon: user.selectingCenter.split(',')[1] }},() => {});
                  } else {
                    router.push({path: '/'},() => {});
                  }
                } else {
                  me.$store.commit('progressCircleOff');
                  me.errMessage= '認証コードが間違っています';
                }
              },
              onFailure(failure) {
                me.$store.commit('progressCircleOff');
                console.error(failure);
                localStorage.removeItem('loginUserInfo');
                me.errMessage = '認証に失敗しました。もう一度ログインしてください';
                me.mode = "signin";
              },
            });
          }catch(e){
              this.$store.commit('progressCircleOff');
              console.error(e);
              localStorage.removeItem('loginUserInfo');
              this.errMessage = '認証に失敗しました。もう一度ログインしてください';
              this.mode = "signin";
          }
        },

        async isAuthenticated() {
            try {
            await Auth.currentSession();
            return true;
            } catch {
            return false;
            }
        },
        
        async getUser() {
          return Auth.currentAuthenticatedUser().then((data) => {
            if (data && data.signInUserSession) {
              this.$store.dispatch('changeUser', data);
              return data;
            }
          }).catch(() => {
            this.$store.dispatch('changeUser', null);
            return null;
          });
      },

      async loadNewsPermissionFromStorage(){//初回ログイン前を購読状態(チェックボックスON)にしたい
        if(localStorage.getItem('unsub_news')) {
          this.mailNewsPermission = false;
        }else{
          this.mailNewsPermission = true;
        }
      },

      async saveNewsPermission(user){
        try {
          this.mailNewsPermission = user.mailNewsPermission;
          if (!this.email){
            const input = { id: user.username };
            const result = await API.graphql(graphqlOperation(getFranUser, input));
            const resultData = result.data.getFranUser;
            if (resultData) {
              this.email = resultData.email;
            }
          }
          const input = { mail: this.email};
          const result = await API.graphql(graphqlOperation(getFranMailNews, input));
          const resultData = result.data.getFranMailNews;
          if(!resultData && this.mailNewsPermission){
            await API.graphql(graphqlOperation(createFranMailNews, { input: input }));
            localStorage.removeItem('unsub_news');
          }
          else if(resultData && !this.mailNewsPermission){
            await API.graphql(graphqlOperation(deleteFranMailNews, { input: input }));
            localStorage.setItem('unsub_news', 'y');
          }
        } catch(e) {
          console.error(e);
        }
      },
    },

  };
</script>