<!-- インデックスページ -->

<template>
  <v-app>
    <!-- プログレスサークル -->
    <v-overlay :value="progressCircle" style="z-index:9999">
      <v-progress-circular
        :size="64"
        color="#00FFFF"
        indeterminate
      />
    </v-overlay>
    
    <!-- ナビゲーション -->
    <nav-bar v-show="$route.meta.mobile" />

    <!-- バックバー -->
    <goback-bar v-show="$route.meta.goback" />
    
    <v-main class = "pa-0">
      <v-container class="pb-15" id="index-v-container" fluid>
        <!-- vue router / メインコンテンツ -->
        <router-view v-if="forecastLoaded"></router-view>
      </v-container>
    </v-main>

    <ad-banner-bottom v-show="$route.meta.home" />
  </v-app>
</template>

<script>
  import axios from 'axios';
  import DiaClose from '@/mixins/base/dia-close.js';
  
  export default {
    name: 'Index',
    
    mixins: [DiaClose],

    components: {
      NavBar: () => import('@/components/index/NavBar'),
      AdBannerBottom: () => import('@/components/base/AdBannerBottom'),
      GobackBar: () => import('@/components/index/GobackBar'),
    },
    
    data: () => ({
      forecastLoaded: false,
    }),
    
    computed: {
      stateFranUser() {
        return this.$store.getters.getFranUser;
      },
      progressCircle() {
        return this.$store.state.showProgressCircle;
      }
    },
    
    mounted: function () {
      this.loadAfterFranForecast().then(() => this.forecastLoaded = true);
    },
    
    methods: {
      async loadAfterFranForecast() {
        let dObj = new Date();
        const yearNow = dObj.getFullYear();
        const monthNow = dObj.getMonth() + 1;
        const dayNow = dObj.getDate();
        const hourNow = dObj.getHours();
        let franNowPeriodStr;
        let franForecastPeriodStr;
        if (hourNow >= 12) {
          franNowPeriodStr = yearNow.toString() + ('00' + monthNow.toString()).slice(-2) + ('00' + dayNow.toString()).slice(-2) + '0000';
          franForecastPeriodStr = yearNow.toString() + ('00' + monthNow.toString()).slice(-2) + ('00' + dayNow.toString()).slice(-2) + '1200';
        } else {
          franForecastPeriodStr = yearNow.toString() + ('00' + monthNow.toString()).slice(-2) + ('00' + dayNow.toString()).slice(-2) + '0000';
          dObj.setDate(dayNow - 1);
          const yearYesterday = dObj.getFullYear();
          const monthYesterday = dObj.getMonth() + 1;
          const dayYesterday = dObj.getDate();
          franNowPeriodStr = yearYesterday.toString() + ('00' + monthYesterday.toString()).slice(-2) + ('00' + dayYesterday.toString()).slice(-2) + '1200';
        }
        
        const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
        let franNowFileName;
        let franForecastFileName;
        if (env === 'test') {
          franNowFileName = 'https://d15weav17n7j8c.cloudfront.net/csv/forecast/modified_fran_forecast5_' + franNowPeriodStr + '.csv';
          franForecastFileName = 'https://d15weav17n7j8c.cloudfront.net/csv/forecast/modified_fran_forecast5_' + franForecastPeriodStr + '.csv';
        } else {
          franNowFileName = 'https://d1c2jpt0saqfbp.cloudfront.net/csv/forecast/modified_fran_forecast5_' + franNowPeriodStr + '.csv';
          franForecastFileName = 'https://d1c2jpt0saqfbp.cloudfront.net/csv/forecast/modified_fran_forecast5_' + franForecastPeriodStr + '.csv';
        }
        
        try {
          const resAfter = await axios.get(franForecastFileName);
          const afterFranForecastList = resAfter.data.split('\n').slice(0, -1).map(s => this.convertListToJson(s));
          localStorage.setItem('afterFranForecastList', JSON.stringify(afterFranForecastList));
        } catch(e) {
          if (e.response.status == 403) { // 最新のFRAN予報のCSVファイルが無かった場合
            const resAfter = await axios.get(franNowFileName);
            const afterFranForecastList = resAfter.data.split('\n').slice(0, -1).map(s => this.convertDummyListToJson(s));
            localStorage.setItem('afterFranForecastList', JSON.stringify(afterFranForecastList));
          }
          console.error(e);
        }
      },
      
      convertListToJson(csvStr) {
        const cols = csvStr.split(',');
        return {
          AmedasId: cols[0],
          pointName: cols[1],
          latitude: cols[2],
          longitude: cols[3],
          franForecast: (cols[4] == '0') ? '降る' : '降らん',
          franWeather: cols[5] + '.png',
          franMinTemperature: cols[6],
          franMaxTemperature: cols[7]
        };
      },
      
      convertDummyListToJson(csvStr) {
        const cols = csvStr.split(',');
        return {
          AmedasId: cols[0],
          pointName: cols[1],
          latitude: cols[2],
          longitude: cols[3],
          franForecast: '---',
          franWeather: 'logo.png',
          franMinTemperature: '--',
          franMaxTemperature: '--'
        };
      }
    }
  };
</script>

<style lang="sass" scoped>
  #index-v-container
        height: 100%
        min-height: 100%
</style>
