<template>
  <section>
    <v-card class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
      <v-container class="ma-0 pa-0">
        <v-row class="fran-headtitle" no-gutters>
          <v-col>
            <span>当選者が選んだ地点</span>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-for="h in winnerpointsHistories.slice(-3).reverse()"
        :key="h.createdAt"
        class="fran-prize-list ma-0 pa-0"
      >
        <v-row>
          <v-col cols="4">{{ getDisplayPeriod(h) }}</v-col>
          <v-col cols="8">{{ getDisplayRegion(h) }}</v-col>
        </v-row>
        <v-divider></v-divider>
      </v-container>
      </div>
    </v-card>
  </section>
</template>

<script>
  import { API } from 'aws-amplify';
  import { listFranWinnerSelectedPointsV2s } from '@/graphql/queries';
  import dayjs from 'dayjs';
  import 'dayjs/locale/ja';
  import AreaSelect from '@/mixins/area-select.js';
  dayjs.locale('ja');

  export default {
    name: 'WinnerPoints',
    
    mixins: [AreaSelect],
    
    data: () => ({
      winnerpointsHistories: [],
    }),
    
    mounted: async function () {
      try {
        const winnerpointsHistories = await this.getWinnerPointsHistories();
        this.winnerpointsHistories = winnerpointsHistories;
      } catch(error) {
        console.error(`mounted failed: ${error}`);
      }
    },
    
    methods: {
      async getWinnerPointsHistories() {
        try {
          const result = await API.graphql({
            query: listFranWinnerSelectedPointsV2s,
            authMode: 'API_KEY',
          });
          const winnerpointsHistories = result.data.listFranWinnerSelectedPointsV2s.items;
          const sortedWinnerPointsHistories = winnerpointsHistories.sort((a, b) => {
            return (a.forecast_date < b.forecast_date) ? -1 : (a.forecast_date > b.forecast_date) ? 1 : 0;
          });
          return sortedWinnerPointsHistories;
        } catch(error) {
          console.error(`Failed to load user all compensation histories: ${JSON.stringify(error)}`);
        }
      },
      
      getDisplayPeriod(history) {
        const forecast_date = new Date((history.forecast_date + 43200) * 1000);
        const year = forecast_date.getFullYear();
        const month = forecast_date.getMonth() + 1;
        const day = forecast_date.getDate();
        const ampm = (forecast_date.getHours() == 0) ? '午前' : '午後';
        const result = year + '/' + month + '/' + day + ' ' + ampm;
        return result;
      },
      
      getDisplayRegion(history) {
        let regions = [];
        for (const amedas_id of history.selected_amedas_ids) {
          const prefecture = this.getDisplayPrefecture(amedas_id);
          try {
            const point = this.getDisplayPoint(amedas_id);
            const region = prefecture + ' ' + point; 
            regions.push(region);
          } catch(error) {
            console.error(error);
            regions.push('移設等の理由で表示できない地点');
          }
        }
        return regions.toString();
      },
      
      getDisplayPrefecture(amedas_id) {
        let result = '--';
        result = this.findArea(amedas_id.substr(0, 2)).name;
        return result;
      },
      
      getDisplayPoint(amedas_id) {
        let result = '--';
        result = this.findCity(amedas_id).pointName;
        return result;
      }
    }
  };
</script>
