/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFranRainForecastV2 = /* GraphQL */ `
  query GetFranRainForecastV2(
    $forecast_amedas_id: String!
    $forecast_date: AWSTimestamp!
  ) {
    getFranRainForecastV2(
      forecast_amedas_id: $forecast_amedas_id
      forecast_date: $forecast_date
    ) {
      forecast_amedas_id
      forecast_date
      amedas_point_name
      max_temp
      min_temp
      one_mm_rain
      weather_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranRainForecastV2s = /* GraphQL */ `
  query ListFranRainForecastV2s(
    $forecast_amedas_id: String
    $forecast_date: ModelIntKeyConditionInput
    $filter: ModelFranRainForecastV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranRainForecastV2s(
      forecast_amedas_id: $forecast_amedas_id
      forecast_date: $forecast_date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        forecast_amedas_id
        forecast_date
        amedas_point_name
        max_temp
        min_temp
        one_mm_rain
        weather_id
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranEveryHoursForecastV2 = /* GraphQL */ `
  query GetFranEveryHoursForecastV2($amedas_id: String!) {
    getFranEveryHoursForecastV2(amedas_id: $amedas_id) {
      amedas_id
      forecast_value {
        datetime
        temperature
        weather_id
        rain
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFranEveryHoursForecastV2s = /* GraphQL */ `
  query ListFranEveryHoursForecastV2s(
    $amedas_id: String
    $filter: ModelFranEveryHoursForecastV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranEveryHoursForecastV2s(
      amedas_id: $amedas_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        amedas_id
        forecast_value {
          datetime
          temperature
          weather_id
          rain
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranWeeklyForecastV2 = /* GraphQL */ `
  query GetFranWeeklyForecastV2($amedas_id: String!) {
    getFranWeeklyForecastV2(amedas_id: $amedas_id) {
      amedas_id
      forecast_value {
        amedas_id
        area_name
        forecast_date
        week_list {
          date
          temperature_high
          temperature_low
          weather_id
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFranWeeklyForecastV2s = /* GraphQL */ `
  query ListFranWeeklyForecastV2s(
    $amedas_id: String
    $filter: ModelFranWeeklyForecastV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranWeeklyForecastV2s(
      amedas_id: $amedas_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        amedas_id
        forecast_value {
          amedas_id
          area_name
          forecast_date
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranAmedasV2 = /* GraphQL */ `
  query GetFranAmedasV2($amedas_id: String!, $obs_date: String!) {
    getFranAmedasV2(amedas_id: $amedas_id, obs_date: $obs_date) {
      amedas_id
      obs_date
      amedas_list {
        rain
        temp
        wind
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranAmedasV2s = /* GraphQL */ `
  query ListFranAmedasV2s(
    $amedas_id: String
    $obs_date: ModelStringKeyConditionInput
    $filter: ModelFranAmedasV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranAmedasV2s(
      amedas_id: $amedas_id
      obs_date: $obs_date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        amedas_id
        obs_date
        amedas_list {
          rain
          temp
          wind
        }
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranUser = /* GraphQL */ `
  query GetFranUser($id: ID!) {
    getFranUser(id: $id) {
      id
      user_name
      email
      forecast_amedas_id
      now_selected_amedas_id
      age
      gender
      createdAt
      updatedAt
    }
  }
`;
export const listFranUsers = /* GraphQL */ `
  query ListFranUsers(
    $filter: ModelFranUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFranUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_name
        email
        forecast_amedas_id
        now_selected_amedas_id
        age
        gender
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranSelectedPointsV2 = /* GraphQL */ `
  query GetFranSelectedPointsV2($id: String!, $forecast_date: AWSTimestamp!) {
    getFranSelectedPointsV2(id: $id, forecast_date: $forecast_date) {
      id
      forecast_date
      selected_amedas_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranSelectedPointsV2s = /* GraphQL */ `
  query ListFranSelectedPointsV2s(
    $id: String
    $forecast_date: ModelIntKeyConditionInput
    $filter: ModelFranSelectedPointsV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranSelectedPointsV2s(
      id: $id
      forecast_date: $forecast_date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        forecast_date
        selected_amedas_id
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsBySelectedAmedasId = /* GraphQL */ `
  query ItemsBySelectedAmedasId(
    $selected_amedas_id: String
    $forecast_date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFranSelectedPointsV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsBySelectedAmedasId(
      selected_amedas_id: $selected_amedas_id
      forecast_date: $forecast_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        forecast_date
        selected_amedas_id
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranCompensationV2 = /* GraphQL */ `
  query GetFranCompensationV2($id: String!, $forecast_date: AWSTimestamp!) {
    getFranCompensationV2(id: $id, forecast_date: $forecast_date) {
      id
      forecast_date
      compensation_flag
      linepay
      price
      limitedAt
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranCompensationV2s = /* GraphQL */ `
  query ListFranCompensationV2s(
    $id: String
    $forecast_date: ModelIntKeyConditionInput
    $filter: ModelFranCompensationV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranCompensationV2s(
      id: $id
      forecast_date: $forecast_date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        forecast_date
        compensation_flag
        linepay
        price
        limitedAt
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByCompensationFlag = /* GraphQL */ `
  query ItemsByCompensationFlag(
    $compensation_flag: Int
    $limitedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFranCompensationV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByCompensationFlag(
      compensation_flag: $compensation_flag
      limitedAt: $limitedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        forecast_date
        compensation_flag
        linepay
        price
        limitedAt
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranEventParticipationV2 = /* GraphQL */ `
  query GetFranEventParticipationV2($user_id: String!, $event_id: String!) {
    getFranEventParticipationV2(user_id: $user_id, event_id: $event_id) {
      user_id
      event_id
      user_answer
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranEventParticipationV2s = /* GraphQL */ `
  query ListFranEventParticipationV2s(
    $user_id: String
    $event_id: ModelStringKeyConditionInput
    $filter: ModelFranEventParticipationV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranEventParticipationV2s(
      user_id: $user_id
      event_id: $event_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user_id
        event_id
        user_answer
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranNftParticipation = /* GraphQL */ `
  query GetFranNftParticipation($user_id: String!, $nft_event_id: String!) {
    getFranNftParticipation(user_id: $user_id, nft_event_id: $nft_event_id) {
      user_id
      nft_event_id
      amedas_id
      user_answer
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranNftParticipations = /* GraphQL */ `
  query ListFranNftParticipations(
    $user_id: String
    $nft_event_id: ModelStringKeyConditionInput
    $filter: ModelFranNftParticipationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranNftParticipations(
      user_id: $user_id
      nft_event_id: $nft_event_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user_id
        nft_event_id
        amedas_id
        user_answer
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByUserAnswer = /* GraphQL */ `
  query ItemsByUserAnswer(
    $user_answer: Int
    $event_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFranEventParticipationV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByUserAnswer(
      user_answer: $user_answer
      event_id: $event_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        event_id
        user_answer
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranNftWinners = /* GraphQL */ `
  query GetFranNftWinners($user_id: String!, $nft_event_id: String!) {
    getFranNftWinners(user_id: $user_id, nft_event_id: $nft_event_id) {
      user_id
      nft_event_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranNftWinnerss = /* GraphQL */ `
  query ListFranNftWinnerss(
    $user_id: String
    $nft_event_id: ModelStringKeyConditionInput
    $filter: ModelFranNftWinnersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranNftWinnerss(
      user_id: $user_id
      nft_event_id: $nft_event_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user_id
        nft_event_id
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranEventPrizeV2 = /* GraphQL */ `
  query GetFranEventPrizeV2($user_id: String!, $event_id: String!) {
    getFranEventPrizeV2(user_id: $user_id, event_id: $event_id) {
      user_id
      event_id
      prize_flag
      linepay
      price
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranEventPrizeV2s = /* GraphQL */ `
  query ListFranEventPrizeV2s(
    $user_id: String
    $event_id: ModelStringKeyConditionInput
    $filter: ModelFranEventPrizeV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranEventPrizeV2s(
      user_id: $user_id
      event_id: $event_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user_id
        event_id
        prize_flag
        linepay
        price
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranWinnerSelectedPointsV2 = /* GraphQL */ `
  query GetFranWinnerSelectedPointsV2($forecast_date: AWSTimestamp!) {
    getFranWinnerSelectedPointsV2(forecast_date: $forecast_date) {
      forecast_date
      selected_amedas_ids
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFranWinnerSelectedPointsV2s = /* GraphQL */ `
  query ListFranWinnerSelectedPointsV2s(
    $forecast_date: AWSTimestamp
    $filter: ModelFranWinnerSelectedPointsV2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranWinnerSelectedPointsV2s(
      forecast_date: $forecast_date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        forecast_date
        selected_amedas_ids
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranShopEvent = /* GraphQL */ `
  query GetFranShopEvent($shop_id: String!, $event_id: String!) {
    getFranShopEvent(shop_id: $shop_id, event_id: $event_id) {
      shop_id
      event_id
      register_id
      email
      display
      amedas_id
      tweet_id
      start_date
      end_date
      start_hour
      end_hour
      createdAt
      updatedAt
    }
  }
`;
export const listFranShopEvents = /* GraphQL */ `
  query ListFranShopEvents(
    $shop_id: String
    $event_id: ModelStringKeyConditionInput
    $filter: ModelFranShopEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranShopEvents(
      shop_id: $shop_id
      event_id: $event_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shop_id
        event_id
        register_id
        email
        display
        amedas_id
        tweet_id
        start_date
        end_date
        start_hour
        end_hour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShopEventByDisplay = /* GraphQL */ `
  query GetShopEventByDisplay(
    $display: Int
    $event_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFranShopEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopEventByDisplay(
      display: $display
      event_id: $event_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shop_id
        event_id
        register_id
        email
        display
        amedas_id
        tweet_id
        start_date
        end_date
        start_hour
        end_hour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShopEventByRegisterId = /* GraphQL */ `
  query GetShopEventByRegisterId(
    $register_id: String
    $event_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFranShopEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopEventByRegisterId(
      register_id: $register_id
      event_id: $event_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shop_id
        event_id
        register_id
        email
        display
        amedas_id
        tweet_id
        start_date
        end_date
        start_hour
        end_hour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLimitMailSignIn = /* GraphQL */ `
  query GetLimitMailSignIn($mail: String!) {
    getLimitMailSignIn(mail: $mail) {
      mail
      deletedAt
      count
      createdAt
      updatedAt
    }
  }
`;
export const listLimitMailSignIns = /* GraphQL */ `
  query ListLimitMailSignIns(
    $mail: String
    $filter: ModelLimitMailSignInFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLimitMailSignIns(
      mail: $mail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mail
        deletedAt
        count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranNotificationPermission = /* GraphQL */ `
  query GetFranNotificationPermission($topic: String!, $endpoint: String!) {
    getFranNotificationPermission(topic: $topic, endpoint: $endpoint) {
      topic
      endpoint
      id
      createdAt
      updatedAt
    }
  }
`;
export const listFranNotificationPermissions = /* GraphQL */ `
  query ListFranNotificationPermissions(
    $topic: String
    $endpoint: ModelStringKeyConditionInput
    $filter: ModelFranNotificationPermissionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranNotificationPermissions(
      topic: $topic
      endpoint: $endpoint
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        topic
        endpoint
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFranMailNews = /* GraphQL */ `
  query GetFranMailNews($mail: String!) {
    getFranMailNews(mail: $mail) {
      mail
      createdAt
      updatedAt
    }
  }
`;
export const listFranMailNewss = /* GraphQL */ `
  query ListFranMailNewss(
    $mail: String
    $filter: ModelFranMailNewsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFranMailNewss(
      mail: $mail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
