/* eslint-disable */
import { Analytics } from 'aws-amplify';

export default {
    // Pinpointにクリックイベント送信してリンクを開く

    name: 'AdFunc',

    methods: {
        sendClickEvent(ad_url, type) {
            Analytics.record({
                name: 'adclick',
                immediate: true,
                attributes: {
                    url: ad_url,     
                    ad_type: type
                }
            });
            window.open(ad_url);
          }
    },

}