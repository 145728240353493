import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import API from '@aws-amplify/api';
import awsconfig from './aws-exports'
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag";
import SocialSharing from 'vue-social-sharing';

API.configure(awsconfig)

// TODO 基底コンポーネントを一括登録する
const files = require.context('./components/base', true, /\.vue$/)
const components = {}
files.keys().forEach(key => {
  components[key.replace(/(\.\/|\.vue)/g, '')] = files(key).default
})

Object.keys(components).forEach(key => {
  Vue.component(key, components[key])
})

Vue.use(VueGtag, {
  config: { id: "UA-4473597-18" },
},router);

Vue.use(SocialSharing);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
