<template>
  <section>
    <base-tittle>地図について</base-tittle>

    <v-card class="fran-wrap d-flex" outlined tile>
      <v-card-text class="text--primary">
        この地図は、以下のデータやツールを使用して日本気象株式会社が作成したものです。
        <ul>
          <li>地図データ</li>
            <ul>
              <li><a href="https://www.openstreetmap.org/">&copy;OpenStreetMap</a> <span>contributors</span></li>
              <li><a href="https://maps.gsi.go.jp/development/ichiran.html#dem">標高タイル &copy;国土地理院</a></li>
              <li><a href="http://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-L03-b_r.html">国土数値情報 土地利用細分メッシュ</a></li>
            </ul>
          <li>地図作成</li>
            <ul>
              <li><a href="http://openmaptiles.org/">OpenMapTiles</a></li>
            </ul>
          <li>地図表示</li>
            <ul>
              <li><a href="http://leafletjs.com/">Leaflet</a></li>
              <li><a href="https://maplibre.org/">MapLibre</a></li>
            </ul>
        </ul>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
  export default {
    name: 'AboutMap',

    components: {
    },

    data: () => ({
      //
    }),
  }
</script>
