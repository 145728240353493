/* eslint-disable */

import regions from '../assets/data/area/regions.json'
import areas from '../assets/data/area/areas.json'
//import cities from '../assets/data/area/cities.json'
const cities = JSON.parse(localStorage.getItem('afterFranForecastList'));

const messageSelectRegionFirst = 'まずは地方を選択してください'
const messageSelectAreaFirst = 'まずは都道府県を選択してください'

export default {
    // エリア選択ミックスイン
    // 2020-09-15 連 新規作成
    // エリア専用

    name: 'AreaSelect',

    // エリア選択
    data: () => ({
        regionData: null,
        regionList: regions,

        areaData: null,
        areaList: [messageSelectRegionFirst],

        cityData: null,
        cityList: [messageSelectAreaFirst],
    }),

    methods: {
        closeMyself() {
            // NavBarへのデータ
            this.$emit('closeMe', false)
        },

        // 都道府県リスト
        collectAreaList() {
            //console.log(`collectAreaList called! Selected region: ${ JSON.stringify(this.regionData)}`)
            this.areaData = null
            this.cityData = null
            let tmpAreaList = []
            if (this.regionData) {
                const regionName = this.regionData.name
                tmpAreaList = areas.filter((area) => {
                    if (area.regionName === regionName) return area
                })
            }
            if (tmpAreaList.length < 1) {
                tmpAreaList = [messageSelectRegionFirst]
            }
            this.areaList = tmpAreaList
        },

        // 市町村リスト
        collectCityList() {
            //console.log(`collectCityList called! Selected region: ${ JSON.stringify(this.areaData)}`)
            this.cityData = null
            let tmpCityList = []
            if (this.areaData) {
                const areaName = this.areaData.name
                tmpCityList = cities.filter((city) => {
                    if (city.areaName === areaName) return city
                })
            }
            if (tmpCityList.length < 1) {
                tmpCityList = [messageSelectAreaFirst]
            }
            this.cityList = tmpCityList
        },
        
        findCity(cityId) {
            if (!cityId) return null
            let result = null
            const filteredCities = cities.filter((city) => {
                if (city.AmedasId === cityId) return city
            })
            if (filteredCities.length > 0) result = filteredCities[0]
            return result
        },
        
        findArea(id) {
            if(!id) return null
            let result = null
            const filteredAreas = areas.filter((area) => {
                if (area.id === id) return area
            })
            if (filteredAreas.length > 0) result = filteredAreas[0]
            return result
        },

        // 選択完了
        selected() {},
    },
}