<template>
  <section>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-form
      ref="form"
      v-model="validForm"
      lazy-validation
    >
      <v-card class="d-flex" outlined tile>
        <div class="mx-auto">
          <v-card-text class="text-h5 text-center px-6 text--darken-2">
            <p>お天気NFTが当たる！</p>
          </v-card-text>
          <v-card-text class="text-center px-8 grey--text text--darken-2">
            {{ eventContent }}
            <p v-if="!eventExpired">回答期限：{{ endDateStr }}</p>
            <p v-else>回答期限を過ぎました</p>
          </v-card-text>
          <template v-if="!eventExpired && !eventNotYet">
            <template v-if="eventForm==='multiple choice'">
              <v-select v-model="choice" :items="choices" outlined></v-select>
            </template>
            <template v-else>
              <div class="px-10 py-3">
                <v-text-field
                  label="回答（半角数字）"
                  v-model="userAnswer"
                  required
                  :rules="userAnswerRules"
                  ></v-text-field>
              </div>
            </template>
            <div class="px-10 py-3">
              <template v-if="loginTool==='passwordless'">
                <v-text-field
                  label="メールアドレス"
                  v-model="userEmail"
                  disabled
                  ></v-text-field>
              </template>
              <template v-else-if="loginTool==='social'">
                <v-text-field
                  label="メールアドレス"
                  v-model="userEmail"
                  required
                  :rules="userEmailRules"
                  ></v-text-field>
              </template>
              <template v-else>
                <div class="mail-login-btn">
                  応募にはメールアドレスでの
                  <v-btn
                    @click="mailSignIn"
                    color="#a260bf"
                    class="font-weight-regular caption"
                    width="100px"
                    height="29px"
                    depressed
                    dark
                    tile
                    top
                  >
                    <v-icon left dense class="login-google-icon">mdi-email</v-icon>
                  ログイン</v-btn>
                  が必要です。
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <v-card-text v-if="userParticipated" class="text-center px-10 grey--text text--darken-2">
              <p v-if="eventForm==='multiple choice'">「{{ choice }}」で応募されました。</p>
              <p v-else>「{{ userAnswer }}」で応募されました。</p>
              <p>登録アドレスは「{{ userEmail }}」です。</p>
            </v-card-text>
          </template>
          <v-card-actions class="ma-2">
            <v-btn
              v-if="!eventExpired && !eventNotYet"
              @click="closeMyself"
              :disabled="this.processing"
              class="font-weight-regular ma-auto"
              color="primary"
              width="130px"
              max-width="130px"
              outlined
              dark
              tile
              top
            >
              キャンセル
            </v-btn>
            <v-btn
              v-if="this.user && !eventExpired && !eventNotYet"
              @click="executeSetNftParticipation"
              :disabled="!this.enableSetNftParticipation"
              class="font-weight-regular ma-auto dia-receive-btn"
              color="primary"
              width="130px"
              max-width="130px"
              depressed
              dark
              tile
              top
            >
              <template v-if="!userParticipated">応募</template>
              <template v-else>応募(変更)</template>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </section>
</template>

<script>
  import AreaSelect from '@/mixins/area-select.js';
  import axios from 'axios';
  import QueryFranUser from '@/mixins/query-franuser.js';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getFranNftParticipation } from '@/graphql/queries';
  import { setNftParticipation, updateFranUser } from '@/graphql/mutations';
  import dayjs from 'dayjs';
  import 'dayjs/locale/ja';
  dayjs.locale('ja');

  export default {
    name: 'DiaNft',

    mixins: [AreaSelect, QueryFranUser],

    props: ['diaNftToggle', 'nft_event_id', 'amedas_id'],

    data: () => ({
      loginTool: '',
      validForm: false,
      user: null,
      userParticipated: false,
      userAnswer: '',
      userAnswerRules: [
        v => !!v || '回答が入力されていません',
        v => /^[0-9]+$/.test(v) || '半角数字で入力してください',
      ],
      userEmail: '',
      userEmailRules: [
        v => /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/.test(v) || 'メールアドレスの形式で入力してください'
      ],
      processing: false,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
      snackbarTimeout: 2000,
      amedasId: '',
      amedasName: '',
      nftEventId: '',
      eventContent: '',
      eventForm: '',
      startDate: '',
      endDate: '',
      deletedAt: '',
      choice: '',
      choices: [],
      eventNotYet: false,
      eventExpired: false,
      endDateStr: ''
    }),
    
    mounted: async function () {
      this.user = await this.$store.state.franUser;
      await this.checkLoginTool();
      await this.updateNft();
    },
    
    computed: {
      enableSetNftParticipation: function() {
        let result = false;
        if (this.validForm === true && this.processing === false &&
          (this.userAnswer || this.choice) && this.userEmail) {
          result = true;
        }
        return result;
      }
    },
    
    methods: {
      async updateNft() {
        this.amedasId = await this.amedas_id;
        this.amedasName = await this.findCity(this.amedasId).pointName;
        this.nftEventId = await this.nft_event_id;
        await this.loadNftInformation();
        await this.checkDate();
        await this.loadNftParticipation();
      },
      
      checkLoginTool() {
        if (this.user) {
          const id = this.user.id;
          const MailRegExp = /^Email/;
          if (MailRegExp.test(id)) {
            this.loginTool = 'passwordless';
          } else {
            this.loginTool = 'social';
          }
          if (this.user.email) {
            this.userEmail = this.user.email;
          }
        }
      },
      
      async loadNftInformation() {
        const date = new Date();
        const dateStr = String(date.getFullYear()) + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0');
        const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
        const filePath = 'https://d3e48edr1ew123.cloudfront.net/json-' + env + '/nft-event/' + + this.amedasId + '/' + this.nftEventId + '/nft-event-information.json?' + dateStr;
        try {
          const response = await axios.get(filePath);
          const data = JSON.parse(JSON.stringify(response.data));
          const eventInformation = data['information'];
          this.eventContent = eventInformation['event_content'];
          this.eventForm = eventInformation['event_form'];
          const startDateOrigin = eventInformation['start_date'];
          const endDateOrigin = eventInformation['end_date'];
          const startDateYear = startDateOrigin.substr(0, 4);
          const startDateMonth = startDateOrigin.substr(4, 2);
          const startDateDay = startDateOrigin.substr(6, 2);
          const endDateYear = endDateOrigin.substr(0, 4);
          const endDateMonth = endDateOrigin.substr(4, 2);
          const endDateDay = endDateOrigin.substr(6, 2);
          this.startDate = new Date(startDateYear, startDateMonth - 1, startDateDay);
          this.endDate = new Date(endDateYear, endDateMonth - 1, endDateDay);
          this.deletedAt = eventInformation['deletedAt'];
          this.choices = eventInformation['choices'];
          if (this.choices) {
            this.choice = eventInformation['choices'][0];
          }
        } catch(e) {
          this.closeMyself();
          console.error(e);
        }
      },
      
      checkDate() {
        const now = new Date();
        if (now < this.startDate) {
          this.eventNotYet = true;
        } else {
          this.eventNotYet = false;
        }
        if (now > this.endDate) {
          this.eventExpired = true;
        } else {
          this.eventExpired = false;
        }
        this.displayEndDate(this.endDate);
      },
      
      displayEndDate(endDate) {
        const year = endDate.getFullYear();
        const month = endDate.getMonth() + 1;
        const day = endDate.getDate();
        this.endDateStr = String(year) + '年' + String(month) + '月' + String(day) + '日';
      },
      
      async loadNftParticipation() {
        if (this.user) {
          try {
            const input = { user_id: this.user.id, nft_event_id: this.nftEventId };
            const result = await API.graphql(graphqlOperation(getFranNftParticipation, input));
            const resultData = result.data.getFranNftParticipation;
            if (resultData) {
              if (this.eventForm === 'multiple choice') {
                this.choice = this.choices[resultData.user_answer];
              } else {
                this.userAnswer = resultData.user_answer;
              }
              this.userParticipated = true;
            } else {
              this.userParticipated = false;
            }
          } catch(e) {
            console.error(e);
          }
        }
      },
      
      closeMyself() {
        this.$emit('closeMe', false);
      },
      
      async setUserEmail() {
        if (this.loginTool === 'social') {
          const input = {
            id: this.user.id,
            email: this.userEmail
          };
          await API.graphql(graphqlOperation(updateFranUser, { input: input }));
        }
      },
      
      async executeSetNftParticipation() {
        this.processing = true;
        this.$store.commit('progressCircleOn');
        if (this.eventForm === 'multiple choice') {
          this.userAnswer = this.choices.indexOf(this.choice);
        }
        try {
          await this.setUserEmail();
        } catch (e) {
          this.$store.commit('progressCircleOff');
          this.snackbarText = 'メールアドレスの値が不正です';
          this.showErrorSnackBar();
          this.processing = false;
          return;
        }
        try {
          const input = {
            user_id: this.user.id,
            nft_event_id: this.nftEventId,
            amedas_id: this.amedasId,
            user_answer: this.userAnswer,
            end_date: this.endDate,
            deletedAt: this.deletedAt
          };
          console.log(`executeSetNftParticipation : ${JSON.stringify(input)}`);
          const gqlRes = await API.graphql(graphqlOperation(setNftParticipation, {input: input}));
          this.$store.commit('progressCircleOff');
          if (gqlRes && gqlRes.data && gqlRes.data.SetNftParticipation) {
            const result = gqlRes.data.SetNftParticipation;
            const answer = result['user_answer'];
            if (answer !== -999) {
              this.snackbarText = '応募しました！';
              this.showSnackbar = true;
              this.snackbarColor = 'primary';
              setTimeout(function(){ 
                this.closeMyself();
              }.bind(this), this.snackbarTimeout);
            } else {
              this.snackbarText = '回答に失敗しました';
              this.showErrorSnackBar();
            }
          } else {
            this.snackbarText = '回答に失敗しました';
            this.showErrorSnackBar();
          }
          this.processing = false;
        } catch (error) {
          console.error(`Failed to execute SetNftParticipation: ${JSON.stringify(error)}`);
          this.$store.commit('progressCircleOff');
          this.snackbarText = '回答に失敗しました';
          this.showErrorSnackBar();
          this.processing = false;
        }
      },
      
      showErrorSnackBar() {
        this.showSnackbar = true;
        this.snackbarColor = 'error';
        setTimeout(function(){this.closeMyself();}.bind(this), this.snackbarTimeout);
      },
      
      mailSignIn() {
        this.$router.push({path: '/mailsignin/signin/0'},() => {});
      }
    }
  };
</script>
