import { API, graphqlOperation } from 'aws-amplify';
import { getFranUser } from '../graphql/queries';

export default {
    // FranUser クエリーミックスイン
    name: 'QueryFranUser',
    methods: {
        /**
         * FranUser データを取得する
         */
        async getFranUserData(userId) {
            try {
              const input = { id: userId };
              const result = await API.graphql(graphqlOperation(getFranUser, input));
              //console.log(`FranUser: ${JSON.stringify(result)}`)
              return result.data.getFranUser;
            } catch(error) {
              const errorMessage = `Failed to find FranUser: ${JSON.stringify(error)}`;
              console.error(errorMessage);
              throw new Error(errorMessage);
            }
        },
    }
};
