<!-- ホームページ -->

<template>
  <section>
    <!-- FRAN V2 マップ  -->
    <fran-map />
  </section>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      FranMap: () => import('@/components/home/FranMap'),
    },
  };
</script>
