<!-- エリア選択 ダイアログ -->

<template>
  <section>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>
    
    <base-tittle>退会</base-tittle>

    <v-card class="fran-dialog d-flex" tile>
      <div>
        <v-card-title>
          退会してもよろしいですか？
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="closeMyself"
            class="font-weight-regular"
            width="130px"
            max-width="130px"
            outlined
            tile
            top
            rounded
          >
            キャンセル
          </v-btn>

          <v-btn
            v-if="this.stateFranUser"
            @click="withdrawFromFran"
            class="font-weight-regular"
            width="130px"
            max-width="130px"
            depressed
            tile
            top
            rounded
          >
            退会する
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </div>
    </v-card>
  </section>
</template>

<script>
  import { API, graphqlOperation, Auth } from 'aws-amplify';
  import { deleteUser, deleteFranMailNews } from '../../graphql/mutations';

  export default {
    name: 'DiaWithdrawal',

    // 親コンポーネントからのデータ
    props: ['diaToggle'],

    data: () => ({
      user: null,
      // Snackbar
      showSnackbar: false,
      snackbarText: '退会処理が失敗しました',
      snackbarColor: 'warning',
      snackbarTimeout: 2000
    }),
    
    computed: {
      stateFranUser: function() {
        return this.$store.state.franUser;
      },
    },

    methods: {
      closeMyself() {
        // 親コンポーネントへのデータ
        this.$emit('closeMe', false);
      },
      /**
       * 退会処理を実行する
       */
      async withdrawFromFran() {
        // console.log(`withdrawFromFran called`)
        if (this.stateFranUser) {
          // console.log(`target userId: ${this.stateFranUser.id}`)
          // 
          try{
            if (this.stateFranUser.email) {
              const input = { mail: this.stateFranUser.email };
              await API.graphql(graphqlOperation(deleteFranMailNews, { input: input }));
            }
          }catch(error){
            //console.error(`error: ${JSON.stringify(error)}`);
          }
          try {
            const input = {
              id: this.stateFranUser.id
            };
            this.$store.commit('progressCircleOn');
            await this.unsubscribeNotification();
            const result = await API.graphql(graphqlOperation(deleteUser, {input: input}));
            // console.log(`Withdraw result: ${JSON.stringify(result)}`)
            if (result && result.data) {
              const withdrawResult = result.data.DeleteUser;
              let withdrawed = this.isWithdrawed(withdrawResult);
              if (withdrawed === true) {
                // 退会処理が成功したらユーザーのグローバルサインアウトを実行する
                await this.globalSignOut();
                this.closeMyself();
              } else {
                this.showSnackbar = true;
              }
            }
          } catch (error) {
            console.error(`Failed to withdrawFromFran: ${JSON.stringify(error)}`);
            this.showSnackbar = true;
          } finally {
            this.$store.commit('progressCircleOff');
          }
        }
      },
      isWithdrawed(withdrawResult) {
        // 退会処理が成功したかどうかを判別する
        let result = false
        if (withdrawResult && withdrawResult.id != '-99') {
          result = true
        }
        return result
      },
      async globalSignOut() {
        try {
          await Auth.signOut({ global: true });
        } catch (error) {
          console.error(`Failed to signing out: ${JSON.stringify(error)}`)
        }
      },
      async unsubscribeNotification() {//web-push通知無効化
        navigator.serviceWorker.ready.then(function(reg) {
          reg.pushManager.getSubscription().then(function(subscription) {
            if(subscription){
              subscription.unsubscribe();
              }
          });
        });
      },
    },
  }
</script>
