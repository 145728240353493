/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:d3cdf0f6-fbb5-4680-a9e5-292fdf1bcd03",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_XWf798U6u",
    "aws_user_pools_web_client_id": "p30pc749igtu33rrp1bh0q5a8",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://26gw5zakjjdbpdbkb4nkfno5wm.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ndxhuxzbsnabld6zkgbivdoufe",
    "aws_mobile_analytics_app_id": "8ea1f087fd7440fca427c8d1fee32843",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "8ea1f087fd7440fca427c8d1fee32843",
            "region": "us-west-2"
        }
    }
};


export default awsmobile;
