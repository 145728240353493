<template>
  <section>
    <v-card class="fran-wrap pb-0" outlined tile>
    <div class="fran-wrap-center">
      <v-container class="ma-0 pa-0">
        <v-row class="fran-headtitle" no-gutters>
          <v-col>
            <span>{{ franPastPeriodStr }}のFRAN予報結果</span>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="fran-prize-list ma-0 pa-0">
        <div class="fran-prize-list-center">選んだエリア：{{ selectedAmedasName }}</div>
      </v-container>
    </div>
    </v-card>
    <v-card class="fran-wrap pt-0 d-flex result-map" outlined tile>
      <template v-if="noResult">
        <div class="noresult-image">
          <v-img :src="require(`@/assets/img/result_notyet.png`)"></v-img>
        </div>
      </template>
      <v-container class="pt-0">
        <div class="fran-flex"></div>
        <div class="result-map" id="map">
          <div class="legend-fran-result">
            <v-img :src="require(`@/assets/img/legend_fran_result.png`)"></v-img>
          </div>
        </div>
      </v-container>
    </v-card>
  </section>
</template>

<script>
import { Map, NavigationControl } from 'maplibre-gl';
import { markRaw } from 'vue';

import { API, graphqlOperation } from 'aws-amplify';
import { getFranSelectedPointsV2 } from '@/graphql/queries';
import axios from 'axios';
import AreaSelect from '@/mixins/area-select.js';

const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'];

export default {
  name: 'ResultMap',
  
  mixins: [AreaSelect],
  
  data () {
    return {
      user: '',
      zoom: 9,
      center: [139.750, 35.692],
      selectedAmedasId: '44132',
      selectedAmedasName: '未選択',
      selectedAmedasCoord: '',
      timestampFranNow: '',
      franPastPeriodStr: '',
      noResult: false
    };
  },
  
  computed: {
    stateFranUser: function() {
      return this.$store.state.franUser;
    }
  },
    
  watch: {
    async stateFranUser (newValue) {
      if (newValue) {
        this.user = await this.$store.state.franUser;
        await this.getPeriod();
        await this.loadUserPoint();
      }
    }
  },

  mounted: async function () {
    this.user = await this.$store.state.franUser;
    await this.getPeriod();
    await this.loadUserPoint();
    await this.setupMap();
  },
  
  unmounted: function () {
    this.map.value?.remove();
  },
  
  methods: {
    getPeriod() {
      let dObj = new Date();
      const monthNow = dObj.getMonth() + 1;
      const dayNow = dObj.getDate();
      const hourNow = dObj.getHours();
      const weekNow = dayOfWeekStr[dObj.getDay()];
      const timestampNow = Math.floor(dObj.getTime() / 1000) + 32400; // getTimeはミリ秒なので1000で割る 日本時間での経過時間(≠unixtime)
      const timestampFranForecast = Math.floor(timestampNow / 43200) * 43200 - 32400; // 現在時刻より前で直近の0時or12時のunixtime
      this.timestampFranNow = timestampFranForecast - 86400; // さらに2つ前の0時or12時のunixtime
      if (hourNow >= 12) {
        this.franPastPeriodStr = monthNow + '/' + dayNow + '(' + weekNow + ')午前';
        dObj.setDate(dayNow - 1);
        const yearYesterday = dObj.getFullYear();
        const monthYesterday = dObj.getMonth() + 1;
        const dayYesterday = dObj.getDate();
        this.franPastFileStr = yearYesterday.toString() + ('00' + monthYesterday.toString()).slice(-2) + ('00' + dayYesterday.toString()).slice(-2) + '12';
      } else {
        dObj.setDate(dayNow - 1);
        const yearYesterday = dObj.getFullYear();
        const monthYesterday = dObj.getMonth() + 1;
        const dayYesterday = dObj.getDate();
        const weekYesterday = dayOfWeekStr[dObj.getDay()];
        this.franPastPeriodStr = monthYesterday + '/' + dayYesterday + '(' + weekYesterday  + ')午後';
        this.franPastFileStr = yearYesterday.toString() + ('00' + monthYesterday.toString()).slice(-2) + ('00' + dayYesterday.toString()).slice(-2) + '00';
      }
    },
    
    async loadUserPoint() {
      if (this.user) {
        try {
          const input = { id: this.user.id, forecast_date: this.timestampFranNow };
          const result = await API.graphql(graphqlOperation(getFranSelectedPointsV2, input));
          const resultData = result.data.getFranSelectedPointsV2;
          if (resultData) {
            this.selectedAmedasId = resultData.selected_amedas_id;
            const selectedAmedasCity = this.findCity(this.selectedAmedasId);
            this.selectedAmedasName = selectedAmedasCity.pointName;
            this.selectedAmedasCoord = [selectedAmedasCity.longitude, selectedAmedasCity.latitude];
            this.center = this.selectedAmedasCoord;
          }
        } catch(e) {
          console.error(e);
        }
      }
    },
    
    async setupMap() {
      try {
        await axios.get('https://d1ihqv2p7rmgfm.cloudfront.net/' + this.franPastFileStr + '/metadata.json');
      }  catch(e) {
        this.noResult = true;
        console.error(e);
      }
      
      let map = markRaw(new Map({
        container: 'map',
        style: {
          version: 8,
          glyphs: 'https://d3342ng3auo02n.cloudfront.net/font/{fontstack}/{range}.pbf',
          sources: {
            base: {
              type: 'raster',
              tileSize: 256,
              tiles: [
                'https://d3342ng3auo02n.cloudfront.net/v2/base@2x/{z}/{x}/{y}.png',
              ],
              minzoom: 0,
              maxzoom: 14
            },
            points: {
              type: 'vector',
              tiles: ['https://d1ihqv2p7rmgfm.cloudfront.net/' + this.franPastFileStr + '/{z}/{x}/{y}.pbf'],
              minzoom: 6,
              maxzoom: 11
            }
          },
          layers: [
            { 'id': 'base', 'type': 'raster', 'source': 'base'},
            { 'id': 'correct-points', 'type': 'circle', 'source': 'points', 'source-layer': 'resulttile',
              'paint': { 'circle-color': '#0086d1', 'circle-radius': { 'base': 2, 'stops': [[6, 5], [10.5, 20]] }},
              'maxzoom': 11,
              'filter': ['==', 'fran_result', '0']
            },
            { 'id': 'wrong-points', 'type': 'circle', 'source': 'points', 'source-layer': 'resulttile',
              'paint': { 'circle-color': '#f39700', 'circle-radius': { 'base': 2, 'stops': [[6, 5], [10.5, 20]] }},
              'maxzoom': 11,
              'filter': ['==', 'fran_result', '1']
            },
            { 'id': 'points-name', 'type': 'symbol', 'source': 'points', 'source-layer': 'resulttile',
              'minzoom': 8, 'maxzoom': 10.5,
              'layout': { 'text-field': ['get', 'point_name'], 'text-offset': [0, -1.5] },
              'paint': { 'text-halo-color': 'rgba(255, 255, 255, 0.4)', 'text-halo-width': 4 }
            }
          ]
        },
        center: this.center,
        zoom: this.zoom,
        minZoom: 6,
        maxZoom: 10
      }));
      map.addControl(new NavigationControl({ showCompass: false }));
      return {
        map
      };
    }
  }
};
</script>

<style scoped>
@import '~maplibre-gl/dist/maplibre-gl.css';
</style>
