<!-- ナビゲーション -->
<template>
  <section>
    <v-app-bar height="53" color="#289FDA" dense dark>
      <div class="fran-menu fran-menu-drawer">
        <v-btn icon @click.stop="drawer = !drawer">
          <v-img
            :src="require('@/assets/img/nav_menu.svg')"
            width="43"
          />
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class="fran-logo">
        <a href="/">
          <v-img
            alt="FRAN"
            class="shrink"
            :src="require('@/assets/img/logo.png')"
            width="109"
            contain
            to="/help"
          />
        </a>
      </div>
      <div class="fran-menu fran-menu-btn">
        <v-btn icon to ="/weather" v-bind:ripple="false">
          <v-img
            :src="require('@/assets/img/nav_weather.svg')"
            width="43"
          />
        </v-btn>
        <!--<v-btn icon to ="/pay" v-bind:ripple="false">-->
        <!--  <v-badge :content="$store.state.prizeFlag" :value="$store.state.prizeFlag" color="red" dot overlap>-->
        <!--  <v-img-->
        <!--    :src="require('@/assets/img/nav_pay.svg')"-->
        <!--    width="43"-->
        <!--  />-->
        <!--  </v-badge>-->
        <!--</v-btn>        -->
      </div>
    </v-app-bar>
    <!-- ナビゲーションドロワーコンポーネント -->
    <nav-drawer v-model="drawer" />
  </section>
</template>

<script>
  import DiaClose from '@/mixins/base/dia-close.js';

  export default {
    name: 'NavBar',

    mixins: [DiaClose],

    components: {
      NavDrawer: () => import('@/components/index/NavDrawer'),
    },

    data: () => ({
      drawer: null,
    }),
    
  };
</script>
