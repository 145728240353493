/* eslint-disable */

export default {
    // ダイアログ クローズ
    // 親コンポーネント用
    // base類!!!共用

    name: 'DiaClose',

    data: () => ({
        diaToggle: false,
        diaEventToggle: false,
        diaShopToggle: false,
        diaShopListToggle: false,
        diaNftToggle: false
    }),

    methods: {
        closeChild(info) {
            this.diaToggle = info;
        },
        closeEventChild(info) {
            this.diaEventToggle = info;
        },
        closeShopChild(info) {
            this.diaShopToggle = info;
        },
        closeShopListChild(info) {
            this.diaShopListToggle = info;
        },
        closeNftChild(info) {
            this.diaNftToggle = info;
        }
    },

}