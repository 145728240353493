<template>
  <section>
    <v-footer app v-if="showAdBanner" :height=bannerHeight color="#289FDA" dense dark class="show-ad-footer pa-0">
      <div class="ad-banner-bottom">
        <v-btn icon dark @click="closeAdBanner" class="ad-banner-close-bottom">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template>
          <img :src=bannerImg :srcset="bannerSrcSet" @click="sendClickEvent(bannerURL, 'bottom-banner')">
        </template>
      </div>
    </v-footer>
  </section>
</template>

<script>
import axios from 'axios';
import AdFunc from '@/mixins/ad-func.js';
export default {
  name: 'AdBannerBottom',
  
  mixins: [AdFunc],

  data: () => ({
    showAdBanner: false,
    bannerImg: "",
    bannerSrcSet : "",
    bannerURL: "",
    bannerHeight: 0
  }),

  created() {
    //this.$store.commit('changeAdHeight', 50);
  },
  
  mounted() {
    //this.$store.commit('changeAdHeight', 50);
    this.loadAdInformation();
  },

  methods: {
    async loadAdInformation() {
      const date = new Date();
      const dateStr = String(date.getFullYear()) + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0');
      const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
      const filePath = 'https://d3e48edr1ew123.cloudfront.net/json-' + env + '/ad-information.json?' + dateStr;
      try {
        const response = await axios.get(filePath);
        const data = JSON.parse(JSON.stringify(response.data));
        this.showAdBanner = data["banner"]["flag"];

        const adNum = Math.floor( Math.random() * data["banner"]["list"].length ) ;// 広告ランダム表示
        const adData = data["banner"]["list"][adNum];
        if(this.showAdBanner){
          this.bannerImg = adData["img"]["1x"];
          this.bannerURL = adData["url"];
          this.bannerHeight = adData["size"][1];
          this.$store.commit('changeAdHeight', this.bannerHeight);
          let srclist = [];
          for (let key in adData["img"]) {
            srclist.push(adData["img"][key] + " " + key);
          }
          this.bannerSrcSet = srclist.join(", ");
        }
      } catch(e) {
        console.error(e);
      }
    },
    closeAdBanner() {
      this.$store.commit('changeAdHeight', 0);
      this.showAdBanner=false;
    },
  },
};
</script>

