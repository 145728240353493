<template>
  <section>
    <v-card class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
      <v-container class="ma-0 pa-0">
        <v-row class="fran-headtitle" no-gutters>
          <v-col>
            <span>ショウキンについて</span>
          </v-col>
        </v-row>
      </v-container>
      
      <v-container class="fran-prize-list ma-0 pa-0">
        <v-row>
          <v-col cols="4">金額：</v-col>
          <v-col cols="8" v-if="campaignFlagAmount" class="red--text" >{{ shokinAmount }}<br>{{ campaignCommentAmount }}</v-col>
          <v-col cols="8" v-else>{{ shokinAmount }}</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="4">当選人数：</v-col>
          <v-col cols="8" v-if="campaignFlagCount"><p class="red--text mb-0" >{{ shokinCount }}<br>{{ campaignCommentCount }}</p><p class="mb-0 fran-prize-list-small">※当選確率は該当者数により変動します。</p></v-col>
          <v-col cols="8" v-else><p class="mb-0" >{{ shokinCount }}</p><p class="mb-0 fran-prize-list-small">※当選確率は該当者数により変動します。</p></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="4">受取期限：</v-col>
          <v-col cols="8">当選から6日以内</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="4">受取方法：</v-col>
          <v-col cols="8">LINE Pay</v-col>
        </v-row>
      </v-container>
      </div>
    </v-card>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AboutPrize',
  
  data () {
    return {
      shokinAmount: '',
      shokinCount: '',
      campaignFlagAmount: false,
      campaignFlagCount: false,
      campaignCommentAmount:'',
      campaignCommentCount:'',
    };
  },
  
  mounted: function() {
    this.loadShokinInformation();
  },
  
  methods: {
    async loadShokinInformation() {
      const date = new Date();
      const dateStr = String(date.getFullYear()) + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0');
      const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
      const filePath = 'https://d3e48edr1ew123.cloudfront.net/json-' + env + '/shokin-information.json?' + dateStr;
      try {
        const response = await axios.get(filePath);
        const data = JSON.parse(JSON.stringify(response.data));
        this.shokinAmount = data["text_money"];
        this.shokinCount = data["text_people"];
        if(data["campaign_flag_money"]){
          this.campaignCommentAmount = data["comment_money"];
          this.campaignFlagAmount = true;
        }
        if(data["campaign_flag_people"]){
          this.campaignCommentCount = data["comment_people"];
          this.campaignFlagCount = true;
        }
      } catch(e) {
        console.error(e);
      }
    }
  }
};
</script>
