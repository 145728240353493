<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="cancel"
      @keydown.esc="cancel"
      v-model="settingDialog"
      max-width="500"
    >
      <v-card class="ma-0 pt-1 pb-3 pl-4 pr-4">
        <v-card-title class="fran-headtitle grey--text text--darken-1"><v-icon left>mdi-bell</v-icon>ブラウザ通知設定</v-card-title>

        <v-card-text v-if="notSupported" class="ma-0 pa-0 red--text">
          プッシュ通知に対応していないブラウザです。
        </v-card-text>

        <v-card-text v-if="notLoggedIn" class="ma-0 pa-0 red--text">
          ログイン後に設定変更できます。
        </v-card-text>

        <v-card-text v-if="blocked" class="ma-0 pa-0 red--text">
          ブラウザ通知がブロックされています。<br>通知を受け取る場合はブロックを解除してください。
        </v-card-text>
        <!-- 
        <v-card-text v-if="blocked" class="ma-0 pa-0 blue--text">
          ブロック解除手順はこちら
        </v-card-text>
        -->
        
        <v-card-text v-if="doubleRegistered" class="ma-0 pa-0 red--text">
          同じブラウザ上でプッシュ通知を設定できるのは1アカウントまでです。
        </v-card-text>
        <!-- 
        <v-card-text v-if="doubleRegistered" class="ma-0 pa-0 blue--text">
          ブラウザ設定をリセットする手順はこちら
        </v-card-text>
        -->

        <v-checkbox
            v-model="daily"
            label="応募締切時間のお知らせをうけとる"
            color="#289FDA"
            hide-details
            :disabled="disabled"
        ></v-checkbox>
        <v-checkbox
            v-model="scratch"
            label="スクラッチ獲得のお知らせをうけとる"
            color="#289FDA"
            hide-details
            :disabled="disabled"
        ></v-checkbox>
        <v-checkbox
            v-model="news"
            label="イベントやキャンペーンのお知らせをうけとる"
            color="#289FDA"
            hide-details
            :disabled="disabled"
        ></v-checkbox>

        <v-card-text v-if="!disabled" class="ma-0 pa-0 pt-6">
          ポップアップが表示された場合は「許可する」を選択してください。
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="#289FDA"
            text
            @click="cancel"
          >
            キャンセル
          </v-btn>

          <v-btn
            color="#289FDA"
            dark
            depressed 
            class="dia-receive-btn ml-2"
            @click="setValue"
            :disabled="disabled"
          >
            設定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { getFranNotificationPermission } from '@/graphql/queries';
  import { createFranNotificationPermission, deleteFranNotificationPermission } from '@/graphql/mutations';
  export default {
    name: 'SetNotification',

    props: {
      settingDialog: {type: Boolean} ,
    },

    data(){
      return {
        blocked: false,
        notSupported: false,
        notLoggedIn: false,
        doubleRegistered: false,
        disabled: false,

        daily:false,
        scratch:false,
        news:false,

        initState: [],

        endpoint: '',
        pushKey : 'BDFPAdAzZR5Tm6YokfVzc9qSl3HzpxIVRZflZj5jChPcPdjabW1Goul3m-0H3q6-EeXhzLnM3q6xwM9JwQh0cKs' //web-pushで生成したpublicKey
      };
    },

    computed: {
      stateUser: function() {
        return this.$store.state.user;
      },
    },
    
    watch: {
      stateUser (newValue) {
        if (newValue && newValue.username) {
          this.isNotLoggedIn();
        }
      },
      async settingDialog(newValue){//設定ウィンドウキャンセルで閉じたときに設定ロードしなおす
        if(!newValue && !this.notLoggedIn){
          await this.loadSetting();
        }
      }
    },

    async mounted(){
      //console.log(Notification.permission);
      this.isPushSupported();//WebPush非対応ブラウザ
      if(!this.notSupported){//非対応表示を優先
        this.isNotLoggedIn();//ログイン時のみ設定変更できる
        const swReg = await navigator.serviceWorker.register("/service-worker.js");
        const subscription = await swReg.pushManager.getSubscription();
        this.endpoint = JSON.stringify(subscription);

        if(!this.notLoggedIn){
          this.loadSetting();
        }
        if ('permissions' in navigator) {
          let notificationPermission = await navigator.permissions.query({name:'notifications'});
          notificationPermission.onchange = () => {
            this.isBlocked();
          };
        }
        this.isBlocked();
      }
    },

    methods: {
      isPushSupported() { //非対応ブラウザ
        if ('serviceWorker' in navigator && 'PushManager' in window){
          return;
        }else{
          this.disabled = true;
          this.notSupported = true;
        }
      },

      isBlocked(){  //通知ブロック中
        if (Notification.permission == 'denied') {
          this.disabled = true;
          this.blocked = true;
        }
      },

      isNotLoggedIn(){  //未ログイン
        const u = this.$store.state.user;
        if(!u){
          this.disabled = true;
          this.notLoggedIn = true;
        }else{
          this.disabled = false;
          this.notLoggedIn = false;
          this.loadSetting();
        }
      },

      cancel() {  //設定を閉じる
        this.$emit('result', {'res': false});
      },

      async setValue() {  //設定が変更されていればDBに保存
        if(this.daily || this.scratch || this.news){
          await this.subscribe();
        }
        await this.saveSetting();
        this.$emit('result', {'res': true});
      },

      async subscribe() { //通知先エンドポイント登録
        const swReg = await navigator.serviceWorker.register("/service-worker.js");
        const subscription = await swReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlB64ToUint8Array(this.pushKey),
        });
        this.endpoint = JSON.stringify(subscription);
      },
      
      urlB64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, "+")
          .replace(/_/g, "/");

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      },

      async saveSetting(){
        let user = this.stateUser;
        const topics = ["daily", "scratch", "news"];
        let state = [];
        state[0] = this.daily;
        state[1] = this.scratch;
        state[2] = this.news;
        const me = this;
        try {
          for (let i = 0; i < 3; i++){
            if(state[i] != me.initState[i]){  //値が更新されていれば保存
              if(state[i]){
                const input = { topic: topics[i], endpoint: me.endpoint, id: user.username };
                //console.log(input);
                await API.graphql(graphqlOperation(createFranNotificationPermission, { input: input }));
              }
              else{
                const input = { topic: topics[i], endpoint: me.endpoint};
                //console.log(input);
                await API.graphql(graphqlOperation(deleteFranNotificationPermission, { input: input }));
              }
            }
          }
        } catch(e) {
          console.error(e);
        }
      },

      async loadSetting(){
        const topics = ["daily", "scratch", "news"];
        let state = [];
        const me = this;
        for (const topic of topics){
          try {
            const input = { topic: topic, endpoint: me.endpoint };
            const result = await API.graphql(graphqlOperation(getFranNotificationPermission, input));
            const resultData = result.data.getFranNotificationPermission;
            if (resultData) {
              state.push(true);
            }else{
              state.push(false);
            }
          } catch(e) {
            //console.error(e);
            if(e.errors[0].errorType == "Unauthorized"){
              this.doubleRegistered = true;
              this.disabled = true;
            }
          }
        }
        this.daily = state[0];
        this.scratch = state[1];
        this.news = state[2];
        this.initState = state;
      }

    },
  };
</script>