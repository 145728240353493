<template>
  <section>
    <v-dialog v-model="diaToggle" max-width="500">
      <dia-compensation
        v-bind:diaToggle="diaToggle"
        v-bind:compensationHistory="selectedCompensationHistory"
        v-on:closeMe="closeCompensationDialog($event)"
      />
    </v-dialog>
    <v-card class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
        <v-container class="ma-0 pa-0">
          <v-row class="fran-headtitle" no-gutters>
            <v-col>
              <span>ショウキン受け取り</span>
            </v-col>
          </v-row>
        </v-container>
        <template v-if="display_flg">
          <v-container
            v-for="h in compensationHistories"
            :key="h.createdAt"
            class="fran-prize-list ma-0 pa-0"
          >
            <v-row>
              <v-col cols="4">{{ getDisplayPeriod(h) }}</v-col>
              <v-col cols="4">{{ getDisplayPrice(h) }}</v-col>
              <v-col cols="3">
                <template v-if="h.compensation_flag==1 && isNotExpired(h)">
                  <div class="fran-get-btn">
                    <a @click.prevent="openCompensesationDialog(h)">受け取る</a>
                  </div>
                </template>
                <template v-else>
                  {{ getDisplayAcceptance(h) }}
                </template>
              </v-col>
              <v-col cols="1" class="share-twitter-col">
                <template v-if="h.compensation_flag==3">
                  <ShareNetwork
                    class="share-twitter-img"
                    url="a.franchan.jp"
                    tag="div"
                    network="twitter"
                    :title="`${getDisplayTweetPeriod(h)}のFRAN予報が外れたのでショウキンを獲得しました！みんなもFRANに参加しよう！`"
                    hashtags="FRAN予報">
                    <img width="19" height="16" :src="require('@/assets/img/twitter_logo.png')">
                  </ShareNetwork>
                </template>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
        </template>
        <template v-else>
          <div class="no-shokin-information">
            ここに受け取れるショウキンの情報が表示されます。
          </div>
        </template>
      </div>
    </v-card>
  </section>
</template>

<script>
  import DiaClose from '@/mixins/base/dia-close.js';
  import { API, graphqlOperation } from 'aws-amplify';
  import { listFranCompensationV2s } from '@/graphql/queries';
  import dayjs from 'dayjs';
  import 'dayjs/locale/ja';
  dayjs.locale('ja');
  
  const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'];

  export default {
    name: 'GetPrize',
    
    mixins: [DiaClose],
    
    components: {
      DiaCompensation: () => import('@/components/prize/DiaCompensation'),
    },
    
    data: () => ({
      compensationHistories: [],
      selectedCompensationHistory: null,
      display_flg: true
    }),
    
    mounted: async function () {
      try {
        const compensationHistories = await this.getUserAllCompensationHistories();
        this.compensationHistories = compensationHistories;
      } catch(error) {
        console.error(`mounted failed: ${error}`);
      }
    },
    
    methods: {
      async getUserAllCompensationHistories() {
        const u = this.$store.state.user;
        if (u) {
          try {
            // FranCompensation より受取可能な償金レコードを取得する
            const input = { id: u.username, sortDirection: 'DESC', filter: { or: [ { compensation_flag: { eq: 1 }  }, { compensation_flag: { eq: 3 } } ] } };
            const result = await API.graphql(graphqlOperation(listFranCompensationV2s, input));
            const compensationHistories = result.data.listFranCompensationV2s.items;
            if (!compensationHistories.length) {
              this.display_flg = false;
            }
            return compensationHistories;
          } catch(error) {
            this.display_flg = false;
            console.error(`Failed to load user all compensation histories: ${JSON.stringify(error)}`);
          }
        } else {
          this.display_flg = false;
        }
      },
      
      isNotExpired(history) {
        let result = false;
        const limitedAt = dayjs(history.limitedAt);
        if (limitedAt.isAfter(dayjs())) {
          result = true;
        }
        return result;
      },
      
      getDisplayPrice(history) {
        let result = '--';
        if (history && history.price) {
          result = `${String(history.price).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円`;
        }
        return result;
      },
      
      getDisplayPeriod(history) {
        const forecast_date = new Date((history.forecast_date + 43200) * 1000);
        const year = forecast_date.getFullYear();
        const month = forecast_date.getMonth() + 1;
        const day = forecast_date.getDate();
        const ampm = (forecast_date.getHours() == 0) ? '午前' : '午後';
        const result = year + '/' + month + '/' + day + ' ' + ampm;
        return result;
      },
      
      getDisplayTweetPeriod(history) {
        const forecast_date = new Date((history.forecast_date + 43200) * 1000);
        const month = forecast_date.getMonth() + 1;
        const day = forecast_date.getDate();
        const week = dayOfWeekStr[forecast_date.getDay()];
        const ampm = (forecast_date.getHours() == 0) ? '午前' : '午後';
        const result = month + '/' + day + '(' + week + ')' + ampm;
        return result;
      },
      
      getDisplayAcceptance(history) {
        let result = '';
        if (history.compensation_flag == 3) {
          result = '受け取り済み';
        } else {
          result = '期限切れ';
        }
        return result;
      },
      
      openCompensesationDialog(history) {
        this.selectedCompensationHistory = history;
        this.diaToggle = true;
      },
      
      async closeCompensationDialog(event) {
        this.closeChild(event);
        // 償金履歴を最新化する
        const compensationHistories = await this.getUserAllCompensationHistories();
        this.compensationHistories = compensationHistories;
        await this.$store.dispatch('loadPrizeNumber', this.$store.state.user);
      }
    },
  };
</script>
