<template>
  <section>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>
    <base-tittle>ショウキン受け取り</base-tittle>

    <v-form
      ref="form"
      v-model="validForm"
      lazy-validation
    >
      <v-card class="d-flex" outlined tile>
        <div class="mx-auto">
          <v-card-text class="text-center px-10 grey--text text--darken-2">
            LINE Pay ナンバーを入力してください<br>
            <p class="text-left compensation-description">スマートフォンでご覧になっている場合、<a href="https://line.me/R/app/1653458183-YelprGZ5/?liff.state=/setting/b2c/transfer">こちら</a>より確認できます。<br>
            PCのブラウザでご覧になっている場合、お手持ちのスマートフォンで下の二次元コードを読み取ることで確認することができます。</p>
            <img src="@/assets/img/linepay_qr.png">
          </v-card-text>
          <div class="px-10 py-3">
            <v-text-field
              label="LINE Pay ナンバー*"
              v-model="linePayNo"
              required
              :rules="linePayNoRules"
              :counter="11"
              maxlength="11"
            ></v-text-field>
          </div>
          <div class="px-10 py-3">
            <v-text-field
              label="携帯電話番号（下4桁）*"
              v-model="mobilePhoneNo"
              required
              :rules="mobilePhoneNoRules"
              :counter="4"
              maxlength="4"
            ></v-text-field>
          </div>
          <div class="px-7 grey--text">
            <small>*必須項目</small>
          </div>
          <v-card-actions class="ma-2">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeMyself"
              :disabled="this.processing"
              class="font-weight-regular mr-2"
              color="primary"
              width="130px"
              max-width="130px"
              outlined
              dark
              tile
              top
            >
              キャンセル
            </v-btn>
            <v-btn
              v-if="this.user"
              @click="executePayCompensation"
              :disabled="!this.enableExecutePayCompensation"
              class="font-weight-regular ml-2 dia-receive-btn"
              color="primary"
              width="130px"
              max-width="130px"
              depressed
              dark
              tile
              top
            >
              受け取り
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </section>
</template>

<script>
  import QueryFranUser from '@/mixins/query-franuser.js';
  import { API, graphqlOperation } from 'aws-amplify';
  import { payCompensation } from '@/graphql/mutations';
  import dayjs from 'dayjs';
  import 'dayjs/locale/ja';
  dayjs.locale('ja');

  export default {
    name: 'DiaCompensation',

    mixins: [QueryFranUser],

    props: ['diaToggle', 'compensationHistory'],

    data: () => ({
      validForm: false,
      user: null,
      linePayNo: '',
      linePayNoRules: [
        v => !!v || 'LINE Pay ナンバーは必須です',
        v => v.length === 11 || 'LINE Pay ナンバーは11桁の数字です',
        v => /^[0-9]+$/.test(v) || '数字で入力してください',
      ],
      mobilePhoneNo: '',
      mobilePhoneNoRules: [
        v => !!v || '携帯電話番号（下4桁）は必須です',
        v => v.length === 4 || '4桁の数字で入力してください',
        v => /^[0-9]+$/.test(v) || '数字で入力してください',
      ],
      processing: false,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
      snackbarTimeout: 2000
    }),
    
    mounted: function () {
      this.user = this.$store.state.user;
      this.resetInputValues();
    },
    
    computed: {
      enableExecutePayCompensation: function() {
        let result = false;
        if (this.compensationHistory && 
          this.validForm === true && this.processing === false &&
          this.linePayNo && this.mobilePhoneNo) {
          result = true;
        }
        return result;
      }
    },

    methods: {
      resetInputValues() {
        this.linePayNo = '';
        this.mobilePhoneNo = '';
        this.processing = false;
      },
      
      closeMyself() {
        this.$emit('closeMe', false);
      },
      
      async executePayCompensation() {
        this.processing = true;
        this.$store.commit('progressCircleOn');
        try {
          const input = {
            user_id: this.compensationHistory.id,
            forecast_date: this.compensationHistory.forecast_date,
            line_pay_no: this.linePayNo,
            amount: this.compensationHistory.price,
            mobile_phone_no: this.mobilePhoneNo
          };
          console.log(`executePayCompensation : ${JSON.stringify(input)}`);
          const gqlRes = await API.graphql(graphqlOperation(payCompensation, {input: input}));
          this.$store.commit('progressCircleOff');
          if (gqlRes && gqlRes.data && gqlRes.data.payCompensation) {
            const result = gqlRes.data.payCompensation;
            const flag = result['compensation_flag'];
            if (flag === 3) {
              this.snackbarText = '償金をLINE Pay に送金しました！';
              this.showSnackbar = true;
              this.snackbarColor = 'primary';
              setTimeout(function(){ 
                this.resetInputValues();
                this.closeMyself();
              }.bind(this), this.snackbarTimeout);
            } else if (flag === 1) {
              this.snackbarText = 'LINE Pay 情報が間違っています。確認して再入力してください。';
              this.showSnackbar = true;
              this.snackbarColor = 'warning';
              setTimeout(function(){ 
                this.resetInputValues();
              }.bind(this), this.snackbarTimeout);
            } else {
              this.showLinePayPdErrorSnackBar();
            }
          } else {
            this.showLinePayPdErrorSnackBar();
          }
        } catch (error) {
          console.error(`Failed to execute payCompensation: ${JSON.stringify(error)}`);
          this.$store.commit('progressCircleOff');
          this.showLinePayPdErrorSnackBar();
        }
      },
      
      showLinePayPdErrorSnackBar() {
        this.snackbarText = 'LINE Pay への送金が失敗しました';
        this.showSnackbar = true;
        this.snackbarColor = 'error';
        setTimeout(function(){this.closeMyself();}.bind(this), this.snackbarTimeout);
      }
    },
  };
</script>
