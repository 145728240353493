<template>
  <section>
    <base-tittle>FRAN予報について</base-tittle>

    <v-card class="fran-wrap d-flex" outlined tile>
      <v-card-text class="text--primary">
        <ul>
          <li>FRAN予報は雨が降るか、降らないかを予報する天気予報です。</li>
          <li>予報発表時刻の12～24時間後（日本時間で0～12時または12～24時）において、1mm以上の降水がある場合に「降る」と発表し、1mm未満の場合に「降らん」と発表します。</li>
          <li>FRAN予報は毎日午前0時頃、午後0時頃に発表されます。</li>
          <li>選択する地点は、予報対象時間ごと（12時間ごと）に変更することができます。予報対象時間まで（9月1日午前のFRAN予報の場合、8月31日23時59分まで）は変更可能です。予報対象時間になると変更できません。</li>
          <li>FRAN予報の地点はアメダス地点に対応しています。</li>
          <li>FRAN予報の適中・外れ判定は気象庁が提供する「地域気象観測報」の通常報第2報のうち、品質が「正常」または「準正常（やや疑わしい）」の観測値を用いて行います。遅延報や修正報、その他の品質（「非常に疑わしい」など）の観測値は利用しません。</li>
          <li>欠測値の場合、予報によらずFRAN予報適中として扱います。</li>
          <li>選択した地点のアメダスが廃止等になった場合、選択地点は初期地点の東京に設定されます。</li>
          <li>FRAN予報は日本気象株式会社が開発した独自予報です。</li>
        </ul>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
  export default {
    name: 'AboutFran',

    components: {
    },

    data: () => ({
      //
    }),
  }
</script>
