import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

// Amplify読み込み
import { AmplifyPlugin } from 'aws-amplify-vue'
import * as AmplifyModules from 'aws-amplify'

Vue.use(VueRouter)
Vue.use(AmplifyPlugin, AmplifyModules)

let user;

// ユーザー管理
getUser();

function getUser() {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.dispatch('changeUser', data)
      return data;
    }
  }).catch(() => {
    store.dispatch('changeUser', null)
    return null;
  })
}

// vue router
//
// mode : 'hash'
// 最終更新日：2020.09.16（ナビゲーションガード追加）

const routes = [
  // ボトムナビゲーションから
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/bottombar/Home.vue'),
    meta: {
      mobile: true,
      home: true,
    },
  },
  {
    path: '/weather',
    name: 'Weather',
    component: () => import('@/views/bottombar/Weather.vue'),
    meta: {
      mobile: true,
    },
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('@/views/bottombar/Pay.vue'),
    meta: {
      mobile: true,
    },
  },
    {
    path: '/mailsignin/:pageid/:code',
    name: 'MailSignIn',
    component: () => import('@/views/bottombar/MailSignIn.vue'),
    meta: {
      mobile: true,
    },
  },

  // ナビゲーションドロワーから
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/views/navbar/Help.vue'),
    meta: {
      goback: true,
    },
  },
  {
    path: '/info',
    name: 'Info',
    beforeEnter() {
      window.location = "https://lp.franchan.jp/info.html"
    }
  },
  {
    path: '/aboutfran',
    name: 'AboutFran',
    component: () => import('@/views/navbar/AboutFran.vue'),
    meta: {
      goback: true,
    },
  },
  {
    path: '/userinfo',
    name: 'UserInfo',
    component: () => import('@/views/navbar/UserInfo.vue'),
    meta: {
      goback: true,
    },
  },
  {
    path: '/aboutpoint',
    name: 'AboutPoint',
    component: () => import('@/views/navbar/AboutPoint.vue'),
    meta: {
      goback: true,
    },
  },
  {
    path: '/aboutmap',
    name: 'AboutMap',
    component: () => import('@/views/navbar/AboutMap.vue'),
    meta: {
      goback: true,
    },
  },
  {
    path: '/advertise',
    name: 'Advertise',
    beforeEnter() {
      window.location = "https://lp.franchan.jp/inquiry.html"
    }
  },
  {
    path: '/request',
    name: 'Request',
    beforeEnter() {
      window.location = "https://lp.franchan.jp/support.html"
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('@/views/navbar/Company.vue'),
    meta: {
      goback: true,
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    beforeEnter() {
      window.location = "https://lp.franchan.jp/terms.html"
    }
  },
  {
    path: '/privacypolicy',
    name: 'Privacypolicy',
    beforeEnter() {
      window.location = "https://n-kishou.com/corp/policy.html"
    }
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: () => import('@/views/navbar/Withdrawal.vue'),
    meta: {
      goback: true,
    },
  },
  /*
  {
    path: '/updateshopevent',
    name: 'UpdateShopEvent',
    component: () => import('@/views/navbar/UpdateShopEvent.vue'),
    meta: {
      goback: true,
    },
  },
  */
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/bottombar/Home.vue'),
    meta: {
      mobile: true,
    },
  },
  // リダイレクト
  {
    path: '*',
    redirect: '/home',
  },
]

const router = new VueRouter({
  routes,
})

// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser();
    if (!user) {
      return next({
        path: '/'
      })
    }
    return next()
  }
  return next()
})

export default router
