<template>
  <section>
    <base-tittle>Q &amp; A</base-tittle>

     <v-card class="fran-wrap d-flex" outlined tile>
        <v-card-text class="text--primary">
          <ul>
            <li>Q：FRAN予報が外れてもスクラッチができない。<br/>A：スクラッチにはLINEアカウントもしくはGoogleアカウントでのログインが必要です。</li>
            <li>Q：FRANのログインアカウントが分からない。<br/>A：ログインはLINEアカウントもしくはGoogleアカウントでできます。FRAN独自のアカウントはございません。</li>
            <li>Q：受付終了の選択地点を変更したい。<br/>A：既に観測が始まっている予報対象時間については選択地点を変更できません。</li>
            <li>Q：天気予報で雨となっている時間があるのにFRAN予報で「降らん」と表示されている。<br/>A：通常の天気予報とFRAN予報は一致しないことがあります。</li>
          </ul>
        </v-card-text>
      </v-card>
  </section>
</template>

<script>
  export default {
    name: 'Help',

    components: {
    },

    data: () => ({
      //
    }),
  }
</script>
