<template>
  <section>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <v-form
      ref="form"
      v-model="validForm"
      lazy-validation
    >
      <v-card class="d-flex" outlined tile>
        <div class="mx-auto">
          <a :href="imgUrl" target="_blank" rel="noopener noreferrer">
            <v-img :src="img" class="user-event-img" />
          </a>
          <v-card-text class="text-center px-10 grey--text text--darken-2">
            {{ eventContent }}
            <p v-if="!eventExpired">回答期限：{{ endDateStr }}</p>
            <p v-else>回答期限を過ぎました</p>
          </v-card-text>
          <template v-if="!eventExpired && !eventNotYet">
            <template v-if="eventForm==='multiple choice'">
              <v-select v-model="choice" :items="choices" outlined></v-select>
            </template>
            <template v-else>
              <div class="px-10 py-3">
                <v-text-field
                  label="回答（半角数字）"
                  v-model="userAnswer"
                  required
                  :rules="userAnswerRules"
                  ></v-text-field>
              </div>
            </template>
            <div v-if="!linepay" class="px-10 py-3">
              <template v-if="loginTool==='passwordless'">
                <v-text-field
                  label="メールアドレス"
                  v-model="userEmail"
                  disabled
                  ></v-text-field>
              </template>
              <template v-else-if="loginTool==='social'">
                <v-text-field
                  label="メールアドレス"
                  v-model="userEmail"
                  required
                  :rules="userEmailRules"
                  ></v-text-field>
              </template>
              <template v-else>
                <div class="mail-login-btn">
                  応募にはメールアドレスでの
                  <v-btn
                    @click="mailSignIn"
                    color="#a260bf"
                    class="font-weight-regular caption"
                    width="100px"
                    height="29px"
                    depressed
                    dark
                    tile
                    top
                  >
                    <v-icon left dense class="login-google-icon">mdi-email</v-icon>
                  ログイン</v-btn>
                  が必要です。
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <v-card-text v-if="userParticipated" class="text-center px-10 grey--text text--darken-2">
              <p v-if="eventForm==='multiple choice'">「{{ choice }}」で応募されました。</p>
              <p v-else>「{{ userAnswer }}」で応募されました。</p>
              <p v-if="!linepay">登録アドレスは「{{ userEmail }}」です。</p>
            </v-card-text>
          </template>
          <v-card-actions class="ma-2">
            <v-btn
              v-if="!eventExpired && !eventNotYet"
              @click="closeMyself"
              :disabled="this.processing"
              class="font-weight-regular ma-auto"
              color="primary"
              width="130px"
              max-width="130px"
              outlined
              dark
              tile
              top
            >
              キャンセル
            </v-btn>
            <v-btn
              v-if="this.user && !eventExpired && !eventNotYet"
              @click="executeSetEventParticipation"
              :disabled="!this.enableSetEventParticipation"
              class="font-weight-regular ma-auto dia-receive-btn"
              color="primary"
              width="130px"
              max-width="130px"
              depressed
              dark
              tile
              top
            >
              <template v-if="!userParticipated">応募</template>
              <template v-else>応募(変更)</template>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </section>
</template>

<script>
  import axios from 'axios';
  import QueryFranUser from '@/mixins/query-franuser.js';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getFranEventParticipationV2 } from '@/graphql/queries';
  import { setEventParticipation, updateFranUser } from '@/graphql/mutations';
  import dayjs from 'dayjs';
  import 'dayjs/locale/ja';
  dayjs.locale('ja');

  export default {
    name: 'DiaEvent',

    mixins: [QueryFranUser],

    props: ['diaEventToggle', 'id', 'location'],

    data: () => ({
      loginTool: '',
      validForm: false,
      user: null,
      userParticipated: false,
      userAnswer: '',
      userAnswerRules: [
        v => !!v || '回答が入力されていません',
        v => /^[0-9]+$/.test(v) || '半角数字で入力してください',
      ],
      userEmail: '',
      userEmailRules: [
        v => /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/.test(v) || 'メールアドレスの形式で入力してください'
      ],
      processing: false,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
      snackbarTimeout: 2000,
      eventId: '',
      eventLocation: '',
      eventContent: '',
      eventForm: '',
      startDate: '',
      endDate: '',
      deletedAt: '',
      choice: '',
      choices: [],
      linepay: '',
      img: '',
      imgUrl: '',
      eventNotYet: false,
      eventExpired: false,
      endDateStr: ''
    }),
    
    mounted: async function () {
      this.user = await this.$store.state.franUser;
      await this.checkLoginTool();
      await this.updateEvent();
    },
    
    computed: {
      enableSetEventParticipation: function() {
        let result = false;
        if (this.validForm === true && this.processing === false &&
          (this.userAnswer || this.choice) && (this.userEmail || this.linepay)) {
          result = true;
        }
        return result;
      }
    },

    methods: {
      async updateEvent() {
        this.eventId = await this.id;
        this.eventLocation = await this.location;
        await this.loadEventInformation();
        await this.checkDate();
        await this.loadEventParticipation();
      },
      
      checkLoginTool() {
        if (this.user) {
          const id = this.user.id;
          const MailRegExp = /^Email/;
          if (MailRegExp.test(id)) {
            this.loginTool = 'passwordless';
          } else {
            this.loginTool = 'social';
          }
          if (this.user.email) {
            this.userEmail = this.user.email;
          }
        }
      },
      
      async loadEventInformation() {
        const date = new Date();
        const dateStr = String(date.getFullYear()) + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0');
        const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
        const filePath = 'https://d3e48edr1ew123.cloudfront.net/json-' + env + '/user-event/' + this.eventId + '.json?' + dateStr;
        try {
          const response = await axios.get(filePath);
          const data = JSON.parse(JSON.stringify(response.data));
          const eventInformation = data['information'];
          this.eventContent = eventInformation['event_content'];
          this.eventForm = eventInformation['event_form'];
          this.startDate = eventInformation['start_date'];
          this.endDate = eventInformation['end_date'];
          this.deletedAt = eventInformation['deletedAt'];
          this.choices = eventInformation['choices'];
          if (this.choices) {
            this.choice = eventInformation['choices'][0];
          }
          this.linepay = eventInformation['linepay'];
          this.img = eventInformation['img'];
          this.imgUrl = eventInformation['img_url'];
        } catch(e) {
          this.closeMyself();
          console.error(e);
        }
      },
      
      checkDate() {
        const now = new Date();
        const startDate = new Date(this.startDate);
        const endDate = new Date(this.endDate);
        if (now < startDate) {
          this.eventNotYet = true;
        } else {
          this.eventNotYet = false;
        }
        if (now > endDate) {
          this.eventExpired = true;
        } else {
          this.eventExpired = false;
        }
        this.displayEndDate(endDate);
      },
      
      displayEndDate(endDate) {
        const year = endDate.getFullYear();
        const month = endDate.getMonth() + 1;
        const day = endDate.getDate();
        const hour = endDate.getHours();
        const minute = endDate.getMinutes();
        this.endDateStr = String(year) + '年' + String(month) + '月' + String(day) + '日' + String(hour) + '時' + String(minute) + '分';
      },
      
      async loadEventParticipation() {
        if (this.user) {
          try {
            const input = { user_id: this.user.id, event_id: this.eventId };
            const result = await API.graphql(graphqlOperation(getFranEventParticipationV2, input));
            const resultData = result.data.getFranEventParticipationV2;
            if (resultData) {
              if (this.eventForm === 'multiple choice') {
                this.choice = this.choices[resultData.user_answer];
              } else {
                this.userAnswer = resultData.user_answer;
              }
              this.userParticipated = true;
            } else {
              this.userParticipated = false;
            }
          } catch(e) {
            console.error(e);
          }
        }
      },
      
      closeMyself() {
        this.$emit('closeMe', false);
      },
      
      async setUserEmail() {
        if (this.loginTool === 'social') {
          const input = {
            id: this.user.id,
            email: this.userEmail
          };
          await API.graphql(graphqlOperation(updateFranUser, { input: input }));
        }
      },
      
      async executeSetEventParticipation() {
        this.processing = true;
        this.$store.commit('progressCircleOn');
        if (this.eventForm === 'multiple choice') {
          this.userAnswer = this.choices.indexOf(this.choice);
        }
        if (!this.linepay) {
          try {
            await this.setUserEmail();
          } catch (e) {
            this.$store.commit('progressCircleOff');
            this.snackbarText = 'メールアドレスの値が不正です';
            this.showErrorSnackBar();
            this.processing = false;
            return;
          }
        }
        try {
          const input = {
            user_id: this.user.id,
            event_id: this.eventId,
            user_answer: this.userAnswer,
            end_date: this.endDate,
            deletedAt: this.deletedAt
          };
          console.log(`executeSetEventParticipation : ${JSON.stringify(input)}`);
          const gqlRes = await API.graphql(graphqlOperation(setEventParticipation, {input: input}));
          this.$store.commit('progressCircleOff');
          if (gqlRes && gqlRes.data && gqlRes.data.SetEventParticipation) {
            const result = gqlRes.data.SetEventParticipation;
            const answer = result['user_answer'];
            if (answer !== -999) {
              this.snackbarText = '応募しました！';
              this.showSnackbar = true;
              this.snackbarColor = 'primary';
              setTimeout(function(){ 
                this.closeMyself();
              }.bind(this), this.snackbarTimeout);
            } else {
              this.snackbarText = '回答に失敗しました';
              this.showErrorSnackBar();
            }
          } else {
            this.snackbarText = '回答に失敗しました';
            this.showErrorSnackBar();
          }
          this.processing = false;
        } catch (error) {
          console.error(`Failed to execute SetEventParticipation: ${JSON.stringify(error)}`);
          this.$store.commit('progressCircleOff');
          this.snackbarText = '回答に失敗しました';
          this.showErrorSnackBar();
          this.processing = false;
        }
      },
      
      showErrorSnackBar() {
        this.showSnackbar = true;
        this.snackbarColor = 'error';
        setTimeout(function(){this.closeMyself();}.bind(this), this.snackbarTimeout);
      },
      
      mailSignIn() {
        sessionStorage.setItem('center', this.eventLocation);
        sessionStorage.setItem('eventId', this.eventId);
        this.$router.push({path: '/mailsignin/signin/0'},() => {});
      }
    },
  };
</script>
