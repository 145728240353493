<template>
  <section>
    <base-tittle>運営会社</base-tittle>

    <v-card class="fran-wrap d-flex" outlined tile>
      <div class="mx-auto">
        <span>FRANサービスは日本気象株式会社が運営しています。</span>
      </div>
    </v-card>
  </section>
</template>

<script>
  export default {
    name: 'Company',

    components: {
    },

    data: () => ({
      //
    }),
  }
</script>
