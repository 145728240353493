<template>
  <section>
    <base-tittle>ショウキンについて</base-tittle>

   <v-card class="fran-wrap d-flex" outlined tile>
      <v-card-text class="text--primary">
        <ul>
          <li>選択した地点のFRAN予報が外れた場合、別途記載するショウキン人数を上限にショウキンをお支払いします。</li>
          <li>FRAN予報が外れた人数がショウキン人数を上回る場合、ショウキンの獲得有無は抽選によって決定します。</li>
          <li>抽選ではスクラッチを削ります。あたりが出ればショウキンを受け取れます。</li>
          <li>ショウキン獲得のチャンスは12時間ごとにあります。</li>
          <li>地点を選択するには、FRAN予報表示中（9月1日午前のFRAN予報の場合、8月31日12時～23時59分）に少なくとも1回はFRAN予報を表示する必要があります。1回もアクセスしなかった場合、その予報対象時間については未選択となります。</li>
          <li>ショウキンを獲得するには、LINEアカウントもしくはGoogleアカウントでログインした状態で地点を選択する必要があります。</li>
          <li>ショウキンを獲得するには、予報対象時間が過ぎて12時間以内（9月1日午前のFRAN予報の場合、9月1日23時59分まで）にスクラッチを削る必要があります。</li>
          <li>ショウキンの受取り期限は予報対象時間の6日後です。期限を過ぎると受取りの権利を失います。</li>
          <li>ショウキンの受取りができるのは該当ユーザのみです。受取り権利の譲渡、売買等はできません。</li>
          <li>1人につき1アカウントまでの利用としてください。1人で複数アカウントを使われて本サービスを利用されている場合、ショウキンの受取りはできません。</li>
          <li>サービス運営により不正行為があると判断された場合、ショウキンの受取りはできません。</li>
          <li>ショウキンはLINE Payでのみ受取りができます。他の方法での受取りはできません。</li>
        </ul>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
  export default {
    name: 'AboutPoint',

    components: {
    },

    data: () => ({
      //
    }),
  }
</script>
