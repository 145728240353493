<template>
  <section>
    <v-card class="fran-wrap fran-forecast d-flex mb-n15" :height="windowHeight" outlined tile>
      <v-container class="pa-0">
        
        <div class="ad-banner-floating-home"><ad-banner /></div>
       
        <v-dialog v-model="showEventInformation" width="unset" overlay-opacity="0.8" eager>
          <v-btn icon dark @click.stop="showEventInformation=false" class="event-information-window-close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-img :src="eventInfo.img" :srcset="eventInfo.srcSet" height="100%" eager/>
        </v-dialog>
       
        <v-snackbar v-model="readingForecastSnackbar" :timeout="readingForecastTimeout">
          <div class="text-center">FRAN予報を読みこんでいます・・・</div>
        </v-snackbar>
        <div class="footer-legend">
          <div class="radar-date">
            <div id="radar-date-type">
              <template v-if="!sliderValue || sliderValue=='現在'">
                降水強度
              </template>
              <template v-else>
                1時間降水量（予想）
              </template>
            </div>
            <div id="radar-date-divider"></div>
              <template v-if="!sliderValue || sliderValue=='現在'">
                <div>{{ dtime }}</div>
              </template>
              <template v-else>
                <div>～{{ dtime }}</div>
              </template>
          </div>
          <div>
            <img src="https://cdn1.n-kishou.co.jp/image/charge/hires/image/rain-legend.png" class="mb-n1">
          </div>
        </div>
        <v-container class="slider-base">
          <v-row class="ma-0">
            <v-col cols="1" class="pa-0">
              <template v-if="!sliderPlay">
                <div class="slider-play-button" @click="playSlider">
                  <v-icon>mdi-play</v-icon>
                </div>
              </template>
              <template v-else>
                <div class="slider-play-button" @click="pauseSlider">
                  <v-icon>mdi-pause</v-icon>
                </div>
              </template>
            </v-col>
            <v-col cols="10" class="pa-0">
              <div class="slider">
                <vue-slider v-model="sliderValue" :data="sliderData" :marks="true" :tooltip="'none'" @change="changeRainTile()"></vue-slider>
              </div>
            </v-col>
            <v-col cols="1" class="pa-0">
              <div class="slider-unit">時間後</div>
            </v-col>
          </v-row>
        </v-container>
        <div class="fran-wrap-center">
          <div class="fran-time-panel" v-if="showFranPanel">
            {{ franForecastPeriodStr }}の予報
          </div>
        </div>
       
        <div class="reload-button" @click="reloadPage">
          <v-icon>mdi-reload</v-icon>
        </div>
        <div class="map-button-wrap">
          <template v-if="!showFranPanel">
            <div class="map-button radar-mode-button radar-mode-button-on" @click="changeShowFranPanel(true)">
              <img :src="require('@/assets/img/icon_radar-mode-on.svg')">
            </div>
          </template>
          <template v-else>
            <div class="map-button radar-mode-button radar-mode-button-off" @click="changeShowFranPanel(false)">
              <img :src="require('@/assets/img/icon_radar-mode-off.svg')">
            </div>
          </template>
          <div class="map-button current-position-button" @click="getCurrentPosition">
            <img :src="require('@/assets/img/icon_current-position.svg')">
          </div>          
        </div>
        
        <v-dialog v-model="showRetryToLoginModal" width="500">
          <v-card>
            <v-card-title class="text-body-2 grey--text text--darken-2">LINEログインボタンが残っている場合、もう一度ボタンをタップしてください。</v-card-title>
            <v-card-actions class="ma-0 pa-1">
              <v-spacer></v-spacer>
              <v-btn @click="closeRetryToLoginModal" color="primary" text>閉じる</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showResetToTokyoModal" width="500">
          <v-card>
            <v-card-title class="text-body-2 grey--text text--darken-2">前回選択した地点は廃止もしくは移設されたため、選択地点を東京に変更します。</v-card-title>
            <v-card-actions class="ma-0 pa-1">
              <v-spacer></v-spacer>
              <v-btn @click="closeResetToTokyoModal" color="primary" text>閉じる</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showFranErrorModal" width="500">
          <v-card>
            <v-card-title class="text-body-2 grey--text text--darken-2">ただいま最新のFRAN予報が表示できない不具合が起きています。地点を選択することはできます。</v-card-title>
            <v-card-actions class="ma-0 pa-1">
              <v-spacer></v-spacer>
              <v-btn @click="closeFranErrorModal" color="primary" text>閉じる</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showShokinModal" persistent class="shokin-modal" width="500">
          <v-card>
          <div class="shokin-modal">
            <div class="shokin-title">
              <img :src="require('@/assets/img/scratch_title.svg')" alt="ショウキン獲得チャンス！">
            </div>
            <div class="shokin-scratch-wrap">
              <scratch-card class="shokin-scratch-card" imageUrl="scratch_image_mask.png" @complete="completeScratch"
                :key="renderCount" :cardWidth="220" :cardHeight="220" :finishPercent="finishPercent">
                <template v-if="getCompensation">
                  <template>
                    <img :src="require('@/assets/img/scratch_image_atari.png')" width="220" height="220" alt="あたり">
                  </template>
                </template>
                <template v-else-if="user">
                  <img :src="require('@/assets/img/scratch_image_hazure.png')" width="220" height="220" alt="はずれ">
                </template>
                <template v-else>
                  <img :src="require('@/assets/img/scratch_image_sample.png')" width="220" height="220" alt="あたりサンプル">
                </template>
              </scratch-card>              
            </div>
            <template v-if="scratchFlag">
              <div class="shokin-text">
                <template>
                  <p>タップでスクラッチを削ってね</p>
                </template>
              </div>
            </template>
            <template v-if="!user">
              <div class="shokin-text">
                <p>ログインして予報が外れたら、<br>実際に抽選に参加できます！</p>
              </div>
            </template>
            <template v-if="receiveFlag">
              <div class="fran-get-btn pt-6 pb-6">
                <a @click.prevent="diaToggle=true">ショウキンを受け取る</a>
              </div>
            </template>
            <div class="shokin-close-btn">
              <template v-if="!user || showCloseShokinModal">
                <v-btn @click="closeShokinModal">閉じる</v-btn>
              </template>
            </div>
          </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="diaToggle" max-width="500">
          <dia-compensation
            v-bind:diaToggle="diaToggle"
            v-bind:compensationHistory="selectedCompensationData"
            v-on:closeMe="closeCompensationDialog($event)"
          />
        </v-dialog>
        <v-dialog v-model="diaEventToggle" max-width="500">
          <dia-event
            v-bind:diaEventToggle="diaEventToggle"
            v-bind:id="eventId"
            v-bind:location="eventLocation"
            v-on:closeMe="closeEventDialog($event)"
            ref="event"
          />
        </v-dialog>
        <v-dialog v-model="diaNftToggle" max-width="500">
          <dia-nft
            v-bind:diaNftToggle="diaNftToggle"
            v-bind:nft_event_id="selectedNftEventId"
            v-bind:amedas_id="selectedNftAmedasId"
            v-on:closeMe="closeNftDialog($event)"
            ref="nft"
          />
        </v-dialog>
        <!-- 店舗ダイアログ
        <v-dialog v-model="diaShopListToggle" max-width="500">
          <dia-shop-list
            v-bind:diaShopListToggle="diaShopListToggle"
            v-bind:id="selectedShopAmedasId"
            v-on:shopDialogInfo="openShopDialog"
            v-on:closeMe="closeShopListDialog($event)"
            ref="shopList"
          />
        </v-dialog>
        <v-dialog v-model="diaShopToggle" max-width="500">
          <dia-shop
            v-bind:diaShopToggle="diaShopToggle"
            v-bind:amedas_id="selectedShopAmedasId"
            v-bind:shop_id="selectedShopId"
            v-bind:event_id="selectedShopEventId"
            v-on:shopListDialogInfo="backToShopList"
            v-on:closeMe="closeShopDialog($event)"
            ref="shop"
          />
        </v-dialog>
        -->
        <div class="fran-flex"></div>
        <v-img height="100%" width="100%">
          <l-map :zoom="zoom" :minZoom="5" :maxZoom="14" :center="mapCenter" :max-bounds="maxBounds"
            :options="{attributionControl: false, zoomControl: false}" @update:zoom="zoomUpdated" ref="map">
            <l-tile-layer :options="{ maxZoom: 8 }" :url="baseLayerAsia.url"></l-tile-layer>
            <l-tile-layer :url="baseLayer.url"></l-tile-layer>
            <l-tile-layer v-for="(rainLayer, index) in rainLayers" :key="index" :url="rainLayer.url" :opacity="rainLayer.opacity"></l-tile-layer>
            <l-tile-layer :url="lineLayer.url" ></l-tile-layer>
            <l-control-zoom position="bottomright"></l-control-zoom>
            <template v-if="showFranPanel">
              <v-markercluster :options="clusterOptions">
                <l-marker v-for="(afterFranForecast, index) in afterFranForecastList" :zIndexOffset="10"
                  :key="index" :lat-lng="[afterFranForecast.latitude, afterFranForecast.longitude]"
                  @click="changeFranPoint(afterFranForecast.AmedasId)">
                  <l-icon :icon-anchor="forecastIconAnchor">
                    <table class="forecast-map-panel">
                      <tr>
                        <th class="forecast-map-panel-title">{{ afterFranForecast.pointName }}</th>
                      </tr>
                      <tr>
                        <td class="forecast-map-panel-fran">{{ afterFranForecast.franForecast }}</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="d-flex">
                            <div class="forecast-map-panel-img">
                              <img :src="require(`@/assets/wicon/${afterFranForecast.franWeather}`)">
                            </div>
                            <div class="forecast-map-panel-tmp">
                              <span class="fran-weather-red">{{ afterFranForecast.franMaxTemperature }}<span>℃</span></span>
                              <span class="fran-weather-blue">{{ afterFranForecast.franMinTemperature }}<span>℃</span></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </l-icon>
                </l-marker>
                <l-marker v-for="(nftAmedas, index) in nftAmedasList" :zIndexOffset="10"
                  :key="`nft-${index}`" :lat-lng="nftAmedas.location"
                  @click="openNftDialog(nftAmedas)">
                  <l-icon :icon-anchor="nftIconAnchor">
                    <v-icon>mdi-currency-jpy</v-icon>
                  </l-icon>
                </l-marker>
                <!--
                <l-marker v-for="(shopAmedas, index) in shopAmedasList" :zIndexOffset="10"
                  :key="`shopevent-${index}`" :lat-lng="shopAmedas.location"
                  @click="openShopEventListDialog(shopAmedas.amedas_id)">
                  <l-icon :icon-anchor="shopIconAnchor">
                    <v-icon>mdi-currency-jpy</v-icon>
                  </l-icon>
                </l-marker>
                -->
              </v-markercluster>
              
              <template v-if="showUserEventIcon">
                <l-marker v-for="(info, index) in userEventInfo" :key="`userevent-${index}`" :zIndexOffset="-60" :lat-lng="info.location" @click="showEventDialog(info)">
                  <l-icon>
                    <div class="ad-map-icon-img" :style="{ right: info.iconright + 'px', top: info.icontop + 'px'}">
                      <img :src="info.icon" :srcset="info.iconSet">
                    </div>
                  </l-icon>
                </l-marker>
              </template>
            </template>
          </l-map>
        </v-img>
      </v-container>
    </v-card>
  </section>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer, LMarker, LIcon, LControlZoom } from 'vue2-leaflet';
import { latLngBounds } from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import axios from 'axios';
import ScratchCard from 'vue-scratchcard';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import DiaClose from '@/mixins/base/dia-close.js';
import { API, graphqlOperation } from 'aws-amplify';
import { getFranRainForecastV2, getFranCompensationV2, getFranSelectedPointsV2 } from '@/graphql/queries';
import { updateCompensation, createSelectedPoints } from '@/graphql/mutations';
import AreaSelect from '@/mixins/area-select.js';
import AdFunc from '@/mixins/ad-func.js';

import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'];

export default {
  mixins: [DiaClose, AreaSelect, AdFunc],
  
  components: {
    DiaCompensation: () => import('@/components/prize/DiaCompensation'),
    DiaEvent: () => import('@/components/prize/DiaEvent'),
    DiaNft: () => import('@/components/shop/DiaNft'),
    //DiaShop: () => import('@/components/shop/DiaShop'),
    //DiaShopList: () => import('@/components/shop/DiaShopList'),
    //LoginButtonBox: () => import('@/components/base/LoginButtonBox.vue'),
    AdBanner: () => import('@/components/base/AdBanner.vue'),
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlZoom,
    'v-markercluster': Vue2LeafletMarkerCluster,
    ScratchCard,
    VueSlider
  },
  
  data () {
    return {
      user: '',
      dtime: '',
      zoom: 11,
      sliderValue: '現在',
      nextSliderValue: '',
      sliderData: ['現在', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      sliderPlay: false,
      playingSlider: '',
      forecastCenter: [35.692, 139.750],
      baseLayerAsia: {
        url: 'https://maptile1.n-kishou.co.jp/v2/asia/base{r}/{z}/{x}/{y}.png'
      },
      baseLayer: {
        url: 'https://maptile1.n-kishou.co.jp/v2/base{r}/{z}/{x}/{y}.png'
      },
      rainLayers: [
        {
          url: '',
          opacity: 0.6
        },
        {
          url: '',
          opacity: 0
        },
        {
          url: '',
          opacity: 0
        },
        {
          url: '',
          opacity: 0
        }
      ],
      lineLayer: {
        url: 'https://d3342ng3auo02n.cloudfront.net/v2/label{r}/{z}/{x}/{y}.png'
      },
      positionIcon: {
        url: 'https://cdn1.n-kishou.co.jp/image/charge/hires/image/position.png'
      },
      maxBounds: latLngBounds([
        [0.0, 80.0],
        [55.6, 180.0]
      ]),
      timestampFranForecast: '',
      timestampFranNow: '',
      timestampFranPast: '',
      franForecastPeriodStr: '',
      showFranPanel: false,
      afterFranForecastList: [],
      selectedPointIsNotCreated: false,
      
      // 今回選択する地点（デフォルトは前回選択地点、無ければ東京）
      selectingPoint: {
        AmedasId: '',
        pointName: '',
        franForecast: '',
        franWeather: 'logo.png',
        franMinTemperature: '',
        franMaxTemperature: ''
      },

      forecastIconAnchor: [42.5, 85],
      selectedIconAnchor: [42.5, 85],
      nftIconAnchor: [-45, 95],
      shopIconAnchor: [-45, 95],
      clusterOptions: {
        maxClusterRadius: window.innerWidth / 4
      },
      scratchFlag: false,
      receiveFlag: false,
      getCompensation: false,
      readingForecastSnackbar: false,
      readingForecastTimeout: 2000,
      showLoginButton: false,
      showRetryToLoginModal: false,
      showResetToTokyoModal: false,
      showFranErrorModal: false,
      showShokinModal: false,
      showCloseShokinModal: false,
      showInformation: false,
      showEventInformation: false,
      showUserEventIcon: false,
      userEventInfo: [],
      shopIcon: '',
      shopInfo: [],
      eventInfo: {},
      showPauseSliderInformation: false,
      selectedCompensationData: null,
      renderCount: 0,
      finishPercent: 50,
      navbarHeight: 53,
      windowHeight: window.innerHeight - 53,
      windowWidth: window.innerWidth,
      infoItems: [
        {src: require('@/assets/onboard/onboard_1.png')},
        {src: require('@/assets/onboard/onboard_2.png')},
        {src: require('@/assets/onboard/onboard_3.png')},
        {src: require('@/assets/onboard/onboard_4.png')},
        {src: require('@/assets/onboard/onboard_5.png')},
        {src: require('@/assets/onboard/onboard_6.png')},
        {src: require('@/assets/onboard/onboard_7.png')},
        {src: require('@/assets/onboard/onboard_8.png')},
        {src: require('@/assets/onboard/onboard_9.png')},
        {src: require('@/assets/onboard/onboard_10.png')}
      ],
      pauseSliderInfo: {
        src: require('@/assets/img/pause_slider.png')
      },
      eventId: '',
      eventLocation: '',
      selectedShopAmedasId: '',
      selectedShopId: '',
      selectedShopEventId: '',
      selectedNftAmedasId: '',
      selectedNftEventId: ''
    };
  },
  
  computed: {
    stateFranUser: function() {
      return this.$store.state.franUser;
    },
    stateAdHeight: function() {
      return this.$store.state.adHeight;
    },
    mapCenter: function() {
      let result;
      if (this.$route.query.lat && this.$route.query.lon) {
        result = [this.$route.query.lat, this.$route.query.lon];
      } else if (sessionStorage.center) {
        result = sessionStorage.center.split(',');
      } else if (this.forecastCenter) {
        result = this.forecastCenter;
      } else {
        result = [35.692, 139.750];
      }
      return result;
    }
  },
    
  watch: {
    async stateFranUser (newValue) {
      if (newValue) {
        this.$store.commit('progressCircleOn');
        this.user = await this.$store.state.franUser;
        
        await this.getPeriod();
        await this.loadUserPoint();
        await this.setFranForecast();
        await this.createSelectedPoints();
        await this.loadScratchFlag();
        await this.loadBadgeNum();
        this.$store.commit('progressCircleOff');
      }
    },
    async stateAdHeight () {
      if(window.innerWidth < 960){
      this.windowHeight = window.innerHeight - this.navbarHeight - this.$store.state.adHeight;
      }
    }
  },

  mounted: async function () {
    const mobile = window.innerWidth < 960;
    if(window.innerWidth < 960){
    this.windowHeight = window.innerHeight - this.navbarHeight - this.$store.state.adHeight;
    }
    
    if (sessionStorage.zoom) {
      this.zoom = parseInt(sessionStorage.zoom, 10);
    } else {
      this.zoom = (mobile) ? 11 : 12;
    }
    this.clusterOptions.disableClusteringAtZoom = (mobile) ? 11 : 12;
    
    this.$store.commit('progressCircleOn');
    this.user = await this.$store.state.franUser;
    
    await this.getPeriod();
    if (this.user) {
      await this.loadUserPoint();
      await this.setFranForecast();
      await this.createSelectedPoints();
    }
    await this.getTile();
    await this.getNextTile(1);
    if (this.user) {
      await this.loadScratchFlag();
    }
    await this.loadBadgeNum();
    await this.loadAfterFranForecast();
    await this.loadUserEventInformation();
    await this.loadNftAmedasList();
    //await this.loadShopAmedasList();
    await this.showFirstInformation();
    
    if (this.$route.query.eventId) {
      this.eventId = this.$route.query.eventId;
      if(this.$route.query.lat && this.$route.query.lon) {
        this.eventLocation = [this.$route.query.lat, this.$route.query.lon];
      }
      this.diaEventToggle = true;
    }
    if (this.$route.query.amedasId && this.$route.query.nftEventId) {
      this.selectedNftAmedasId = this.$route.query.amedasId;
      this.selectedNftEventId = this.$route.query.nftEventId;
      this.$nextTick(function() {
        this.diaNftToggle = true;
      });
    }
    /*
    if (this.$route.query.amedasId && this.$route.query.shopId && this.$route.query.shopEventId) {
      this.selectedShopAmedasId = this.$route.query.amedasId;
      this.selectedShopId = this.$route.query.shopId;
      this.selectedShopEventId = this.$route.query.shopEventId;
      this.$nextTick(function() {
        this.diaShopToggle = true;
      });
    }
    */
    
    // Safariでの表示不具合に対応 cf:https://github.com/vue-leaflet/Vue2Leaflet/issues/96
    setTimeout(function() {
      window.dispatchEvent(new Event('resize'));
    }, 250);
    
    window.addEventListener("resize", this.handleResize);
    this.$store.commit('progressCircleOff');
    
    let vm = this;
    
    // 読み込み中スナックバー表示とFRAN予報表示をずらす
    setTimeout(function() {
      if(sessionStorage.showFranPanel) {
        if(sessionStorage.showFranPanel == 'true') {
          vm.showFranPanel = true;
        } else {
          vm.showFranPanel = false;
        }
      } else {
        vm.showFranPanel = true;
      }
    }, 500);
    
    // LINEの自動ログインで1度押してもログインできない問題に対応
    if (localStorage.LINELogin) {
      setTimeout(function() {
        if (localStorage.LINELogin && !vm.user) {
          vm.showRetryToLoginModal = true;
        } else {
          localStorage.removeItem('LINELogin')
        }
      }, 3000);
    }  
  },
  
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  
  methods: {
    zoomUpdated(zoom) {
      if (zoom < 7) {
        this.showUserEventIcon = false;
      } else {
        this.showUserEventIcon = true;
      }
    },
    
    async getTile() {
      try {
        if (!this.sliderValue || this.sliderValue == '現在') {
          const res = await axios.get('https://map.n-kishou.co.jp/rain/tile.json');
          const tile_list = res.data.tiles;
          for (let i = 0; i < tile_list.length; i++) {
            if (tile_list[i]['type'] == 'now'){
              this.$set(this.rainLayers[0], 'url', tile_list[i]['url']);
              this.$set(this.rainLayers[0], 'opacity', 0.6);
              this.dtime = this.formatRadarDayStr(tile_list[i]['time']*1000);
            }
          }
        } else if (this.sliderValue < 7) {
          const res = await axios.get('https://kckd15map.franchan.jp/kckd/tile.json');
          const tile_list = res.data.tiles;
          const index = this.sliderValue - 1;
          this.$set(this.rainLayers[this.sliderValue%4], 'url', tile_list[index]['url']);
          this.$set(this.rainLayers[this.sliderValue%4], 'opacity', 0.6);
          this.dtime = this.formatRadarDayStr(tile_list[index]['time']*1000);
        } else {
          const res = await axios.get('https://kckd15map.franchan.jp/kckd15/tile.json');
          const tile_list = res.data.tiles;
          const index = this.sliderValue - 7;
          this.$set(this.rainLayers[this.sliderValue%4], 'url', tile_list[index]['url']);
          this.$set(this.rainLayers[this.sliderValue%4], 'opacity', 0.6);
          this.dtime = this.formatRadarDayStr(tile_list[index]['time']*1000);
        }
      } catch(e) {
        console.error(e);
      }
    },
    
    async getNextTile(nextValue) {
      try {
        if (nextValue == 0) {
          const res = await axios.get('https://map.n-kishou.co.jp/rain/tile.json');
          const tile_list = res.data.tiles;
          for (let i = 0; i < tile_list.length; i++) {
            if (tile_list[i]['type'] == 'now'){
              this.$set(this.rainLayers[0], 'opacity', 0);
              this.$set(this.rainLayers[0], 'url', tile_list[i]['url']);
              this.nextDtime = this.formatRadarDayStr(tile_list[i]['time']*1000);
            }
          }
        } else if (nextValue < 7) {
          const res = await axios.get('https://kckd15map.franchan.jp/kckd/tile.json');
          const tile_list = res.data.tiles;
          const index = nextValue - 1;
          this.$set(this.rainLayers[nextValue%4], 'opacity', 0);
          this.$set(this.rainLayers[nextValue%4], 'url', tile_list[index]['url']);
          this.nextDtime = this.formatRadarDayStr(tile_list[index]['time']*1000);
        } else {
          const res = await axios.get('https://kckd15map.franchan.jp/kckd15/tile.json');
          const tile_list = res.data.tiles;
          const index = nextValue - 7;
          this.$set(this.rainLayers[nextValue%4], 'opacity', 0);
          this.$set(this.rainLayers[nextValue%4], 'url', tile_list[index]['url']);
          this.nextDtime = this.formatRadarDayStr(tile_list[index]['time']*1000);
        }
        this.nextSliderValue = nextValue;
      } catch(e) {
        console.error(e);
      }
    },
    
    async loadScratchFlag() {
      try {
        const input = { id: this.user.id, forecast_date: this.timestampFranPast };
        const result = await API.graphql(graphqlOperation(getFranCompensationV2, input));
        const resultData = result.data.getFranCompensationV2;
        if (resultData) {
          if (resultData.compensation_flag == -1) {
            this.scratchFlag = true;
            this.getCompensation = true;
          } else if (resultData.compensation_flag == -2) {
            this.scratchFlag = true;
          }
        }
      } catch(e) {
        console.error(e);
      }
    },
    
    async loadUserPoint() {
      try {
        const forecastInput = { id: this.user.id, forecast_date: this.timestampFranForecast };
        const forecastResult = await API.graphql(graphqlOperation(getFranSelectedPointsV2, forecastInput));
        const forecastResultData = forecastResult.data.getFranSelectedPointsV2;
        if (forecastResultData) {
          this.$set(this.selectingPoint, 'AmedasId', forecastResultData.selected_amedas_id);
        } else {
          this.$set(this.selectingPoint, 'AmedasId', this.user.forecast_amedas_id);
          if (!this.selectingPoint.AmedasId) {
            this.$set(this.selectingPoint, 'AmedasId', '44132');
            this.$set(this.selectingPoint, 'pointName', '東京');
          }
          this.selectedPointIsNotCreated = true;
        }
      } catch(e) {
        console.error(e);
      }
    },
    
    getPeriod() {
      let dObj = new Date();
      const monthNow = dObj.getMonth() + 1;
      const dayNow = dObj.getDate();
      const hourNow = dObj.getHours();
      const weekNow = dayOfWeekStr[dObj.getDay()];
      
      const timestampNow = Math.floor(dObj.getTime() / 1000) + 32400; // getTimeはミリ秒なので1000で割る 日本時間での経過時間(≠unixtime)
      this.timestampFranForecast = Math.floor(timestampNow / 43200) * 43200 - 32400; // 現在時刻より前で直近の0時or12時のunixtime
      this.timestampFranNow = this.timestampFranForecast - 43200; // さらに1つ前の0時or12時のunixtime
      this.timestampFranPast = this.timestampFranNow - 43200; // さらに1つ前のunixtime
      
      if (hourNow < 12) {
        this.franForecastPeriodStr = monthNow + '/' + dayNow + '(' + weekNow + ')午後';
      } else {
        dObj.setDate(dayNow + 1);
        const monthTomorrow = dObj.getMonth() + 1;
        const dayTomorrow = dObj.getDate();
        const weekTomorrow = dayOfWeekStr[dObj.getDay()];
        this.franForecastPeriodStr = monthTomorrow + '/' + dayTomorrow + '(' + weekTomorrow + ')午前';
      }
    },
    
    async loadAfterFranForecast() {
      try {
        this.afterFranForecastList = JSON.parse(localStorage.getItem('afterFranForecastList'));
      } catch(e) {
        console.error(e);
      }
    },
    
    async setFranForecast() {
      await this.getFranForecast(this.selectingPoint, this.timestampFranForecast);
      if (!this.selectingPoint.franForecast) {
        const selectingAmedasId = this.selectingPoint.AmedasId;
        const selectingCity = this.findCity(selectingAmedasId);
        let selectingPointName;
        if(selectingCity) {
          selectingPointName = this.findCity(selectingAmedasId).pointName;
        }
        this.$set(this.selectingPoint, 'AmedasId', '44132');
        this.$set(this.selectingPoint, 'pointName', '東京');
        await this.getFranForecast(this.selectingPoint, this.timestampFranForecast);
        // 選択アメダスIDでFRAN予報を取得できず、東京でFRAN予報を取得できた場合は選択アメダスIDが消滅したと判断する
        if (this.selectingPoint.franForecast) {
          this.selectedPointIsNotCreated = true;
          this.showResetToTokyoModal = true;
        } else {
          // 東京でFRAN予報を取得できた場合はFRAN予報取得不具合と判断して選択地点を東京から元に戻す
          this.$set(this.selectingPoint, 'AmedasId', selectingAmedasId);
          this.$set(this.selectingPoint, 'pointName', selectingPointName);
          this.showFranErrorModal = true;
        }
      }
      const forecastCity = this.findCity(this.selectingPoint.AmedasId);
      if(forecastCity) {
        this.forecastCenter = [forecastCity.latitude, forecastCity.longitude];
      }
    },
    
    async getFranForecast(point, forecast_date) {
      try {
        const result = await API.graphql({
          query: getFranRainForecastV2,
          authMode: 'API_KEY',
          variables: {forecast_amedas_id: point.AmedasId, forecast_date: forecast_date}
        });
        const resultData = result.data.getFranRainForecastV2;
        if (resultData) {
          if (resultData.one_mm_rain) {
            this.$set(point, 'franForecast', '降らん');
          } else {
            this.$set(point, 'franForecast', '降る');
          }
          this.$set(point, 'franWeather', resultData.weather_id + '.png');
          this.$set(point, 'franMaxTemperature', resultData.max_temp);
          this.$set(point, 'franMinTemperature', resultData.min_temp);
        }
        this.$set(point, 'pointName', this.findCity(point.AmedasId).pointName);
      } catch(error) {
        console.error(error);
        console.error(`Failed to getFranRainForecast: ${JSON.stringify(error)}`);
      }
    },
    
    async createSelectedPoints() {
      if (this.selectedPointIsNotCreated) {
        const deletedAt = this.timestampFranForecast + 259200; // 余裕を持って3日後に削除する
        try {
          const input = {
            id: this.user.id,
            forecast_date: this.timestampFranForecast,
            selected_amedas_id: this.selectingPoint.AmedasId,
            deletedAt: deletedAt
          };
          const gqlRes = await API.graphql(graphqlOperation(createSelectedPoints, { input: input }));
          if (gqlRes && gqlRes.data && gqlRes.data.CreateSelectedPoints) {
            const result = gqlRes.data.CreateSelectedPoints;
            if (result['selected_amedas_id'] == '-99') { // 更新可能時刻を過ぎていた場合
              this.reloadPage();
            }
          }
        } catch(e) {
          console.error(e);
        }
      }
    },
    
    async changeFranPoint(AmedasId) {
      if (this.user) {
        this.$store.commit('progressCircleOn');
        const deletedAt = this.timestampFranForecast + 259200; // 余裕を持って3日後に削除する
        try {
          const input = {
            id: this.user.id,
            forecast_date: this.timestampFranForecast,
            selected_amedas_id: AmedasId,
            deletedAt: deletedAt
          };
          const gqlRes = await API.graphql(graphqlOperation(createSelectedPoints, { input: input }));
          if (gqlRes && gqlRes.data && gqlRes.data.CreateSelectedPoints) {
            const result = gqlRes.data.CreateSelectedPoints;
            if (result['selected_amedas_id'] == '-99') { // 更新可能時刻を過ぎていた場合
              this.$store.commit('progressCircleOff');
              this.reloadPage();
              return;
            }
            else if (result['selected_amedas_id'] == '-999') { // DB更新に失敗した場合
              this.$store.commit('progressCircleOff');
              console.log('failed to createSelectedPoints');
              return;
            }
          }
        } catch(e) {
          this.$store.commit('progressCircleOff');
          console.error(e);
        }
        this.$set(this.selectingPoint, 'AmedasId', AmedasId);
        await this.getFranForecast(this.selectingPoint, this.timestampFranForecast);
        const forecastCity = this.findCity(AmedasId);
        this.forecastCenter = [forecastCity.latitude, forecastCity.longitude];
        this.$store.commit('progressCircleOff');
      }
    },
    
    changeShowFranPanel(showFranPanel) {
      if(showFranPanel) {
        this.readingForecastSnackbar = true;
        this.pauseSlider();
        let vm = this;
        // 読み込み中スナックバー表示とFRAN予報表示をずらす
        setTimeout(function() {
          vm.showFranPanel = showFranPanel;
        }, 500);
      } else {
        this.showFranPanel = showFranPanel;
      }
      sessionStorage.setItem('showFranPanel', showFranPanel);
    },
    
    formatRadarDayStr(daystr) {
      const dObj = new Date(daystr);
      const hour = dObj.getHours();
      const minute =('0' + dObj.getMinutes()).slice(-2);
      return hour + ':' + minute;
    },
    
    async changeRainTile() {
      let beforeValue, nowValue, nextValue;
      if (!this.sliderValue || this.sliderValue == '現在') {
        beforeValue = 15;
        nowValue = 0;
        nextValue = 1;
      } else if (this.sliderValue < 15) {
        beforeValue = this.sliderValue - 1;
        nowValue = this.sliderValue;
        nextValue = this.sliderValue + 1;
      } else {
        beforeValue = 14;
        nowValue = 15;
        nextValue = 0;
      }
      if (this.nextSliderValue && (this.nextSliderValue == nextValue - 1 || this.nextSliderValue - nextValue == 15)) {
        this.$set(this.rainLayers[beforeValue%4], 'url', '');
        this.$set(this.rainLayers[nowValue%4], 'opacity', 0.6);
        this.dtime = this.nextDtime;
        this.getNextTile(nextValue);
      } else {
        await this.$set(this.rainLayers[0], 'url', '');
        await this.$set(this.rainLayers[1], 'url', '');
        await this.$set(this.rainLayers[2], 'url', '');
        await this.$set(this.rainLayers[3], 'url', '');
        this.nextSliderValue = await '';
        this.nextDtime = await '';
        await this.getTile();
        this.getNextTile(nextValue);
      }
    },
    
    playSlider() {
      this.showFranPanel = false;
      this.sliderPlay = true;
      let vm = this;
      this.playingSlider = setInterval(function() {
        if (vm.sliderValue == '現在') {
          vm.sliderValue = 1;
        } else if (vm.sliderValue != 15) {
          vm.sliderValue = vm.sliderValue + 1;
        } else {
          vm.sliderValue = '現在';
        }
        vm.changeRainTile();
      }, 1500);
    },
    
    pauseSlider() {
      this.sliderPlay = false;
      clearInterval(this.playingSlider);
      if(!localStorage.getItem('first_pauseSliderInformation')) {
          localStorage.setItem('first_pauseSliderInformation', 'no');
          this.showPauseSliderInformation = true;
      }
    },
    
    async completeScratch() {
      this.showCloseShokinModal = true;
      if (this.user) {
        try {
          if (this.getCompensation) {
            const winnerInput = {
              id: this.user.id,
              forecast_date: this.timestampFranPast,
              compensation_flag: 1
            };
            await API.graphql(graphqlOperation(updateCompensation, { input: winnerInput }));
            const afterInput = {
              id: this.user.id,
              forecast_date: this.timestampFranPast
            };
            const afterResult = await API.graphql(graphqlOperation(getFranCompensationV2, afterInput));
            this.selectedCompensationData = afterResult.data.getFranCompensationV2;
            this.receiveFlag = true;
          } else {
            const loserInput = {
              id: this.user.id,
              forecast_date: this.timestampFranPast,
              compensation_flag: 0
            };
            await API.graphql(graphqlOperation(updateCompensation, { input: loserInput }));
          }
          this.scratchFlag = false;
        } catch(error) {
          console.error(error);
        }
      }
    },
    
    getCurrentPosition() {
      let vm = this;
      navigator.geolocation.getCurrentPosition(function(position) {
        const currentPointCenter = [position.coords.latitude, position.coords.longitude];
        vm.$refs.map.mapObject.panTo(currentPointCenter);
      });
    },
    
    closeRetryToLoginModal() {
      this.showRetryToLoginModal = false;
    },
    
    closeResetToTokyoModal() {
      this.showResetToTokyoModal = false;
    },
    
    closeFranErrorModal() {
      this.showFranErrorModal = false;
    },
    
    closeShokinModal() {
      this.showShokinModal = false;
      this.$store.dispatch('loadPrizeNumber', this.$store.state.user);
    },
    
    async closeCompensationDialog(event) {
      await this.closeChild(event);
      try {
        const input = {
          id: this.user.id,
          forecast_date: this.timestampFranPast
        };
        const result = await API.graphql(graphqlOperation(getFranCompensationV2, input));
        const resultData = result.data.getFranCompensationV2;
        if (resultData) {
          if (resultData.compensation_flag == 3) {
            this.receiveFlag = false;
          }
        }
      } catch(e) {
        console.error(e);
      }
    },
    
    async closeEventDialog(event) {
      await this.closeEventChild(event);
      this.eventId = '';
      this.eventLocation = '';
    },
    
    async closeNftDialog(event) {
      await this.closeNftChild(event);
      this.selectedNftAmedasId = '';
      this.selectedNftEventId = '';
    },
    
    async closeShopListDialog(event) {
      await this.closeShopListChild(event);
      this.selectedShopAmedasId = '';
    },
    
    async closeShopDialog(event) {
      await this.closeShopChild(event);
      this.selectedShopAmedasId = '';
      this.selectedShopId = '';
      this.selectedShopEventId = '';
    },
    
    handleResize() {
      if(window.innerWidth < 960){
      this.windowHeight = window.innerHeight - this.navbarHeight - this.$store.state.adHeight;
      }
      else{
        this.windowHeight = window.innerHeight - this.navbarHeight;
      }
      this.windowWidth = window.innerWidth;
    },
    
    async reloadPage() {
      const center = this.$refs.map.mapObject.getCenter();
      sessionStorage.setItem('center', [center.lat, center.lng]);
      sessionStorage.setItem('zoom', this.$refs.map.mapObject.getZoom());
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    },
    
    async showFirstInformation() {
      if(!localStorage.getItem('first_information')) {
          localStorage.setItem('first_information', 'no');
          this.showInformation = true;
      }else{
        await this.loadEventInfomation();
      }
    },

    async loadEventInfomation() {
      const date = new Date();
      const dateStr = String(date.getFullYear()) + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0');
      const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
      const filePath = 'https://d3e48edr1ew123.cloudfront.net/json-' + env + '/event-information.json?' + dateStr;
      try {
        const response = await axios.get(filePath);
        const data = JSON.parse(JSON.stringify(response.data));

        if(data["information"]["flag"]){
          let name = data["information"]["name"];

          if(localStorage.getItem('event_information') == name){//表示済みイベント情報
            this.showEventInformation = false;
          }else{    //新規イベント情報
            localStorage.setItem('event_information', name);
            this.showEventInformation = true;

            let d = {};
            d["img"] = data["information"]["img"]["1x"];
            let srclist = [];
            for (let key in data["information"]["img"]) {
              srclist.push(data["information"]["img"][key] + " " + key);
            }
            d["srcSet"] = srclist.join(", ");
            this.eventInfo = d;
          }
        }
      } catch(e) {
        console.error(e);
      }
    },
    
    async loadBadgeNum() {
      await this.$store.dispatch('loadPrizeNumber', this.$store.state.user);
    },
    
    async loadUserEventInformation() {
      const date = new Date();
      const dateStr = String(date.getFullYear()) + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0');
      const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
      const filePath = 'https://d3e48edr1ew123.cloudfront.net/json-' + env + '/user-event-information.json?' + dateStr;
      try {
        const response = await axios.get(filePath);
        const data = JSON.parse(JSON.stringify(response.data));
        this.showUserEventIcon = data["dialog"]["flag"];

        let dataList = [];
        let flagList = [];
        const now = new Date();
        if(this.showUserEventIcon){
          for (let userEventData of data["dialog"]["list"]) {
            const startDate = new Date(userEventData["show_start"]);
            const endDate = new Date(userEventData["show_end"]);
            if (now < startDate || now > endDate) {
              continue;
            }
            
            let d = {};
            d["event_id"] = userEventData["event_id"];
            d["location"] = userEventData["location"];
            d["icon"] = userEventData["icon"]["1x"];
            d["iconright"] = -(userEventData["iconsize"][0]-12)/2;
            d["icontop"] = -(userEventData["iconsize"][1])/2;

            let iconlist = [];
            for (let key in userEventData["icon"]) {
              iconlist.push(userEventData["icon"][key] + " " + key);
            }
            d["iconSet"] = iconlist.join(", ");

            dataList.push(d);
            flagList.push(userEventData["showfirst"]);
          }
          this.userEventInfo = dataList;
        }
      } catch(e) {
        console.error(e);
      }
    },
    
    async loadNftAmedasList() {
      const date = new Date();
      const dateStr = String(date.getFullYear()) + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0');
      const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
      const filePath = 'https://d3e48edr1ew123.cloudfront.net/json-' + env + '/nft-event/nft_amedas_list.json?' + dateStr;
      try {
        const response = await axios.get(filePath);
        const data = JSON.parse(JSON.stringify(response.data));
        this.nftAmedasList = data['list'];
      } catch(e) {
        console.error(e);
      }
    },
    
    async loadShopAmedasList() {
      const date = new Date();
      const dateStr = String(date.getFullYear()) + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0');
      const env = (typeof process.env.VUE_APP_USER_BRANCH === "undefined") ?  'test' : process.env.VUE_APP_USER_BRANCH;
      const filePath = 'https://d3e48edr1ew123.cloudfront.net/json-' + env + '/shop-event/shop_amedas_list.json?' + dateStr;
      try {
        const response = await axios.get(filePath);
        const data = JSON.parse(JSON.stringify(response.data));
        this.shopAmedasList = data['list'];
      } catch(e) {
        console.error(e);
      }
    },

    showEventDialog(info) {
      this.eventId = info.event_id;
      this.eventLocation = info.location;
      this.$nextTick(function() {
        this.diaEventToggle = true;
      });
      this.$nextTick(function() {
        if (this.$refs.event) {
          this.$refs.event.updateEvent();
        }
      });
    },
    
    openNftDialog(nftAmedas) {
      this.selectedNftAmedasId = nftAmedas.amedas_id;
      this.selectedNftEventId = nftAmedas.nft_event_id;
      this.$nextTick(function() {
        this.diaNftToggle = true;
      });
      this.$nextTick(function() {
        if (this.$refs.nft) {
          this.$refs.nft.updateNft();
        }
      });
    },
    
    openShopEventListDialog(amedasId) {
      this.selectedShopAmedasId = amedasId;
      this.$nextTick(function() {
        this.diaShopListToggle = true;
      });
      this.$nextTick(function() {
        if (this.$refs.shopList) {
          this.$refs.shopList.updateShopList();
        }
      });
    },
    
    openShopDialog(amedasId, shopId, eventId) {
      this.selectedShopAmedasId = amedasId;
      this.selectedShopId = shopId;
      this.selectedShopEventId = eventId;
      this.$nextTick(function() {
        this.diaShopListToggle = false;
        this.diaShopToggle = true;
      });
      this.$nextTick(function() {
        if (this.$refs.shop) {
          this.$refs.shop.updateShop();
        }
      });
    },
    
    backToShopList(amedasId) {
      this.selecedShopAmedasId = amedasId;
      this.selectedShopId = '';
      this.selectedShopEventId = '';
      this.$nextTick(function() {
        this.diaShopToggle = false;
        this.diaShopListToggle = true;
      });
      this.$nextTick(function() {
        if (this.$refs.shopList) {
          this.$refs.shopList.updateShopList();
        }
      });
    }
  }
};
</script>

<style scoped>
  @import '~leaflet.markercluster/dist/MarkerCluster.css';
  @import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>
