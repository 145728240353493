<template>
  <section>
    <v-card v-if="display_flg" class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
      <v-container class="fran-weather-today ma-0 pa-0">
        <div class="fran-today-flex d-flex">
          <v-row class="fran-headtitle-today" no-gutters>
            <v-col>
              <span>{{ display_date_string }}：{{ area }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <v-row no-gutters>
          <v-col cols="5">
            <div>
              <v-img
                :src="require(`@/assets/wicon/${today_weather_icon}`)"
                aspect-ratio="2"
                contain
              ></v-img>
            </div>
          </v-col>
          <v-col cols="7" class="flex-column d-flex align-left justify-center">
            <div class="fran-weather-title">
              {{ today_weather_text }}
            </div>
            <div class="fran-weather-degree">
              <span class="fran-weather-red">
                {{ temperature_high }}<sup>℃</sup>
              </span>
              <span class="fran-weather-blue">
                {{ temperature_low }}<sup>℃</sup>
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      </div>
    </v-card>
  </section>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { getFranRainForecastV2, getFranUser } from '@/graphql/queries';
  import AreaSelect from '@/mixins/area-select.js';

  export default {
    name: 'WeatherToday',
    
    mixins: [AreaSelect],
    
    data: () => ({
      timestampFranForecast: '',
      today_weather_text: '',
      today_weather_icon: 'logo.png',
      temperature_high: '',
      temperature_low: '',
      display_date_string: '',
      selectedAmedasId: '44132',
      area: '東京',
      display_flg: true,
    }),
    
    computed: {
      stateFranUser: function() {
        return this.$store.state.franUser;
      }
    },
    
    watch: {
      async stateFranUser (newValue) {
        if (newValue) {
          await this.getPeriod();
          await this.loadUserPoint();
          this.fetchWeatherToday(this.timestampFranForecast);
        }
      }
    },
    
    mounted: async function () {
      await this.getPeriod();
      await this.loadUserPoint();
      this.fetchWeatherToday(this.timestampFranForecast);
    },
    
    methods : {
      getPeriod() {
        let dObj = new Date();
        const timestampNow = Math.floor(dObj.getTime() / 1000) + 32400; // getTimeはミリ秒なので1000で割る 日本時間での経過時間(≠unixtime)
        if(dObj.getHours() >= 18 && dObj.getHours() <= 23) {
          this.timestampFranForecast = Math.floor(timestampNow / 43200) * 43200 - 32400; // 現在時刻より前で直近の0時or12時のunixtime
        } else {
          this.timestampFranForecast = Math.floor(timestampNow / 43200) * 43200 - 32400 - 43200; // 現在時刻より前で直近の1つ前の0時or12時のunixtime
        }
      },
      
      async loadUserPoint() {
        try {
          const user = await this.$store.state.franUser;
          if (user) {
            const input = { id: user.id };
            const result = await API.graphql(graphqlOperation(getFranUser, input));
            const resultData = result.data.getFranUser;
            if (resultData) {
              this.selectedAmedasId = resultData.forecast_amedas_id;
              this.area = this.findCity(this.selectedAmedasId).pointName;
            }
          } else {
            if (!this.selectedAmedasId) {
              this.selectedAmedasId = '44132';
              this.area = '東京';
            }
          }
        } catch(e) {
          console.error(e);
        }
      },
      
      async fetchWeatherToday(forecast_date) {
        try {
          const result = await API.graphql({
            query: getFranRainForecastV2,
            authMode: 'API_KEY',
            variables: {forecast_amedas_id: this.selectedAmedasId, forecast_date: forecast_date},
          });
          const resultData = result.data.getFranRainForecastV2;
          this.today_weather_text = weather_code_conv(resultData.weather_id);
          this.today_weather_icon = resultData.weather_id + '.png';
          this.temperature_high = resultData.max_temp;
          this.temperature_low = resultData.min_temp;
          this.display_date_string = this.day_format((resultData.forecast_date + 43200) * 1000) + ' ';
        } catch(error) {
          this.display_flg = false;
          console.error(`Failed to getFranRainForecast: ${JSON.stringify(error)}`);
        }
      },
      
      day_format(daystr) {
        const WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ];
        const dObj = new Date(daystr);
        const wDay = dObj.getDay();
        const month = dObj.getMonth() + 1;
        const day = dObj.getDate();
        const hour = dObj.getHours();
        const ampm = (hour < 12) ? '午前' : '午後';
        return month + '月' + day + '日' + ' (' + WeekChars[wDay] + ') ' + ampm;
      }
    }
  };
  
  function weather_code_conv(number) {
    const number_head = number.slice(0, 1);
    if(number_head == 1) {
      if(number == 100) {
        return '晴れ';
      }
      if(number == 101) {
        return '晴れ時々くもり';
      }
      if(number == 102) {
        return '晴れ一時雨';
      }
      if(number == 103) {
        return '晴れ時々雨';
      }
      if(number == 104) {
        return '晴れ一時雪';
      }
      if(number == 105) {
        return '晴れ時々雪';
      }
      if(number == 111) {
        return '晴れのちくもり';
      }
      if(number == 114) {
        return '晴れのち雨';
      }
      if(number == 117) {
        return '晴れのち雪';
      }
    }
    if(number_head == 2) {
      if(number == 200) {
        return 'くもり';
      }
      if(number == 201) {
        return 'くもり時々晴れ';
      }
      if(number == 202) {
        return 'くもり一時雨';
      }
      if(number == 203) {
        return 'くもり時々雨';
      }
      if(number == 204) {
        return 'くもり一時雪';
      }
      if(number == 205) {
        return 'くもり時々雪';
      }
      if(number == 211) {
        return 'くもりのち晴れ';
      }
      if(number == 214) {
        return 'くもりのち雨';
      }
      if(number == 217) {
        return 'くもりのち雪';
      }
    }
    if(number_head == 3) {
      if(number == 300) {
        return '雨';
      }
      if(number == 301) {
        return '雨時々晴れ';
      }
      if(number == 302) {
        return '雨一時くもり';
      }
      if(number == 303) {
        return '雨時々雪';
      }
      if(number == 309) {
        return '雨一時雪';
      }
      if(number == 311) {
        return '雨のち晴れ';
      }
      if(number == 313) {
        return '雨のちくもり';
      }
      if(number == 315) {
        return '雨のち雪';
      }
    }
    if(number_head == 4) {
      if(number == 400) {
        return '雪';
      }
      if(number == 401) {
        return '雪時々晴れ';
      }
      if(number == 402) {
        return '雪一時くもり';
      }
      if(number == 403) {
        return '雪時々雨';
      }
      if(number == 409) {
        return '雪一時雨';
      }
      if(number == 411) {
        return '雪のち晴れ';
      }
      if(number == 413) {
        return '雪のちくもり';
      }
      if(number == 414) {
        return '雪のち雨';
      }
    }
    return '雨';
  }
</script>