<!-- 天気ページ -->

<template>
  <section>
    <weather-today />
    <weather-every-hour />
    <weather-week />
    <amedas-observed />
    <div class="ad-banner-floating-other"><ad-banner /></div>
  </section>
</template>

<script>
  export default {
    name: 'Weather',

    components: {
      WeatherToday: () => import('@/components/home/WeatherToday'),
      WeatherEveryHour: () => import('@/components/home/WeatherEveryHour'),
      WeatherWeek: () => import('@/components/home/WeatherWeek'),
      AmedasObserved: () => import('@/components/home/AmedasObserved'),
      AdBanner: () => import('@/components/base/AdBanner.vue')
    }
  };
</script>
