<template>
  <section>
    <base-tittle>退会について</base-tittle>

    <v-card class="fran-wrap d-flex" outlined tile>
      <v-card-text class="text--primary">
        <ul>
          <li>ショウキンの受取りが可能な状態で退会した場合、ショウキンの受取りができない場合があります。</li>
        </ul>
      </v-card-text>
    </v-card>
      
    <v-card 
      v-if="stateFranUser"
      class="fran-wrap" outlined tile
    >
      <v-card-actions>
        <v-spacer />
        
        <!-- エリア選択ボタン -->
        <v-dialog v-model="diaToggle" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="font-weight-regular"
              v-bind="attrs"
              v-on="on"
              outlined
              rounded
              width="130px"
              max-width="130px"
            >
              退会する
            </v-btn>
          </template>
        <!-- 退会確認 ダイアログコンポーネント -->
        <dia-withdrawal
          v-bind:diaToggle="diaToggle"
          v-on:closeMe="closeChild($event)"
        />
      </v-dialog>
        
        <v-spacer />
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
  import DiaClose from '@/mixins/base/dia-close.js'
  export default {
    name: 'Withdrawal',
    
    mixins: [DiaClose],

    components: {
      DiaWithdrawal: () => import('@/components/base/DiaWithdrawal'),
    },

    data: () => ({
      //
    }),
    
    computed: {
      stateFranUser: function() {
        return this.$store.state.franUser
      },
    }
  }
</script>
