<template>
  <section>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>
    <base-tittle>メールアドレス登録・変更</base-tittle>
    <v-form v-model="validForm" lazy-validation>
      <v-card class="d-flex" outlined tile>
        <div class="mx-auto">
          <v-text-field
            class="mt-5"
            label="メールアドレス"
            v-model="userEmail"
            :rules="userEmailRules"
            :disabled = "loginTool==='passwordless'"
            ></v-text-field>
          <v-checkbox
            v-model="mailNewsPermission"
            class= "ma-0 pt-0 pl-2 pb-2 mail-receive-label"
            label="FRANの最新情報をメールで受け取る"
            color="primary"
            hide-details
          ></v-checkbox>
          <v-card-actions class="ma-2">
            <v-btn
              @click="closeMyself"
              :disabled="this.processing"
              class="font-weight-regular ma-auto"
              color="primary"
              width="120px"
              max-width="120px"
              outlined
              dark
              tile
              top
            >キャンセル</v-btn>
            <v-btn
              v-if="user"
              @click="executeSetUserEmail"
              :disabled="!enableSetUserEmail"
              class="font-weight-regular ma-auto dia-receive-btn"
              color="primary"
              width="120px"
              max-width="120px"
              depressed
              dark
              tile
              top
            >
              <template v-if="mode==='add'">登録</template>
              <template v-else>変更</template>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </section>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { updateFranUser } from '@/graphql/mutations';
  import { createFranMailNews, deleteFranMailNews } from '@/graphql/mutations';

  export default {
    name: 'DiaEditEmail',

    props: ['diaToggle', 'mailNewsPermissionChild', 'loginTool'],

    data: () => ({
      validForm: false,
      mode: '',
      user: '',
      userEmail: '',
      userEmailRules: [
        v => /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/.test(v) || 'メールアドレスの形式で入力してください'
      ],
      processing: false,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
      snackbarTimeout: 2000,
      mailNewsPermission: false,
      initMailState: false,
      initEmail: ''
    }),

    watch: {
      async diaToggle(newValue){//設定ウィンドウ開いたときに設定ロードしなおす
        this.loadEmail();
        if(newValue && this.userEmail){
          this.mailNewsPermission = this.mailNewsPermissionChild;
        }
      }
    },
    
    mounted: function() {
      this.loadEmail();
      this.mailNewsPermission = this.mailNewsPermissionChild;
    },
    
    computed: {
      enableSetUserEmail: function() {
        let result = false;
        if (this.validForm === true && this.processing === false && this.userEmail && (this.userEmail != this.initEmail || this.mailNewsPermission != this.mailNewsPermissionChild)) {
          result = true;
        }
        return result;
      }
    },
    
    methods: {
      closeMyself() {
        this.$emit('closeMe', false);
      },
      
      showErrorSnackBar() {
        this.showSnackbar = true;
        this.snackbarColor = 'error';
        setTimeout(function(){this.closeMyself();}.bind(this), this.snackbarTimeout);
      },

      loadEmail(){
        this.user = this.$store.state.franUser;
        if (this.user.email) {
          this.userEmail = this.user.email;
          this.initEmail = this.user.email;
          this.mode = 'change';
        } else {
          this.mode = 'add';
        }
      },
      
      async setUserEmail() {
        const input = {
          id: this.user.id,
          email: this.userEmail
        };
        await API.graphql(graphqlOperation(updateFranUser, { input: input }));
      },

      async saveNewsPermission(){
        if (this.mailNewsPermission != this.mailNewsPermissionChild){//チェックボックスに変更があれば保存
          if(this.mailNewsPermission){
            await API.graphql(graphqlOperation(createFranMailNews, { input: { mail: this.userEmail } }));
            localStorage.removeItem('unsub_news');
          }else{
            await API.graphql(graphqlOperation(deleteFranMailNews, { input: { mail: this.initEmail } }));
            localStorage.setItem('unsub_news', 'y');
          }
        }else if (this.mailNewsPermission && (this.userEmail != this.initEmail)){//受け取る設定のままアドレスを変更
            await API.graphql(graphqlOperation(deleteFranMailNews, { input: { mail: this.initEmail } }));
            await API.graphql(graphqlOperation(createFranMailNews, { input: { mail: this.userEmail } }));
            localStorage.removeItem('unsub_news');
        }
      },
      
      async executeSetUserEmail() {
        this.processing = true;
        this.$store.commit('progressCircleOn');
        try {
          await this.setUserEmail();
        } catch (e) {
          this.$store.commit('progressCircleOff');
          this.snackbarText = 'メールアドレスの値が不正です';
          this.showErrorSnackBar();
          this.processing = false;
          return;
        }
        try {
          await this.saveNewsPermission();
        } catch(e) {
          //DynamoDB:ConditionalCheckFailedExceptionをスルー
        }
        this.$store.commit('progressCircleOff');
        if (this.mode === 'add') {
          this.snackbarText = 'メールアドレス設定を登録しました！';
        } else {
          this.snackbarText = 'メールアドレス設定を変更しました！';
        }
        this.showSnackbar = true;
        this.snackbarColor = 'primary';
        setTimeout(function(){ 
          this.closeMyself();
          this.$emit('email-change', {userEmail: this.userEmail, mailNews: this.mailNewsPermission});
        }.bind(this), this.snackbarTimeout);
        this.processing = false;
      }
    }
  };
</script>
