<template>
  <v-container class="pt-0 pb-0">
    <div class="line-login-btn">
      <v-img 
        v-on:click="signIn('LINE')"
        :src="require('@/assets/btn_login_base.png')"
        width="100px"
      ></v-img>
    </div>
    <div class="google-login-btn">
      <v-btn 
        v-on:click="signIn('Google')" 
        color="#4285f4"
        class="font-weight-regular caption"
        width="100px"
        height="29px"
        depressed
        dark
        tile
        top
      >
        <v-icon left dense class="login-google-icon">mdi-google</v-icon>
        ログイン
      </v-btn>
    </div>
    
    <div class="mail-login-btn">
      <v-btn
        to ="/mailsignin/signin/0" 
        color="#a260bf"
        class="font-weight-regulat caption"
        width="100px"
        height="29px"
        depressed
        dark
        tile
        top
      >
      <v-icon left dense class="login-google-icon">mdi-email</v-icon>
      ログイン</v-btn>
    </div>
    
  </v-container>
</template>

<script>
//import router from '../../router/index.js';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import oauth from '../../aws-oauth-config';

oauth.redirectSignIn = `${window.location.origin}/`;
oauth.redirectSignOut = `${window.location.origin}/`;

if (awsconfig.aws_user_pools_id === "ap-northeast-1_NExyyqoHZ") {
  oauth.domain = "test-auth.franchan.jp";
} else {
  oauth.domain = "sign.franchan.jp";
}
Amplify.configure(awsconfig);
Auth.configure({ oauth });

export default {
  name: 'LoginButtonBox',

  data: () => ({
  }),
  
  mounted() {
    // SignIn check
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
        //case 'signIn':
          //router.push({path: '/'},() => {});
          //break;
        case 'signIn_failure':
          break;
        case 'signOut':
          this.$router.go({path: '/', force: true});
          //router.push({path: '/'},() => {});
          break;
        default:
          break;
      }
    });
  },

  methods: {
    closeMyself() {
      this.$emit('closeMe', false);
    },
    signIn: async function(providerName) {
      if (providerName == 'LINE') {
        await localStorage.setItem('LINELogin', 1)
      }
      await Auth.federatedSignIn({provider: providerName});
    },
  },
};
</script>
