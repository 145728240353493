<!-- ######################################
  #
  # FRAN 
  # 2020-08-31 連 新規作成
  #
  # バージョン情報：v 0.2.0 α
  # 修正回数：17
  # 最終更新日：2020.09.18
  #
####################################### -->

<template>
  <main>
    <!-- mobile インデックスページ メインです -->
    <index />
  </main>
</template>

<script>
import { Amplify, Analytics } from 'aws-amplify';// eslint-disable-line
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);


  export default {
    name: 'App',

    components: {
      Index: () => import('@/views/Index'),
    },

    methods: {
    },
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&family=M+PLUS+Rounded+1c:wght@500;700&display=swap');
  @import "./css/style.css";
</style>
