<template>
  <section>
    <v-card v-if="display_flg" class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
      <v-container class="fran-weather ma-0 pa-0">
         <v-row class="fran-headtitle" no-gutters>
          <v-col>
            <span>実況データ：{{ area }}</span>
            <div class="fran-headtitle-sub">実況データはFRAN予報のエリアに連動</div>
          </v-col>
          <v-col cols="auto">
            <span class="body-1 font-weight-bold"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="text-center">
          <v-col cols="3" class="lighten-1 py-3"></v-col>
          <v-col cols="3" class="py-3">降水量</v-col>
          <v-col cols="3" class="py-3">気温</v-col>
          <v-col cols="3" class="py-3">風速</v-col>
        </v-row>
        <v-divider></v-divider>
        <template v-for="(hourData, index) in franAmedasDataList">
          <v-row :key="hourData.time" class="fran-weather-wrap py-2" no-gutters>
            <v-col cols="3">
              <div class="fran-weather-date">
                {{ getDisplayTime(hourData) }}
              </div>
            </v-col>
            <v-col cols="3">
              <div class="text-center">
                {{ getDisplayRain(hourData) }}
              </div>
            </v-col>
            <v-col cols="3">
              <div class="text-center">
                {{ getDisplayTemp(hourData) }}
              </div>
            </v-col>
            <v-col cols="3">
              <div class="text-center">
                {{ getDisplayWind(hourData) }}
              </div>
            </v-col>
          </v-row>
          <v-divider :key="index"></v-divider>
        </template>
      </v-container>
      </div>
    </v-card>
  </section>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { listFranAmedasV2s, getFranUser } from '@/graphql/queries';
  import AreaSelect from '@/mixins/area-select.js';
  import dayjs from 'dayjs';
  import 'dayjs/locale/ja';
  dayjs.locale('ja');

  export default {
    name: 'AmedasObserved',
    
    mixins: [AreaSelect],

    data: () => ({
      area: '東京',
      selectedAmedasId: '44132',
      franAmedasDataList: '',
      display_flg: true
    }),
    
    computed: {
      stateFranUser: function() {
        return this.$store.state.franUser;
      },
    },
    
    watch: {
      async stateFranUser (newValue) {
        if (newValue) {
          await this.loadUserPoint();
          this.showAmedasDataList();
        }
      }
    },
    
    mounted: async function () {
      await this.loadUserPoint();
      this.showAmedasDataList();
    },
    
    methods: {
      async loadUserPoint() {
        try {
          const user = await this.$store.state.franUser;
          if (user) {
            const input = { id: user.id };
            const result = await API.graphql(graphqlOperation(getFranUser, input));
            const resultData = result.data.getFranUser;
            if (resultData) {
              this.selectedAmedasId = resultData.now_selected_amedas_id;
              this.area = this.findCity(this.selectedAmedasId).pointName;
            } else {
              if (!this.selectedAmedasId) {
                this.selectedAmedasId = '44132';
                this.area = '東京';
              }
            }
          }
        } catch(e) {
          console.error(e);
        }
      },
      
      async showAmedasDataList() {
        this.franAmedasDataList = await this.collectFranAmedasDataList(this.selectedAmedasId);
      },
      
      async collectFranAmedasDataList(amedasId) {
        let dataList = await this.getAmedasDataListFor(amedasId, dayjs());
        if (!dataList || dataList.length < 1) {
          // 当日分が取得できない場合は、前日分を取得する
          dataList = await this.getAmedasDataListFor(amedasId, dayjs().subtract(1, 'd'));
        }
        const franAmedasDataList = [];
        let lastObsDateHour = null;
        dataList.forEach(data => {
          if (data.amedas_list && data.obs_date) {
            const d = dayjs(data.obs_date);
            const obsDateHour = parseInt(d.format('H'), 10);
            // 一つ前の時間帯と連続していなければ、空データで埋める
            if (lastObsDateHour && obsDateHour > (lastObsDateHour + 1)) {
              let times = obsDateHour - (lastObsDateHour + 1);
              while(times > 0) {
                const spacer = {
                  time: d.subtract(times, 'hour'),
                  temp: null,
                  rain: null,
                  wind: null
                };
                franAmedasDataList.push(spacer);
                times--;
              }
            }
            const obj = {
              time: data.obs_date,
              temp: data.amedas_list.temp,
              rain: data.amedas_list.rain,
              wind: data.amedas_list.wind
            };
            franAmedasDataList.push(obj);
            lastObsDateHour = obsDateHour;
          }
        });
        return franAmedasDataList;
      },
      
      async getAmedasDataListFor(amedasId, targetDate) {
        if (amedasId && targetDate) {
          try {
            const dateFrom = targetDate.startOf('day').add(1, 'h').toISOString();
            const dateTo = targetDate.add(1, 'd').startOf('day').toISOString();
            const input = { 
              amedas_id: amedasId, 
              sortDirection: 'ASC', limit: 24,
              obs_date: {
                between: [dateFrom, dateTo]
              }
            };
            const result = await API.graphql({
              query: listFranAmedasV2s,
              authMode: 'API_KEY',
              variables: input,
            });
            const dataList = result.data.listFranAmedasV2s.items;
            if (dataList.length === 0) {
              this.display_flg = false;
            }
            return dataList;
          } catch(error) {
            console.error(`Failed to load amedas data list: ${JSON.stringify(error)}`);
            return [];
          }
        } else {
          return [];
        }
      },
      
      getDisplayTime(data) {
        let result = '--';
        if (data && data.time) {
          const d = dayjs(data.time);
          const hour = parseInt(d.format('H'), 10);
          if (hour == 0) {
            result = '24:00';
          } else {
            result = d.format('H:mm');
          }
        }
        return result;
      },
      
      getDisplayRain(data) {
        let result = '--';
        if (data && data.rain >= 0) {
          result = `${data.rain.toFixed(1)}mm`;
        }
        return result;
      },
      
      getDisplayTemp(data) {
        let result = '--';
        if (data && data.temp && data.temp != -999) {
          result = `${data.temp.toFixed(1)}℃`;
        }
        return result;
      },
      
      getDisplayWind(data) {
        let result = '--';
        if (data && data.wind && data.wind != -999) {
          result = `${data.wind.toFixed(1)}m`;
        }
        return result;
      },
    },
  };
</script>
