/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const payCompensation = /* GraphQL */ `
  mutation PayCompensation($input: PayCompensationInput!) {
    payCompensation(input: $input) {
      user_id
      forecast_date
      compensation_flag
      linepay
      message
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteFranInput!) {
    DeleteUser(input: $input) {
      id
    }
  }
`;
export const updateCompensation = /* GraphQL */ `
  mutation UpdateCompensation($input: UpdateCompensationInput!) {
    UpdateCompensation(input: $input) {
      id
      forecast_date
      compensation_flag
    }
  }
`;
export const createSelectedPoints = /* GraphQL */ `
  mutation CreateSelectedPoints($input: CreateSelectedPointsInput!) {
    CreateSelectedPoints(input: $input) {
      id
      forecast_date
      selected_amedas_id
      deletedAt
    }
  }
`;
export const setEventParticipation = /* GraphQL */ `
  mutation SetEventParticipation($input: SetEventParticipationInput!) {
    SetEventParticipation(input: $input) {
      user_id
      event_id
      user_answer
      deletedAt
    }
  }
`;
export const setNftParticipation = /* GraphQL */ `
  mutation SetNftParticipation($input: SetNftParticipationInput!) {
    SetNftParticipation(input: $input) {
      user_id
      nft_event_id
      amedas_id
      user_answer
      deletedAt
    }
  }
`;
export const updateShopEvent = /* GraphQL */ `
  mutation UpdateShopEvent($input: UpdateShopEventInput!) {
    UpdateShopEvent(input: $input) {
      shop_id
      event_id
      register_id
      email
      operation_mode
      amedas_id
      tweet_id
      start_date
      end_date
      start_hour
      end_hour
      result
    }
  }
`;
export const createFranRainForecastV2 = /* GraphQL */ `
  mutation CreateFranRainForecastV2(
    $input: CreateFranRainForecastV2Input!
    $condition: ModelFranRainForecastV2ConditionInput
  ) {
    createFranRainForecastV2(input: $input, condition: $condition) {
      forecast_amedas_id
      forecast_date
      amedas_point_name
      max_temp
      min_temp
      one_mm_rain
      weather_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranRainForecastV2 = /* GraphQL */ `
  mutation UpdateFranRainForecastV2(
    $input: UpdateFranRainForecastV2Input!
    $condition: ModelFranRainForecastV2ConditionInput
  ) {
    updateFranRainForecastV2(input: $input, condition: $condition) {
      forecast_amedas_id
      forecast_date
      amedas_point_name
      max_temp
      min_temp
      one_mm_rain
      weather_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranRainForecastV2 = /* GraphQL */ `
  mutation DeleteFranRainForecastV2(
    $input: DeleteFranRainForecastV2Input!
    $condition: ModelFranRainForecastV2ConditionInput
  ) {
    deleteFranRainForecastV2(input: $input, condition: $condition) {
      forecast_amedas_id
      forecast_date
      amedas_point_name
      max_temp
      min_temp
      one_mm_rain
      weather_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFranEveryHoursForecastV2 = /* GraphQL */ `
  mutation CreateFranEveryHoursForecastV2(
    $input: CreateFranEveryHoursForecastV2Input!
    $condition: ModelFranEveryHoursForecastV2ConditionInput
  ) {
    createFranEveryHoursForecastV2(input: $input, condition: $condition) {
      amedas_id
      forecast_value {
        datetime
        temperature
        weather_id
        rain
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFranEveryHoursForecastV2 = /* GraphQL */ `
  mutation UpdateFranEveryHoursForecastV2(
    $input: UpdateFranEveryHoursForecastV2Input!
    $condition: ModelFranEveryHoursForecastV2ConditionInput
  ) {
    updateFranEveryHoursForecastV2(input: $input, condition: $condition) {
      amedas_id
      forecast_value {
        datetime
        temperature
        weather_id
        rain
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranEveryHoursForecastV2 = /* GraphQL */ `
  mutation DeleteFranEveryHoursForecastV2(
    $input: DeleteFranEveryHoursForecastV2Input!
    $condition: ModelFranEveryHoursForecastV2ConditionInput
  ) {
    deleteFranEveryHoursForecastV2(input: $input, condition: $condition) {
      amedas_id
      forecast_value {
        datetime
        temperature
        weather_id
        rain
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFranWeeklyForecastV2 = /* GraphQL */ `
  mutation CreateFranWeeklyForecastV2(
    $input: CreateFranWeeklyForecastV2Input!
    $condition: ModelFranWeeklyForecastV2ConditionInput
  ) {
    createFranWeeklyForecastV2(input: $input, condition: $condition) {
      amedas_id
      forecast_value {
        amedas_id
        area_name
        forecast_date
        week_list {
          date
          temperature_high
          temperature_low
          weather_id
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFranWeeklyForecastV2 = /* GraphQL */ `
  mutation UpdateFranWeeklyForecastV2(
    $input: UpdateFranWeeklyForecastV2Input!
    $condition: ModelFranWeeklyForecastV2ConditionInput
  ) {
    updateFranWeeklyForecastV2(input: $input, condition: $condition) {
      amedas_id
      forecast_value {
        amedas_id
        area_name
        forecast_date
        week_list {
          date
          temperature_high
          temperature_low
          weather_id
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranWeeklyForecastV2 = /* GraphQL */ `
  mutation DeleteFranWeeklyForecastV2(
    $input: DeleteFranWeeklyForecastV2Input!
    $condition: ModelFranWeeklyForecastV2ConditionInput
  ) {
    deleteFranWeeklyForecastV2(input: $input, condition: $condition) {
      amedas_id
      forecast_value {
        amedas_id
        area_name
        forecast_date
        week_list {
          date
          temperature_high
          temperature_low
          weather_id
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFranAmedasV2 = /* GraphQL */ `
  mutation CreateFranAmedasV2(
    $input: CreateFranAmedasV2Input!
    $condition: ModelFranAmedasV2ConditionInput
  ) {
    createFranAmedasV2(input: $input, condition: $condition) {
      amedas_id
      obs_date
      amedas_list {
        rain
        temp
        wind
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranAmedasV2 = /* GraphQL */ `
  mutation UpdateFranAmedasV2(
    $input: UpdateFranAmedasV2Input!
    $condition: ModelFranAmedasV2ConditionInput
  ) {
    updateFranAmedasV2(input: $input, condition: $condition) {
      amedas_id
      obs_date
      amedas_list {
        rain
        temp
        wind
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranAmedasV2 = /* GraphQL */ `
  mutation DeleteFranAmedasV2(
    $input: DeleteFranAmedasV2Input!
    $condition: ModelFranAmedasV2ConditionInput
  ) {
    deleteFranAmedasV2(input: $input, condition: $condition) {
      amedas_id
      obs_date
      amedas_list {
        rain
        temp
        wind
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFranWinnerSelectedPointsV2 = /* GraphQL */ `
  mutation CreateFranWinnerSelectedPointsV2(
    $input: CreateFranWinnerSelectedPointsV2Input!
    $condition: ModelFranWinnerSelectedPointsV2ConditionInput
  ) {
    createFranWinnerSelectedPointsV2(input: $input, condition: $condition) {
      forecast_date
      selected_amedas_ids
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranWinnerSelectedPointsV2 = /* GraphQL */ `
  mutation UpdateFranWinnerSelectedPointsV2(
    $input: UpdateFranWinnerSelectedPointsV2Input!
    $condition: ModelFranWinnerSelectedPointsV2ConditionInput
  ) {
    updateFranWinnerSelectedPointsV2(input: $input, condition: $condition) {
      forecast_date
      selected_amedas_ids
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranWinnerSelectedPointsV2 = /* GraphQL */ `
  mutation DeleteFranWinnerSelectedPointsV2(
    $input: DeleteFranWinnerSelectedPointsV2Input!
    $condition: ModelFranWinnerSelectedPointsV2ConditionInput
  ) {
    deleteFranWinnerSelectedPointsV2(input: $input, condition: $condition) {
      forecast_date
      selected_amedas_ids
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createLimitMailSignIn = /* GraphQL */ `
  mutation CreateLimitMailSignIn(
    $input: CreateLimitMailSignInInput!
    $condition: ModelLimitMailSignInConditionInput
  ) {
    createLimitMailSignIn(input: $input, condition: $condition) {
      mail
      deletedAt
      count
      createdAt
      updatedAt
    }
  }
`;
export const updateLimitMailSignIn = /* GraphQL */ `
  mutation UpdateLimitMailSignIn(
    $input: UpdateLimitMailSignInInput!
    $condition: ModelLimitMailSignInConditionInput
  ) {
    updateLimitMailSignIn(input: $input, condition: $condition) {
      mail
      deletedAt
      count
      createdAt
      updatedAt
    }
  }
`;
export const deleteLimitMailSignIn = /* GraphQL */ `
  mutation DeleteLimitMailSignIn(
    $input: DeleteLimitMailSignInInput!
    $condition: ModelLimitMailSignInConditionInput
  ) {
    deleteLimitMailSignIn(input: $input, condition: $condition) {
      mail
      deletedAt
      count
      createdAt
      updatedAt
    }
  }
`;
export const createFranMailNews = /* GraphQL */ `
  mutation CreateFranMailNews(
    $input: CreateFranMailNewsInput!
    $condition: ModelFranMailNewsConditionInput
  ) {
    createFranMailNews(input: $input, condition: $condition) {
      mail
      createdAt
      updatedAt
    }
  }
`;
export const updateFranMailNews = /* GraphQL */ `
  mutation UpdateFranMailNews(
    $input: UpdateFranMailNewsInput!
    $condition: ModelFranMailNewsConditionInput
  ) {
    updateFranMailNews(input: $input, condition: $condition) {
      mail
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranMailNews = /* GraphQL */ `
  mutation DeleteFranMailNews(
    $input: DeleteFranMailNewsInput!
    $condition: ModelFranMailNewsConditionInput
  ) {
    deleteFranMailNews(input: $input, condition: $condition) {
      mail
      createdAt
      updatedAt
    }
  }
`;
export const createFranUser = /* GraphQL */ `
  mutation CreateFranUser(
    $input: CreateFranUserInput!
    $condition: ModelFranUserConditionInput
  ) {
    createFranUser(input: $input, condition: $condition) {
      id
      user_name
      email
      forecast_amedas_id
      now_selected_amedas_id
      age
      gender
      createdAt
      updatedAt
    }
  }
`;
export const updateFranUser = /* GraphQL */ `
  mutation UpdateFranUser(
    $input: UpdateFranUserInput!
    $condition: ModelFranUserConditionInput
  ) {
    updateFranUser(input: $input, condition: $condition) {
      id
      user_name
      email
      forecast_amedas_id
      now_selected_amedas_id
      age
      gender
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranUser = /* GraphQL */ `
  mutation DeleteFranUser(
    $input: DeleteFranUserInput!
    $condition: ModelFranUserConditionInput
  ) {
    deleteFranUser(input: $input, condition: $condition) {
      id
      user_name
      email
      forecast_amedas_id
      now_selected_amedas_id
      age
      gender
      createdAt
      updatedAt
    }
  }
`;
export const createFranSelectedPointsV2 = /* GraphQL */ `
  mutation CreateFranSelectedPointsV2(
    $input: CreateFranSelectedPointsV2Input!
    $condition: ModelFranSelectedPointsV2ConditionInput
  ) {
    createFranSelectedPointsV2(input: $input, condition: $condition) {
      id
      forecast_date
      selected_amedas_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranSelectedPointsV2 = /* GraphQL */ `
  mutation UpdateFranSelectedPointsV2(
    $input: UpdateFranSelectedPointsV2Input!
    $condition: ModelFranSelectedPointsV2ConditionInput
  ) {
    updateFranSelectedPointsV2(input: $input, condition: $condition) {
      id
      forecast_date
      selected_amedas_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranSelectedPointsV2 = /* GraphQL */ `
  mutation DeleteFranSelectedPointsV2(
    $input: DeleteFranSelectedPointsV2Input!
    $condition: ModelFranSelectedPointsV2ConditionInput
  ) {
    deleteFranSelectedPointsV2(input: $input, condition: $condition) {
      id
      forecast_date
      selected_amedas_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFranCompensationV2 = /* GraphQL */ `
  mutation CreateFranCompensationV2(
    $input: CreateFranCompensationV2Input!
    $condition: ModelFranCompensationV2ConditionInput
  ) {
    createFranCompensationV2(input: $input, condition: $condition) {
      id
      forecast_date
      compensation_flag
      linepay
      price
      limitedAt
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranCompensationV2 = /* GraphQL */ `
  mutation UpdateFranCompensationV2(
    $input: UpdateFranCompensationV2Input!
    $condition: ModelFranCompensationV2ConditionInput
  ) {
    updateFranCompensationV2(input: $input, condition: $condition) {
      id
      forecast_date
      compensation_flag
      linepay
      price
      limitedAt
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranCompensationV2 = /* GraphQL */ `
  mutation DeleteFranCompensationV2(
    $input: DeleteFranCompensationV2Input!
    $condition: ModelFranCompensationV2ConditionInput
  ) {
    deleteFranCompensationV2(input: $input, condition: $condition) {
      id
      forecast_date
      compensation_flag
      linepay
      price
      limitedAt
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFranEventParticipationV2 = /* GraphQL */ `
  mutation CreateFranEventParticipationV2(
    $input: CreateFranEventParticipationV2Input!
    $condition: ModelFranEventParticipationV2ConditionInput
  ) {
    createFranEventParticipationV2(input: $input, condition: $condition) {
      user_id
      event_id
      user_answer
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranEventParticipationV2 = /* GraphQL */ `
  mutation UpdateFranEventParticipationV2(
    $input: UpdateFranEventParticipationV2Input!
    $condition: ModelFranEventParticipationV2ConditionInput
  ) {
    updateFranEventParticipationV2(input: $input, condition: $condition) {
      user_id
      event_id
      user_answer
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranEventParticipationV2 = /* GraphQL */ `
  mutation DeleteFranEventParticipationV2(
    $input: DeleteFranEventParticipationV2Input!
    $condition: ModelFranEventParticipationV2ConditionInput
  ) {
    deleteFranEventParticipationV2(input: $input, condition: $condition) {
      user_id
      event_id
      user_answer
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFranNftParticipation = /* GraphQL */ `
  mutation CreateFranNftParticipation(
    $input: CreateFranNftParticipationInput!
    $condition: ModelFranNftParticipationConditionInput
  ) {
    createFranNftParticipation(input: $input, condition: $condition) {
      user_id
      nft_event_id
      amedas_id
      user_answer
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranNftParticipation = /* GraphQL */ `
  mutation UpdateFranNftParticipation(
    $input: UpdateFranNftParticipationInput!
    $condition: ModelFranNftParticipationConditionInput
  ) {
    updateFranNftParticipation(input: $input, condition: $condition) {
      user_id
      nft_event_id
      amedas_id
      user_answer
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranNftParticipation = /* GraphQL */ `
  mutation DeleteFranNftParticipation(
    $input: DeleteFranNftParticipationInput!
    $condition: ModelFranNftParticipationConditionInput
  ) {
    deleteFranNftParticipation(input: $input, condition: $condition) {
      user_id
      nft_event_id
      amedas_id
      user_answer
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFranNftWinners = /* GraphQL */ `
  mutation CreateFranNftWinners(
    $input: CreateFranNftWinnersInput!
    $condition: ModelFranNftWinnersConditionInput
  ) {
    createFranNftWinners(input: $input, condition: $condition) {
      user_id
      nft_event_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranNftWinners = /* GraphQL */ `
  mutation UpdateFranNftWinners(
    $input: UpdateFranNftWinnersInput!
    $condition: ModelFranNftWinnersConditionInput
  ) {
    updateFranNftWinners(input: $input, condition: $condition) {
      user_id
      nft_event_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranNftWinners = /* GraphQL */ `
  mutation DeleteFranNftWinners(
    $input: DeleteFranNftWinnersInput!
    $condition: ModelFranNftWinnersConditionInput
  ) {
    deleteFranNftWinners(input: $input, condition: $condition) {
      user_id
      nft_event_id
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFranEventPrizeV2 = /* GraphQL */ `
  mutation CreateFranEventPrizeV2(
    $input: CreateFranEventPrizeV2Input!
    $condition: ModelFranEventPrizeV2ConditionInput
  ) {
    createFranEventPrizeV2(input: $input, condition: $condition) {
      user_id
      event_id
      prize_flag
      linepay
      price
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFranEventPrizeV2 = /* GraphQL */ `
  mutation UpdateFranEventPrizeV2(
    $input: UpdateFranEventPrizeV2Input!
    $condition: ModelFranEventPrizeV2ConditionInput
  ) {
    updateFranEventPrizeV2(input: $input, condition: $condition) {
      user_id
      event_id
      prize_flag
      linepay
      price
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranEventPrizeV2 = /* GraphQL */ `
  mutation DeleteFranEventPrizeV2(
    $input: DeleteFranEventPrizeV2Input!
    $condition: ModelFranEventPrizeV2ConditionInput
  ) {
    deleteFranEventPrizeV2(input: $input, condition: $condition) {
      user_id
      event_id
      prize_flag
      linepay
      price
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFranShopEvent = /* GraphQL */ `
  mutation CreateFranShopEvent(
    $input: CreateFranShopEventInput!
    $condition: ModelFranShopEventConditionInput
  ) {
    createFranShopEvent(input: $input, condition: $condition) {
      shop_id
      event_id
      register_id
      email
      display
      amedas_id
      tweet_id
      start_date
      end_date
      start_hour
      end_hour
      createdAt
      updatedAt
    }
  }
`;
export const updateFranShopEvent = /* GraphQL */ `
  mutation UpdateFranShopEvent(
    $input: UpdateFranShopEventInput!
    $condition: ModelFranShopEventConditionInput
  ) {
    updateFranShopEvent(input: $input, condition: $condition) {
      shop_id
      event_id
      register_id
      email
      display
      amedas_id
      tweet_id
      start_date
      end_date
      start_hour
      end_hour
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranShopEvent = /* GraphQL */ `
  mutation DeleteFranShopEvent(
    $input: DeleteFranShopEventInput!
    $condition: ModelFranShopEventConditionInput
  ) {
    deleteFranShopEvent(input: $input, condition: $condition) {
      shop_id
      event_id
      register_id
      email
      display
      amedas_id
      tweet_id
      start_date
      end_date
      start_hour
      end_hour
      createdAt
      updatedAt
    }
  }
`;
export const createFranNotificationPermission = /* GraphQL */ `
  mutation CreateFranNotificationPermission(
    $input: CreateFranNotificationPermissionInput!
    $condition: ModelFranNotificationPermissionConditionInput
  ) {
    createFranNotificationPermission(input: $input, condition: $condition) {
      topic
      endpoint
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateFranNotificationPermission = /* GraphQL */ `
  mutation UpdateFranNotificationPermission(
    $input: UpdateFranNotificationPermissionInput!
    $condition: ModelFranNotificationPermissionConditionInput
  ) {
    updateFranNotificationPermission(input: $input, condition: $condition) {
      topic
      endpoint
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteFranNotificationPermission = /* GraphQL */ `
  mutation DeleteFranNotificationPermission(
    $input: DeleteFranNotificationPermissionInput!
    $condition: ModelFranNotificationPermissionConditionInput
  ) {
    deleteFranNotificationPermission(input: $input, condition: $condition) {
      topic
      endpoint
      id
      createdAt
      updatedAt
    }
  }
`;
