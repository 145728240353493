<template>
  <section id="fran-prize">
    <get-prize />
    <about-prize />
    <winner-points />
    <selected-points />
    <box-result-map />
    <div class="ad-banner-floating-other"><ad-banner /></div>
  </section>
</template>

<script>
  export default {
    name: 'Pay',

    components: {
      AboutPrize: () => import('@/components/prize/AboutPrize.vue'),
      GetPrize: () => import('@/components/prize/GetPrize.vue'),
      WinnerPoints: () => import('@/components/prize/WinnerPoints.vue'),
      SelectedPoints: () => import('@/components/prize/SelectedPoints.vue'),
      BoxResultMap: () => import('@/components/home/BoxResultMap.vue'),
      AdBanner: () => import('@/components/base/AdBanner.vue')
    },

    data: () => ({
      //
    }),
  };
</script>
