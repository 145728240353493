<template>
  <section>
    <v-card v-if="display_flg" class="fran-wrap" outlined tile>
      <div class="fran-wrap-center">
      <v-container class="fran-weather ma-0 pa-0">
        <v-row class="fran-headtitle" no-gutters>
          <v-col>
            <span>週間天気：{{ area }} </span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <template v-for="(sevenday, index) in weekdata">
          <v-row v-if="sevenday.day" :key="sevenday.id" class="fran-weather-wrap" no-gutters>
            <v-col cols="3">
              <div
                v-text="sevenday.day"
                class="fran-weather-date"
              ></div>
            </v-col>
            <v-col cols="3">
              <div>
                <v-img
                  :src="require(`@/assets/wicon/${sevenday.pic}`)"
                  aspect-ratio="3"
                  contain
                ></v-img>
              </div>
            </v-col>
            <v-col cols="3">
              <div
                v-text="sevenday.maxiTem"
                class="fran-weather-degree fran-weather-red"
              ></div>
            </v-col>
            <v-col cols="3">
              <div
                v-text="sevenday.minTem"
                class="fran-weather-degree fran-weather-blue"
              ></div
            ></v-col>
          </v-row>
          <v-divider v-else :key="index.divider"></v-divider>
        </template>
      </v-container>
      </div>
    </v-card>
  </section>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { getFranWeeklyForecastV2, getFranUser } from '@/graphql/queries';
  import AreaSelect from '@/mixins/area-select.js';

  export default {
    name: 'WeatherWeek',
   
    mixins: [AreaSelect],
  
    computed: {
      stateFranUser: function() {
        return this.$store.state.franUser;
      },
    },
    
    watch: {
      async stateFranUser (newValue) {
        if (newValue) {
          await this.loadUserPoint();
          this.showWeatherWeek();
        }
      }
    },
    
    mounted: async function() {
      await this.loadUserPoint();
      this.showWeatherWeek();
    },
   
    methods: {
      async loadUserPoint() {
        try {
          const user = await this.$store.state.franUser;
          if (user) {
            const input = { id: user.id };
            const result = await API.graphql(graphqlOperation(getFranUser, input));
            const resultData = result.data.getFranUser;
            if (resultData) {
              this.selectedAmedasId = resultData.forecast_amedas_id;
              this.area = this.findCity(this.selectedAmedasId).pointName;
            }
          } else {
            if (!this.selectedAmedasId) {
              this.selectedAmedasId = '44132';
              this.area = '東京';
            }
          }
        } catch(e) {
          console.error(e);
        }
      },
      
      async showWeatherWeek() {
        try {
          const tmpDayData = await API.graphql({
            query: getFranWeeklyForecastV2,
            authMode: 'API_KEY',
            variables: {amedas_id: this.selectedAmedasId},
          });
          this.daily_forecast_list = [];
          this.daily_forecast_list.push(...this.daily_forecast_list, ...tmpDayData.data.getFranWeeklyForecastV2.forecast_value.week_list);
          for (let i in this.daily_forecast_list) {
            this.weekdata[i*2].day = this.daily_forecast_list[i].date;
            this.weekdata[i*2].maxiTem = this.daily_forecast_list[i].temperature_high + '℃';
            this.weekdata[i*2].minTem = this.daily_forecast_list[i].temperature_low + '℃';
            this.weekdata[i*2].pic = this.daily_forecast_list[i].weather_id + '.png';
            this.weekdata[i*2].day = day_format(this.daily_forecast_list[i].date);
          }
        } catch(e) {
          this.display_flg = false;
          console.log('error!(getFranWeeklyForecast)');
          console.log(e);
        }
      }
    },
    
    data: () => ({
      weekdata: [
        {
          id: 1,
          day: '-/-（-）',
          maxiTem: '-℃',
          minTem: '-℃',
          precipitation: '-%',
          pic: '11.png',
        },
        { divider: true },
        {
          id: 2,
          day: '-/-（-）',
          maxiTem: '-℃',
          minTem: '-℃',
          precipitation: '-%',
          pic: '11.png',
        },
        { divider: true },
        {
          id: 3,
          day: '-/-（-）',
          maxiTem: '-℃',
          minTem: '-℃',
          precipitation: '-%',
          pic: '11.png',
        },
        { divider: true },
        {
          id: 4,
          day: '-/-（-）',
          maxiTem: '-℃',
          minTem: '-℃',
          precipitation: '-%',
          pic: '11.png',
        },
        { divider: true },
        {
          id: 5,
          day: '-/-（-）',
          maxiTem: '-℃',
          minTem: '-℃',
          precipitation: '-%',
          pic: '11.png',
        },
        { divider: true },
        {
          id: 6,
          day: '-/-（-）',
          maxiTem: '-℃',
          minTem: '-℃',
          precipitation: '-%',
          pic: '11.png',
        },
        { divider: true },
        {
          id: 7,
          day: '-/-（-）',
          maxiTem: '-℃',
          minTem: '-℃',
          precipitation: '-%',
          pic: '11.png',
        },
        { divider: false },
      ],
      daily_forecast_list: [],
      today_string: '',
      area: '東京',
      selectedAmedasId: '44132',
      display_flg: true
    })
  };
  
  function day_format(daystr) {
    var WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ];
    var dObj = new Date(daystr);
    var wDay = dObj.getDay();
    var month = dObj.getMonth() + 1;
    var day = dObj.getDate();
    return month + '/' + day + ' (' + WeekChars[wDay] + ')';
  }
</script>
