import Vue from 'vue';
import Vuex from 'vuex';
import { API, graphqlOperation } from 'aws-amplify';
import { getFranUser, listFranCompensationV2s } from '../graphql/queries';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    franUser: null,
    showProgressCircle: false,
    prizeFlag: 0,
    adHeight: 0
  },
  getters: {
    getFranUser: state => {
     return state.franUser;
    }
  },
  mutations: {
    // ユーザー情報保存
    setUser(state, user) {
      state.user = user;
    },
    setFranUser(state, franUser) {
      state.franUser = franUser;
    },
    // プログレスサークル表示
    progressCircleOn(state) {
      state.showProgressCircle = true;
    },
    progressCircleOff(state) {
      state.showProgressCircle = false;
    },
    loadPrizeNum(state, number){
      state.prizeFlag = number;
    },
    changeAdHeight(state, number) {
      state.adHeight = number;
    }
  },
  actions: {
    // ユーザーを変更する
    // ユーザーのログイン・ログアウト時に実行する
    async changeUser({ commit, dispatch }, user) {
      // console.log(`store.changeUser called: ${user}`)
      commit('setUser', user);
      await dispatch('fetchFranUserData', user);
    },
    // ログインユーザーに応じた FranUser 情報を取得する
    async fetchFranUserData({ commit }, user) {
      if (user && user.username) {
        const userId = user.username;
        try {
          const input = { id: userId };
          const result = await API.graphql(graphqlOperation(getFranUser, input));
          const franUser = result.data.getFranUser;
          commit('setFranUser', franUser);
        } catch(error) {
          const errorMessage = `Failed to find FranUser: ${JSON.stringify(error)}`;
          console.error(errorMessage);
          throw new Error(errorMessage);
        }
      } else {
        commit('setFranUser', null);
      }
    },
    async loadPrizeNumber({ commit }, user) {
      if (user) {
        try {
          // FranCompensation より受取可能な償金レコードを取得する
          const input = { id: user.username, filter: { compensation_flag: { eq: 1 }  }};
          const result = await API.graphql(graphqlOperation(listFranCompensationV2s, input));
          const compensationData = result.data.listFranCompensationV2s.items;
          //isNotExpired()
          let prizeNumber = 0;
          for (let i = 0; i < compensationData.length; ++i) {
            const limitedAt = dayjs(compensationData[i].limitedAt);
            if (limitedAt.isAfter(dayjs())) {
              prizeNumber++;
              }
          }
          if (!prizeNumber) {
            commit('loadPrizeNum',0);
          }
          commit('loadPrizeNum',prizeNumber);
        } catch(error) {
          commit('loadPrizeNum',0);
        }
      } else {
        commit('loadPrizeNum',0);
      }
    },
  },
});
