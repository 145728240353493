<!-- バックバー -->

<template>
  <section>
    <v-toolbar height="60" color="white" flat tile>
      <v-btn @click="prev" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-toolbar>
  </section>
</template>

<script>
  export default {
    name: 'GobackBar',

    data: () => ({
      //
    }),

    methods: {
      prev() {
        this.$router.back()
      },
    },
  }
</script>
