<template>
  <section>
    <base-tittle>ユーザ情報</base-tittle>
    <v-card class="fran-wrap d-flex" outlined tile>
      <v-card-text class="text--primary">
        <template v-if="loginTool">
          <ul>
            <li>ID</li>
            {{ userId }}
            <li>
              メールアドレス
            <v-dialog v-model="diaToggle" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="user-info-button"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  rounded
                >
                {{ btnmode }}
                </v-btn>
              </template>
              <dia-edit-email
                v-bind:mailNewsPermissionChild="mailNewsPermission"
                v-bind:loginTool="loginTool"
                v-bind:diaToggle="diaToggle"
                v-on:email-change="changeEmail"
                v-on:closeMe="closeChild($event)"
              />
            </v-dialog>
            </li>
            <p v-if="userEmail" class="ma-0">{{ userEmail }}</p>
            <p v-else class="ma-0">未設定</p>
            <v-checkbox
              v-model="mailNewsPermission"
              class= "ma-0 pt-0 mail-receive-label"
              label="FRANの最新情報をメールで受け取る"
              color="#289FDA"
              hide-details
              disabled="disabled"
            ></v-checkbox>
          </ul>
        </template>
        <template v-else>
          メール配信設定を変更するにはログインが必要です。
        </template>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
  import DiaClose from '@/mixins/base/dia-close.js';
  import { API, graphqlOperation, Auth } from 'aws-amplify';
  import { getFranMailNews } from '@/graphql/queries';
  
  export default {
    name: 'UserInfo',
    
    mixins: [DiaClose],

    components: {
      DiaEditEmail: () => import('@/components/base/DiaEditEmail.vue')
    },

    data: () => ({
      timeout: 2000,
      //stateFranUser:'',
      userId:'',
      userEmail: '',
      loginTool: '',
      mailNewsPermission: false,
      btnmode: ''
    }),

  computed: {
    stateFranUser: function() {
      return this.$store.state.franUser;
    }
  },

    mounted: async function () {
      if (this.stateFranUser) {
        this.userId = this.stateFranUser.id;
        this.loadLoginTool();
      }
      if (this.stateFranUser && this.stateFranUser.email) {
        this.userEmail = this.stateFranUser.email;
        this.loadNewsPermission();
      }
      this.loadButtonMode();

    },

    watch: {
      stateFranUser(newValue){//設定ウィンドウ開いたときに設定ロードしなおす
      if (newValue) {
        this.userId = this.stateFranUser.id;
        this.loadLoginTool();
      }
      if (newValue && this.stateFranUser.email) {
        this.userEmail = this.stateFranUser.email;
        this.loadNewsPermission();
      }
      this.loadButtonMode();
      }
    },

    methods: {
      loadLoginTool: function() {
        let result;
        const MailRegExp = /^Email/;
        if (MailRegExp.test(this.userId)) {
          result = 'passwordless';
        } else {
          result = 'social';
        }

        this.loginTool = result;
      },

      loadButtonMode: function() {
        if(this.loginTool==='social' && !this.userEmail){
          this.btnmode = '登録';
        }else if((this.loginTool==='social' && this.userEmail) || this.loginTool==='passwordless'){
          this.btnmode = '変更';
        }
      },

      async loadNewsPermission(){
        try {
          const input = { mail: this.userEmail};
          const result = await API.graphql(graphqlOperation(getFranMailNews, input));
          const resultData = result.data.getFranMailNews;
          if (resultData) {
            this.mailNewsPermission = true;
          }else{
            this.mailNewsPermission = false;
          }
        } catch(e) {
          //console.error(e);
        }
        this.initMailState = this.mailNewsPermission;
      },

      changeEmail: function(newValue) {
        this.userEmail = newValue.userEmail;
        this.mailNewsPermission = newValue.mailNews;
        this.getUser();
        //this.$router.go({path: this.$router.currentRoute.path, force: true});
      },

      getUser: function() {
          return Auth.currentAuthenticatedUser().then((data) => {
            if (data && data.signInUserSession) {
              this.$store.dispatch('changeUser', data);
              return data;
            }
          }).catch(() => {
            this.$store.dispatch('changeUser', null);
            return null;
          });
      }
    }
  };
</script>
