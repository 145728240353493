<!-- ナビゲーションドロワー -->

<template>
  <section>
    <v-navigation-drawer
      width="80vw"
      temporary
      fixed
      v-bind="$attrs"
      v-on="$listeners"
      class="fran-nav"
    >
      <v-list-item>
        
        <v-list-item-content class="fran-userdata">
          
          <!--<v-list-item-subtitle>-->
          <!--  プロフィール-->
          <!--</v-list-item-subtitle>-->
        </v-list-item-content>

        

      </v-list-item>

      <v-divider></v-divider>

      <v-list tile dense flat inactive>
        <v-list-item-group v-model="group" color="red darken-4">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>

         
      <div class="mx-auto pa-5">
        <div v-if="user">
         
        </div>
        <div class="copyright">Copyright &copy; Japan Meteorological Corporation. </div>
      </div>
      </template>
    </v-navigation-drawer>
  </section>
</template>

<script>
  import DiaClose from '@/mixins/base/dia-close.js';
  import QueryFranUser from '@/mixins/query-franuser.js';
  import { Auth } from "aws-amplify";

  export default {
    name: 'NavDrawer',

    mixins: [DiaClose, QueryFranUser],
    
    components: {
      //LoginButtonBox: () => import('@/components/base/LoginButtonBox.vue'),
      //SetNotification: () => import('@/components/base/SetNotification.vue'),
    },

    data: () => ({
      group: null,
      user: null,
      items: [
        { title: 'お知らせ', icon: 'mdi-information-outline', link: 'info' },
        { title: 'FRAN予報について', icon: 'mdi-white-balance-sunny', link: 'AboutFran' },
        //{ title: 'ショウキンについて',  icon: 'mdi-currency-usd', link: 'AboutPoint' },
        { title: '地図について', icon: 'mdi-map', link: 'AboutMap' },
        //{ title: 'Q&A',  icon: 'mdi-help-circle-outline', link: 'Help' },
        //{ title: 'ユーザ情報', icon: 'mdi-account-circle', link: 'UserInfo' },
        //{ title: '店舗情報', icon: 'mdi-calendar', link: 'UpdateShopEvent'},
        { title: 'ご意見・ご要望', icon: 'mdi-email', link: 'Request' },
        //{ title: '退会について', icon: 'mdi-exit-run', link: 'Withdrawal' },
        { title: '広告を出されたい企業様', icon: 'mdi-newspaper-variant', link: 'Advertise' },
        { title: '運営会社', icon: 'mdi-web', link: 'Company' },
        { title: '利用規約', icon: 'mdi-note-text', link: 'Terms' },
        { title: 'プライバシーポリシー', icon: 'mdi-shield-key-outline', link: 'Privacypolicy' },
      ],
      settingDialog: false,
    }),
    
    mounted: async function () {
      await this.getFranUser();
    },
    
    computed: {
      stateUser: function() {
        return this.$store.state.user;
      },
      getDisplayUserName: function() {
        let result = 'ゲストさん';
        if (this.user) {
          result = 'ログイン中';
        }
        return result;
      },
    },
    
    watch: {
      group() {
        this.drawer = false;
      },
      stateUser (newValue) {
        // console.log('User data on state changed!', newValue)
        if (newValue && newValue.username) {
          this.getFranUser();
        }
      }
    },
    
    methods: {
      signOut: async function() {
        await Auth.signOut();
      },
      getFranUser: async function() {
        const u = this.$store.state.user;
        // console.log(`getFranUser for: ${JSON.stringify(u)}`)
        if (u) {
          try {
            const franUser = await this.getFranUserData(u.username);
            this.user = franUser;
          } catch(error) {
            console.error(`Failed to load fran user data: ${error}`);
          }
        }
      },
      resnotifi() {
        this.settingDialog = false;
      }
    },
  };
</script>
